import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ICliente, ICreateRelevamiento } from 'modelos/src';
import { DexieService } from '../../../auxiliares/dexie.service';
import { DolarService } from '../../../auxiliares/dolar.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { OfflineService } from '../../../auxiliares/offline.service';
import { LoginService } from '../../login/login.service';
import {
  condicionesComerciales,
  tasasInteres,
  IPago,
  IProductoSeleccionado,
  metodosPago,
  moneda,
  plazos,
  plazosPago,
  tarjetas,
  tiposRelevamiento,
} from '../datos';

@Component({
  selector: 'app-editar-relevamiento',
  templateUrl: './editar-relevamiento.component.html',
  styleUrls: ['./editar-relevamiento.component.scss'],
})
export class EditarRelevamientoComponent implements OnInit {
  public id?: number;
  public loading = false;

  private cliente?: ICliente;

  public form?: FormGroup;

  public dolar: number = 1;

  public campAgricolas: string[] = [];
  public condicionesComerciales = condicionesComerciales;
  public tiposRelevamiento = tiposRelevamiento;
  public metodosPago = metodosPago;
  public plazosPago = plazosPago;
  public tarjetas = tarjetas;

  public productoSeleccionado: IProductoSeleccionado = {
    moneda: 'U$S',
  };
  public metodosPagoSeleccionados: IPago[] = [];

  constructor(
    public helper: HelperService,
    private fb: FormBuilder,
    public listados: ListadosOfflineService,
    public matDialog: MatDialog,
    public dolarService: DolarService,
    public offlineService: OfflineService,
    public dexieService: DexieService,
    private route: ActivatedRoute
  ) {}

  get idRegionSeleccionada(): string | undefined {
    return this.form?.get('idRegion')?.value;
  }
  get idZonaSeleccionada(): string | undefined {
    return this.form?.get('idZona')?.value;
  }
  get idProvinciaSeleccionada(): string | undefined {
    return this.form?.get('idProvincia')?.value;
  }
  get idDepartamentoSeleccionada(): string | undefined {
    return this.form?.get('idDepartamento')?.value;
  }

  // Validaciones
  public async cambioMonedaPago() {
    const mondeda = this.productoSeleccionado.moneda;
    if (mondeda === '$' && this.dolar === 1) {
      this.loading = true;
      this.dolar = await this.dolarService.cotizacion();
      this.loading = false;
    }
    this.calcularPCE();
  }

  public cambioMetodoPago(i: number) {
    const metodo = this.metodosPagoSeleccionados[i].metodo || '';

    this.metodosPagoSeleccionados[i].plazoDias = plazos[metodo];
    this.metodosPagoSeleccionados[i].tasaMensual = tasasInteres[metodo];
    if (metodo === 'Canje') {
      this.metodosPagoSeleccionados[i].comisionCanje = 0.5;
    }
    this.productoSeleccionado.moneda = moneda[metodo];
    this.cambioMonedaPago();
  }
  public cambioMonedaMetodoPago(i: number) {
    const metodo = this.metodosPagoSeleccionados[i].metodo || '';
    const monedaSeleccionadaS = this.metodosPagoSeleccionados[i].moneda;
    const monedaSeleccionada =
      monedaSeleccionadaS === '$'
        ? 'peso'
        : monedaSeleccionadaS === 'U$S'
        ? 'dolar'
        : '';

    const tasasCliente: any = this.cliente?.config?.tasas;

    this.metodosPagoSeleccionados[i].plazoDias = plazos[metodo];
    this.metodosPagoSeleccionados[i].tasaMensual =
      tasasCliente?.[metodo]?.[monedaSeleccionada] || tasasInteres[metodo];
    if (metodo === 'Canje') {
      this.metodosPagoSeleccionados[i].comisionCanje = 0.5;
    }

    this.productoSeleccionado.moneda = monedaSeleccionadaS;
    this.cambioMonedaPago();
  }
  public cambioProcentajePago(i: number) {
    let suma = 0;
    this.metodosPagoSeleccionados.forEach((e, index) => {
      if (index !== i) {
        suma += e.porcentajePago || 0;
      }
    });
    const valorMax = 100 - suma;

    const valor = this.metodosPagoSeleccionados[i].porcentajePago || 0;

    if (valor < 0) this.metodosPagoSeleccionados[i].porcentajePago = 0;
    if (valor > valorMax)
      this.metodosPagoSeleccionados[i].porcentajePago = valorMax;
  }

  private porcentajeFaltante() {
    let suma = 0;
    this.metodosPagoSeleccionados.forEach((e, index) => {
      suma += e.porcentajePago || 0;
    });
    return 100 - suma;
  }

  public calcularPCE() {
    const precioFinal: number = this.productoSeleccionado.precioFinal || 0;
    const moneda = this.productoSeleccionado.moneda;
    if (moneda === 'U$S') {
      this.productoSeleccionado.precioFinalU$S = precioFinal;
    } else {
      const valorDolar = this.dolar;
      this.productoSeleccionado.precioFinalU$S = +(
        precioFinal / valorDolar
      ).toFixed(2);
    }
    const precioFinalU$S: number =
      this.productoSeleccionado.precioFinalU$S || 0;

    let suma = 0;
    for (const pago of this.metodosPagoSeleccionados) {
      const importePorcentual =
        ((pago.porcentajePago || 0) * precioFinalU$S) / 100;
      let dias = pago.plazoDias || 0;

      if (pago.metodo === 'Tarjeta' && pago.diasLibres) {
        dias = dias - pago.diasLibres;
      }

      const meses = dias / 30;
      let interes = meses * (pago.tasaMensual || 0);

      if (pago.metodo === 'Canje') {
        if (pago.plusCanje) {
          interes = interes + pago.plusCanje;
        }
        if (pago.comisionCanje) {
          interes = interes - pago.comisionCanje;
        }
      }
      const importePorcentualContado =
        (importePorcentual / (100 + interes)) * 100;
      suma += importePorcentualContado;
    }
    this.productoSeleccionado.precioContadoU$S = +suma.toFixed(2);
  }

  public mostrarAgregarMetodoPago() {
    let suma = 0;
    this.metodosPagoSeleccionados.forEach(
      (e) => (suma += e.porcentajePago || 0)
    );
    return suma < 100;
  }

  public botonEnviarDisabled(): boolean {
    return !this.form?.valid || this.loading || !this.metodosPagoValidos();
  }

  public productoValido(producto: IProductoSeleccionado): boolean {
    return !!(
      producto.idProducto &&
      producto.precioContadoU$S &&
      producto.precioFinalU$S
    );
  }
  public metodoPagoValido(pago: IPago): boolean {
    let valido = !!(
      pago.metodo &&
      pago.tasaMensual?.toString() &&
      pago.porcentajePago &&
      pago.plazoDias?.toString()
    );
    if (pago.metodo === 'Tarjeta') {
      valido = valido && !!(pago.tarjeta && pago.diasLibres?.toString());
    }
    if (pago.metodo === 'Canje') {
      valido =
        valido &&
        !!(pago.plusCanje?.toString() && pago.comisionCanje?.toString());
    }
    return valido;
  }

  public metodosPagoValidos(): boolean {
    let suma = 0;
    for (const metod of this.metodosPagoSeleccionados) {
      suma += metod.porcentajePago || 0;
      if (!this.metodoPagoValido(metod)) {
        return false;
      }
    }
    if (suma !== 100) {
      return false;
    }
    return true;
  }

  // Acciones

  public volver() {
    window.history.back();
  }

  private getData() {
    const idLocalidad = this.form?.get('idLocalidad')?.value;
    const localidad = this.listados.localidads.find(
      (l) => l._id === idLocalidad
    );
    const datos: ICreateRelevamiento[] = [];
    if (localidad) {
      const producto = this.listados.productos.find(
        (p) => p._id === this.productoSeleccionado.idProducto
      );
      if (producto) {
        const dato: ICreateRelevamiento = {
          fecha: (this.form?.get('fecha')?.value as Date).toISOString(),
          campAgricola: this.form?.get('campAgricola')?.value,
          observaciones: this.form?.get('observaciones')?.value,
          ubicacion: {
            idLocalidad,
            idDepartamento: localidad.idDepartamento,
            idProvincia: localidad.idProvincia,
            // idZona: localidad.idZona,
            // idRegion: localidad.idRegion,
          },
          condicionComercial: this.form?.get('condicionComercial')?.value,
          fuente: this.form?.get('fuente')?.value,
          tipoRelevamiento: this.form?.get('tipoRelevamiento')?.value,
          pago: {
            precioContadoU$S: this.productoSeleccionado.precioContadoU$S,
            precioFinalU$S: this.productoSeleccionado.precioFinalU$S,
            volumen: this.productoSeleccionado.volumen,
            metodos: this.metodosPagoSeleccionados,
          },
          producto: {
            idProducto: producto._id,
            idEmpresa: producto?.idEmpresa,
            idSegmento: producto?.idSegmento,
            idsSubsegmentos: producto?.idsSubsegmentos,
            // idsSubsegmentosPropios: producto?.idsSubsegmentosPropios,
            composicion: producto?.composicion,
            numeroRegistro: producto?.numeroRegistro,
          },
        };
        datos.push(dato);
      }
    }
    return datos;
  }

  public async update(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData()[0];
      await this.dexieService.updateRelevamiento(this.id!, data);
      this.helper.notifSuccess('Editado correctamente');
      this.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  // public async buscarProducto() {
  //   const modal = this.matDialog.open(SeleccionProductoComponent, {
  //     maxWidth: '100%',
  //     maxHeight: '100%',
  //     hasBackdrop: true,
  //     panelClass: 'o-visible',
  //     position: { top: '1em' },
  //   });
  //   const idProducto = await modal.afterClosed().pipe(take(1)).toPromise();
  //   if (idProducto) {
  //     this.productoSeleccionado.idProducto = idProducto;
  //   }
  // }

  public unidadProducto(idProducto: string | undefined) {
    return this.listados.productos.find((p) => p._id === idProducto)?.unidad;
  }

  public agregarMetodoPago() {
    this.metodosPagoSeleccionados.push({
      plazoDias: 0,
      diasLibres: 0,
      tasaMensual: 0.5,
      porcentajePago: this.porcentajeFaltante(),
      comisionCanje: 0,
      plusCanje: 0,
    });
  }

  public quitarMetodoPago(index: number) {
    this.metodosPagoSeleccionados.splice(index, 1);
  }

  public calcularPCETodos() {
    this.calcularPCE();
  }

  //

  public cambioFecha(date: Date) {
    const year = +date.getFullYear().toString().substr(2, 2);
    let campAgricola;
    if (
      date.getMonth() > 8 ||
      (date.getMonth() === 8 && date.getDate() >= 15)
    ) {
      campAgricola = `${year} / ${year + 1}`;
    } else {
      campAgricola = `${year - 1} / ${year}`;
    }
    if (!this.campAgricolas.includes(campAgricola)) {
      this.campAgricolas.push(campAgricola);
    }
    this.form?.patchValue({ campAgricola });
  }

  private crearCampAgricola() {
    let currYear = +new Date().getFullYear().toString().substr(2, 2);
    for (let i = 0; i < 3; i++) {
      this.campAgricolas.push(`${currYear - 1} / ${currYear}`);
      currYear++;
    }
  }

  private defaultCampAgricola() {
    const date = new Date();
    if (
      date.getMonth() > 8 ||
      (date.getMonth() === 8 && date.getDate() >= 15)
    ) {
      return this.campAgricolas[1];
    } else {
      return this.campAgricolas[0];
    }
  }

  private async createForm() {
    this.crearCampAgricola();
    if (this.id) {
      const relevamiento = await this.dexieService.getRelevamiento(this.id);
      console.log('relevamiento', relevamiento);

      this.form = this.fb.group({
        fecha: [new Date(relevamiento?.fecha || ''), Validators.required],
        campAgricola: [
          { value: relevamiento?.campAgricola, disabled: false },
          Validators.required,
        ],
        idRegion: relevamiento?.ubicacion?.idRegion,
        idZona: relevamiento?.ubicacion?.idZona,
        idProvincia: relevamiento?.ubicacion?.idProvincia,
        idDepartamento: relevamiento?.ubicacion?.idDepartamento,
        idLocalidad: [
          relevamiento?.ubicacion?.idLocalidad,
          Validators.required,
        ],
        condicionComercial: relevamiento?.condicionComercial,
        fuente: relevamiento?.fuente,
        tipoRelevamiento: relevamiento?.tipoRelevamiento,
        observaciones: relevamiento?.observaciones,
      });
      this.productoSeleccionado = {
        idProducto: relevamiento?.producto?.idProducto,
        moneda: 'U$S',
        precioContadoU$S: relevamiento?.pago?.precioContadoU$S,
        precioFinal: relevamiento?.pago?.precioFinalU$S,
        precioFinalU$S: relevamiento?.pago?.precioFinalU$S,
        volumen: relevamiento?.pago?.volumen,
      };
      if (relevamiento?.pago?.metodos) {
        for (const pago of relevamiento?.pago?.metodos) {
          this.metodosPagoSeleccionados.push({
            plazoDias: pago.plazoDias,
            diasLibres: pago.diasLibres,
            tasaMensual: pago.tasaMensual,
            porcentajePago: pago.porcentajePago,
            comisionCanje: pago.comisionCanje,
            plusCanje: pago.plusCanje,
            metodo: pago.metodo,
            precioContadoU$S: pago.precioContadoU$S,
            precioFinalU$S: pago.precioFinalU$S,
            tarjeta: pago.tarjeta,
          });
        }
      }
    }
  }

  private defaultForm() {
    if (this.listados.regions.length === 1)
      this.form?.patchValue({ idRegion: this.listados.regions?.[0]?._id });
    if (this.listados.zonas.length === 1)
      this.form?.patchValue({ idZona: this.listados.zonas?.[0]?._id });
    if (this.listados.provincias.length === 1)
      this.form?.patchValue({
        idProvincia: this.listados.provincias?.[0]?._id,
      });
    if (this.listados.departamentos.length === 1)
      this.form?.patchValue({
        idDepartamento: this.listados.departamentos?.[0]?._id,
      });
    if (this.listados.localidads.length === 1)
      this.form?.patchValue({
        idLocalidad: this.listados.localidads?.[0]?._id,
      });
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.cliente = LoginService.getCliente();
    this.id = +this.route.snapshot.paramMap.get('id')!;
    this.createForm();
    this.defaultForm();
    this.loading = false;
  }
}
