<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em; padding: 1em">
  <form id="formCliente" [formGroup]="form!" (ngSubmit)="onSubmit()">
    <!-- Condición comercial -->
    <mat-form-field formGroupName="config">
      <mat-label>Condición Comercial por Defecto</mat-label>
      <mat-select formControlName="condicionComercial">
        <mat-option
          *ngFor="let dato of condicionesComerciales"
          [value]="dato"
          >{{ dato }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <ng-coontainer formGroupName="config">
      <ng-select
        *ngIf="listados.localidads.length"
        placeholder="Localidad"
        formControlName="idLocalidadPorDefecto"
        notFoundText="No se encontraron localidades"
        [items]="listados.localidads"
        bindValue="_id"
        [searchFn]="helper.searchLocalidad"
        [virtualScroll]="true"
        (change)="cambioLocalidad()"
      >
        <ng-template ng-label-tmp let-item="item">
          <strong>
            <span>{{ item.nombre }} </span>
            <span style="font-size: 0.7em"> ({{ item.provincia.nombre }})</span>
          </strong>
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div style="padding-bottom: 5px">
            <span>{{ item.nombre }} </span>
            <span style="font-size: 0.7em">
              ({{ item.departamento?.nombre }} |
              {{ item.provincia.nombre }})</span
            >
          </div>
        </ng-template>
      </ng-select>
    </ng-coontainer>

    <!-- Tasas -->
    <ng-container formGroupName="config">
      <table formGroupName="tasas" style="width: 100%; table-layout: fixed">
        <h2 class="nowrap">Tasas por defecto</h2>
        <tr>
          <th style="text-align: left">Método</th>
          <th style="text-align: center">$</th>
          <th style="text-align: center">U$S</th>
        </tr>
        <tr formGroupName="Contado">
          <td>
            <mat-checkbox
              color="primary"
              [checked]="cliente?.config?.tasas?.Contado ? true : false"
              (change)="toggleForm('Contado', $event)"
              >Contado</mat-checkbox
            >
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="peso"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="dolar"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
        </tr>
        <tr formGroupName="Tarjeta">
          <td>
            <mat-checkbox
              color="primary"
              [checked]="cliente?.config?.tasas?.Tarjeta ? true : false"
              (change)="toggleForm('Tarjeta', $event)"
              >Tarjeta</mat-checkbox
            >
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="peso"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="dolar"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
        </tr>
        <tr formGroupName="Pesificado">
          <td>
            <mat-checkbox
              color="primary"
              [checked]="cliente?.config?.tasas?.Pesificado ? true : false"
              (change)="toggleForm('Pesificado', $event)"
              >Pesificado</mat-checkbox
            >
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="peso"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="dolar"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
        </tr>
        <tr formGroupName="Canje">
          <td>
            <mat-checkbox
              color="primary"
              [checked]="cliente?.config?.tasas?.Canje ? true : false"
              (change)="toggleForm('Canje', $event)"
              >Canje</mat-checkbox
            >
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="peso"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="dolar"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
        </tr>
        <tr formGroupName="Cheque">
          <td>
            <mat-checkbox
              color="primary"
              [checked]="cliente?.config?.tasas?.Cheque ? true : false"
              (change)="toggleForm('Cheque', $event)"
              >Cheque</mat-checkbox
            >
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="peso"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="dolar"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
        </tr>
        <tr formGroupName="Dolar Abierto">
          <td>
            <mat-checkbox
              color="primary"
              [checked]="cliente?.config?.tasas?.['Dolar Abierto'] ? true : false"
              (change)="toggleForm('Dolar Abierto', $event)"
              >Dolar Abierto</mat-checkbox
            >
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="peso"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="dolar"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
        </tr>
        <tr formGroupName="Otro">
          <td>
            <mat-checkbox
              color="primary"
              [checked]="cliente?.config?.tasas?.Otro ? true : false"
              (change)="toggleForm('Otro', $event)"
              >Otro</mat-checkbox
            >
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="peso"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
          <td style="padding: 0 1em">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="dolar"
                autocomplete="off"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </ng-container>
  </form>

  <br />
  <mat-divider></mat-divider>
  <br />

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="formCliente"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="formCliente"
      fxFlex
      (click)="volver()"
    >
      Volver
    </button>
  </mat-card-actions>
</div>
