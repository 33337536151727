<mat-card>
  <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    {{ title }}
  </mat-card-title>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form id="formSubsegmento" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <!-- Nombre  -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <!-- Segmento -->
      <div style="width: 100%">
        <ng-select
          style="width: 100%"
          placeholder="Segmentos"
          [items]="listados.segmentos"
          bindValue="_id"
          formControlName="idSegmento"
          notFoundText="No se encontraron segmentos"
          [searchFn]="helper.searchSegmento"
          [virtualScroll]="true"
          required
          [clearSearchOnAdd]="true"
          [clearable]="true"
          [appendTo]="'body'"
          (change)="filtrarProductos()"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
            <span>{{ item.nombre }} </span>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <div
              fxLayout="column"
              style="
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                line-height: 1em !important;
              "
            >
              <div style="padding-bottom: 5px">
                <span>{{ item.nombre }} </span>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <!-- Productos -->
      <div style="width: 100%">
        <ng-select
          style="width: 100%"
          placeholder="Productos"
          [items]="productos"
          bindValue="_id"
          formControlName="idsProductos"
          notFoundText="No se encontraron productos"
          [searchFn]="helper.searchProducto"
          [virtualScroll]="true"
          required
          [multiple]="true"
          [closeOnSelect]="false"
          [clearSearchOnAdd]="true"
          [clearable]="true"
          [appendTo]="'body'"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
            <span>{{ item.nombre }} </span>
            <span style="font-size: 0.7em"> ({{ item.empresa?.nombre }})</span>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <div
              fxLayout="column"
              style="
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                line-height: 1em !important;
              "
            >
              <div style="padding-bottom: 5px">
                <span>{{ item.nombre }} </span>
                <span style="font-size: 0.7em">
                  ({{ item.empresa?.nombre }})</span
                >
              </div>
              <div
                fxLayout="row"
                style="font-size: 0.6em !important; padding-left: 16px"
              >
                <span
                  *ngFor="let composicion of item.composicion; let i = index"
                >
                  <span *ngIf="i !== 0">&nbsp; | </span>
                  <span
                    >{{ composicion.principioActivo?.nombre }}
                    {{ composicion.concentracion }}%</span
                  >
                </span>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="formSubsegmento"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
