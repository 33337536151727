import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICliente, IUpdateCliente } from 'modelos/src';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ClientesService {
  constructor(private http: HttpService) {}

  public listarPropio(): Observable<ICliente> {
    return this.http.get(`/clientes/propio`);
  }

  public updatePropio(datos: IUpdateCliente): Observable<ICliente> {
    return this.http.put(`/clientes/propio`, datos);
  }
}
