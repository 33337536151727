import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { RegionsRoutingModule } from './regions.routing.module';
import { CrearEditarRegionComponent } from './crear-editar-region/crear-editar-region.component';
import { ListadoRegionsComponent } from './listado-regions/listado-regions.component';

@NgModule({
  declarations: [
    // ListadoRegionsComponent,
    // CrearEditarRegionsComponent,
  
    CrearEditarRegionComponent,
    ListadoRegionsComponent
  ],
  imports: [CommonModule, AuxiliaresModule, RegionsRoutingModule],
})
export class RegionsModule {}
