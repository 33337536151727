import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { OfflineService } from './offline.service';

interface response { casa: { compra: string, venta: string, nombre: string } }

@Injectable({
  providedIn: 'root'
})
export class DolarService {

  constructor(
    private http: HttpClient,
    private offlineService: OfflineService,
  ) { }

  private fechaHoy() {
    const date = new Date();
    return `${date.toLocaleDateString()}-${date.getHours()}`;
  }

  public async cotizacion(): Promise<number> {
    if (this.offlineService.haveConnection) {
      const fecha = this.fechaHoy();
      const cache = localStorage.getItem(`dolar-${fecha}`);
      if (cache) {
        return +cache;
      }
      const response: response[] = await this.http.get<response[]>(`https://www.dolarsi.com/api/api.php?type=valoresprincipales`).pipe(take(1)).toPromise();
      const oficial = response.find( e => e.casa.nombre === "Dolar Oficial");
      let venta = oficial?.casa.venta;
      if (venta) {
        venta = venta.replace(',', '.');
        localStorage.setItem(`dolar-${fecha}`, venta);
        localStorage.setItem(`dolar-latest`, venta);
        return +venta;
      }
      return 1;
    } else {
      const cache = localStorage.getItem(`dolar-latest`);
      if (cache) {
        return +cache;
      }
      return 1;
    }
  }

}

