<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <!-- Menu -->
    <div fxLayout="row" fxLayoutAlign="start center" style="padding: 0 16px">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="(isHandset$ | async) === false"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <mat-toolbar>Menu</mat-toolbar>
    </div>
    <!-- Menu -->

    <!-- Usuario -->
    <div class="usuario">
      <h3 style="text-align: center">{{ usuario?.username || "Offline" }}</h3>
      <h4 style="text-align: center; padding: 0.5em; border-radius: 5px">
        <strong>{{ usuario?.cliente?.nombre }}</strong>
      </h4>
    </div>
    <!-- Usuario -->

    <!-- Elementos Menu -->
    <mat-nav-list>
      <a mat-list-item [routerLink]="'landing'" [routerLinkActive]="'active'">
        Inicio
      </a>

      <a
        *ngIf="offlineService.isOnline && permisoVer"
        mat-list-item
        [routerLink]="'dashboard'"
        [routerLinkActive]="'active'"
      >
        Dashboard
      </a>

      <a
        *ngIf="permisoCrear"
        mat-list-item
        [routerLink]="'relevamientos/offline'"
        [routerLinkActive]="'active'"
      >
        Registros Locales
      </a>

      <a
        *ngIf="offlineService.isOnline && permisoVer"
        mat-list-item
        [routerLink]="'relevamientos/online'"
        [routerLinkActive]="'active'"
      >
        Registros Sinc.
      </a>

      <mat-accordion *ngIf="esAdminGlobal && offlineService.isOnline">
        <mat-expansion-panel style="box-shadow: none">
          <mat-expansion-panel-header>
            <mat-panel-title> Configuración </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- Contenido -->
          <a
            mat-list-item
            [routerLink]="'clientes/crear'"
            [routerLinkActive]="'active'"
          >
            ► Personalizar
          </a>
          <mat-divider></mat-divider>
          <a
            mat-list-item
            [routerLink]="'usuarios'"
            [routerLinkActive]="'active'"
          >
            ► Usuarios
          </a>
          <mat-divider></mat-divider>
          <a
            mat-list-item
            [routerLink]="'grupoComparativos'"
            [routerLinkActive]="'active'"
          >
            ► Grupos Comp.
          </a>
          <a
            mat-list-item
            [routerLink]="'subsegmentoPropios'"
            [routerLinkActive]="'active'"
          >
            ► Subsegmentos P.
          </a>
          <!-- Zonas -->
          <mat-divider></mat-divider>
          <a
            mat-list-item
            [routerLink]="'regions'"
            [routerLinkActive]="'active'"
          >
            ► Regiones
          </a>
          <a mat-list-item [routerLink]="'zonas'" [routerLinkActive]="'active'">
            ► Zonas
          </a>
          <!--  -->
        </mat-expansion-panel>
      </mat-accordion>

      <!-- Salir -->
      <mat-divider></mat-divider>
      <a mat-list-item (click)="logout()">Salir</a>
    </mat-nav-list>
    <!-- Elementos Menu -->

    <mat-divider></mat-divider>

    <!-- Cambio de tema -->
    <mat-slide-toggle
      style="margin-top: 1em; padding: 0 16px"
      [checked]="temaOscuro"
      labelPosition="before"
      color="primary"
      (toggleChange)="toggleTheme()"
    >
      Tema Oscuro
    </mat-slide-toggle>

    <!-- Instalar APP -->
    <mat-nav-list *ngIf="this.pwa.promptEvent">
      <div
        style="
          display: flex;
          height: 48px;
          align-items: center;
          justify-content: center;
        "
      >
        <button
          mat-raised-button
          color="accent"
          (click)="this.pwa.installPwa()"
          style="width: 90%"
        >
          Instalar APP
        </button>
      </div>
    </mat-nav-list>
    <!-- Instalar APP -->

    <!-- Version -->
    <h3 style="text-align: center; margin-top: 1em; padding: 0 16px">
      <span> Versión: {{ version }}</span>
      <span *ngIf="env !== 'Prod'"> ({{ env }})</span>
    </h3>
    <!-- Version -->
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Toolbar -->
    <mat-toolbar color="primary">
      <!-- Boton - visible con menu contraido -->
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="(isHandset$ | async) || !drawer.opened"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <!-- Imagen -->
      <img
        src="/assets/images/agroinsumos3.png"
        alt=""
        [height]="(isHandset$ | async) ? 50 : 60"
      />
      <!-- <span>Agroinsumos - CAPTURADOR DE PRECIOS</span> -->

      <span class="spacer"></span>

      <!-- Sincronizando -->
      <div style="margin-right: 0.25em" *ngIf="offlineService.isOnline">
        <button
          *ngIf="!httpService.sincronizando"
          type="button"
          mat-mini-fab
          color="accent"
          matTooltip="Actualizar Cache"
          (click)="actualizarCache()"
        >
          <mat-icon>sync</mat-icon>
        </button>
        <mat-icon
          *ngIf="httpService.sincronizando"
          class="rotate"
          color="accent"
          matTooltip="Sincronizando"
          style="margin: 8px 0.5em 0"
        >
          sync
        </mat-icon>
      </div>

      <!-- Estado Online/Offline -->
      <div style="margin: 8px 0.5em 0">
        <mat-icon
          *ngIf="!offlineService.isOnline && offlineService.haveConnection"
          style="color: yellow"
          matTooltip="Offline / Conexión Disponible"
          >perm_scan_wifi</mat-icon
        >
        <mat-icon
          *ngIf="offlineService.isOnline"
          style="background: #ffffffbf; border-radius: 16px; color: green"
          matTooltip="Online"
          >wifi</mat-icon
        >
        <mat-icon
          *ngIf="!offlineService.isOnline && !offlineService.haveConnection"
          style="color: red"
          matTooltip="Offline / Sin Conexión"
          >wifi_off</mat-icon
        >
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
