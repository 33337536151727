import { Component, Inject, Renderer2 } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { Router } from '@angular/router';
import { LogoutService } from '../../login/logout.service';
import { env } from '../../../../environments/environment';
import { IUsuario } from 'modelos/src';
import { LoginService } from '../../login/login.service';
import { version } from '../../../../environments/version';
import { HelperService } from '../../../auxiliares/helper.service';
import { OfflineService } from '../../../auxiliares/offline.service';
import { HttpService } from '../../../auxiliares/http.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { DOCUMENT } from '@angular/common';
import { PwaService } from '../../../auxiliares/pwa.service';

type Envs = 'Local' | 'Dev' | 'Test' | 'Prod';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  public usuario?: IUsuario;
  public esAdminGlobal?: Boolean;

  public permisoCrear = HelperService.permisoCrearRelevamientos();
  public permisoVer = HelperService.permisoVerRelevamientos();

  public temaOscuro = localStorage.getItem('temaOscuro') === 'true';

  public version = version;
  public env: Envs = env;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private router: Router,
    private logoutService: LogoutService,
    private helper: HelperService,
    public offlineService: OfflineService,
    public httpService: HttpService,
    public listadosService: ListadosService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public pwa: PwaService
  ) {}

  private temaInicial() {
    if (localStorage.getItem('temaOscuro') === 'true') {
      this.renderer.addClass(this.document.body, 'dark-theme');
    }
  }

  public toggleTheme() {
    if (this.document.body.classList.contains('dark-theme')) {
      this.renderer.removeClass(this.document.body, 'dark-theme');
      localStorage.removeItem('temaOscuro');
    } else {
      this.renderer.addClass(this.document.body, 'dark-theme');
      localStorage.setItem('temaOscuro', 'true');
    }
  }

  public actualizarCache() {
    this.dialogService
      .confirm('Confirme la acción', '¿Desea actualizar el cache de datos?')
      .then(async (confirm) => {
        if (confirm) {
          await this.listadosService.actualizarCache();
        }
      });
  }

  public logout() {
    this.dialogService
      .confirm('Confirme la acción', '¿Desea cerrar la sesión?')
      .then((confirm) => {
        if (confirm) {
          this.router.navigateByUrl('login');
          this.logoutService.logout();
        }
      });
  }

  async ngOnInit(): Promise<void> {
    this.usuario = LoginService.getUsuario();
    this.esAdminGlobal = this.helper.esAdminGlobal();
    this.temaInicial();
  }
}
