import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoginService } from '../modulos/login/login.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  private idOnlineChanged = new Subject<boolean>();
  private haveConnectionChanged = new Subject<boolean>();

  constructor(private helper: HelperService) {
    //listen for the online/offline events
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }

  get userLogged() {
    return LoginService.getToken() ? true : false;
  }

  //return the connection state
  get connectionChanged() {
    return this.haveConnectionChanged.asObservable();
  }

  get onlineChanged() {
    return this.idOnlineChanged.asObservable();
  }

  get isOnline() {
    return !!(window.navigator.onLine && this.userLogged);
  }

  get haveConnection() {
    // console.log(!!(window.navigator.onLine));
    return !!window.navigator.onLine;
  }

  public updateOnlineStatus() {
    if (!this.userLogged && this.haveConnection) {
      this.helper.notifWarn(
        'Se detectó conexión, inicie sesión para trabajar online'
      );
    }
    this.idOnlineChanged.next(this.isOnline);
    this.haveConnectionChanged.next(window.navigator.onLine);
  }
}
