<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" (click)="crear()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna Zona -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Zona
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Provincias -->
    <ng-container matColumnDef="idsProvincias">
      <mat-header-cell *matHeaderCellDef> Provincias </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.idsProvincias.length }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Departamentos -->
    <ng-container matColumnDef="idsDepartamentos">
      <mat-header-cell *matHeaderCellDef> Departamentos </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.idsDepartamentos.length }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Localidades -->
    <ng-container matColumnDef="idsLocalidades">
      <mat-header-cell *matHeaderCellDef> Localidades </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.idsLocalidades.length }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        style="overflow: visible"
        fxLayoutGap="5px"
      >
        <!-- <span class="mobile-label">Acciones:</span> -->
        <button
          mat-mini-fab
          matTooltip="Editar"
          color="accent"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator-goto>
</div>

<mat-dialog-actions
  style="margin: 1em"
  fxLayoutAlign="end center"
  fxLayoutGap="10px"
>
  <button mat-fab type="button" color="primary" (click)="volver()">
    <mat-icon aria-label="Volver">keyboard_return</mat-icon>
  </button>
</mat-dialog-actions>
