import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  IListado,
  IQueryMongo,
  IProducto,
  ISegmento,
  ISubsegmento,
  IRegion,
  IZona,
  IProvincia,
  IDepartamento,
  ILocalidad,
  IEmpresa,
  IPrincipioActivo,
  IGrupoComparativo,
  ISubsegmentoPropio,
} from 'modelos/src';
import { ListadosService } from './listados.service';

@Injectable({
  providedIn: 'root',
})
export class ListadosOfflineService {
  // Listados
  public productos: IProducto[] = [];
  public segmentos: ISegmento[] = [];
  public subsegmentos: ISubsegmento[] = [];
  public regions: IRegion[] = [];
  public zonas: IZona[] = [];
  public provincias: IProvincia[] = [];
  public departamentos: IDepartamento[] = [];
  public localidads: ILocalidad[] = [];
  public empresas: IEmpresa[] = [];
  public principioActivos: IPrincipioActivo[] = [];
  public grupoComparativos: IGrupoComparativo[] = [];
  public subsegmentoPropios: ISubsegmentoPropio[] = [];

  // Listado Continuo
  private productos$?: Subscription;
  private segmentos$?: Subscription;
  private subsegmentos$?: Subscription;
  private regions$?: Subscription;
  private zonas$?: Subscription;
  private provincias$?: Subscription;
  private departamentos$?: Subscription;
  private localidads$?: Subscription;
  private empresas$?: Subscription;
  private principioActivos$?: Subscription;
  private grupoComparativos$?: Subscription;
  private subsegmentoPropios$?: Subscription;

  get getProductos(): IProducto[] {
    return this.productos;
  }

  constructor(private listadosService: ListadosService) {
    console.log('Inicializando servicio de listados offline');
    this.listarTodo();
  }

  //

  private async listarProductos(): Promise<void> {
    const populate = [
      {
        path: 'composicion.principioActivo',
        select: 'nombre',
      },
      {
        path: 'composicion.familiaQuimica',
        select: 'nombre',
      },
      {
        path: 'empresa',
        select: 'nombre',
      },
    ];
    const query: IQueryMongo = {
      select:
        'nombre idEmpresa composicion.idFamiliaQuimica composicion.idPrincipioActivo composicion.concentracion idSegmento idsSubsegmentos numeroRegistro unidad',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.productos$?.unsubscribe();
    this.productos$ = this.listadosService
      .subscribe<IListado<IProducto>>('productos', query)
      .subscribe((data) => {
        this.productos = data.datos;
        console.log(`listado de productos`, data);
      });
    await this.listadosService.getLastValue('productos', query);
  }
  private async listarSegmentos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.segmentos$?.unsubscribe();
    this.segmentos$ = this.listadosService
      .subscribe<IListado<ISegmento>>('segmentos', query)
      .subscribe((data) => {
        this.segmentos = data.datos;
        console.log(`listado de segmentos`, data);
      });
    await this.listadosService.getLastValue('segmentos', query);
  }
  private async listarSubsegmentos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.subsegmentos$?.unsubscribe();
    this.subsegmentos$ = this.listadosService
      .subscribe<IListado<ISubsegmento>>('subsegmentos', query)
      .subscribe((data) => {
        this.subsegmentos = data.datos;
        console.log(`listado de subsegmentos`, data);
      });
    await this.listadosService.getLastValue('subsegmentos', query);
  }
  private async listarRegions(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre idsZonas',
      sort: 'nombre',
    };
    this.regions$?.unsubscribe();
    this.regions$ = this.listadosService
      .subscribe<IListado<IRegion>>('regions', query)
      .subscribe((data) => {
        this.regions = data.datos;
        console.log(`listado de regions`, data);
      });
    await this.listadosService.getLastValue('regions', query);
  }
  private async listarZonas(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre idsProvincias idsDepartamentos idsLocalidades',
      sort: 'nombre',
    };
    this.zonas$?.unsubscribe();
    this.zonas$ = this.listadosService
      .subscribe<IListado<IZona>>('zonas', query)
      .subscribe((data) => {
        this.zonas = data.datos;
        console.log(`listado de zonas`, data);
      });
    await this.listadosService.getLastValue('zonas', query);
  }
  private async listarProvincias(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.provincias$?.unsubscribe();
    this.provincias$ = this.listadosService
      .subscribe<IListado<IProvincia>>('provincias', query)
      .subscribe((data) => {
        this.provincias = data.datos;
        console.log(`listado de provincias`, data);
      });
    await this.listadosService.getLastValue('provincias', query);
  }
  private async listarDepartamentos(): Promise<void> {
    const populate = {
      path: 'provincia',
      select: 'nombre',
    };
    const query: IQueryMongo = {
      select: 'nombre idProvincia',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.departamentos$?.unsubscribe();
    this.departamentos$ = this.listadosService
      .subscribe<IListado<IDepartamento>>('departamentos', query)
      .subscribe((data) => {
        this.departamentos = data.datos;
        console.log(`listado de departamentos`, data);
      });
    await this.listadosService.getLastValue('departamentos', query);
  }
  private async listarLocalidads(): Promise<void> {
    const populate = [
      {
        path: 'provincia',
        select: 'nombre',
      },
      {
        path: 'departamento',
        select: 'nombre',
      },
    ];
    const query: IQueryMongo = {
      select: 'nombre idProvincia idDepartamento',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.localidads$?.unsubscribe();
    this.localidads$ = this.listadosService
      .subscribe<IListado<ILocalidad>>('localidads', query)
      .subscribe((data) => {
        this.localidads = data.datos;
        console.log(`listado de localidads`, data);
      });
    await this.listadosService.getLastValue('localidads', query);
  }
  private async listarEmpresas(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.empresas$?.unsubscribe();
    this.empresas$ = this.listadosService
      .subscribe<IListado<IEmpresa>>('empresas', query)
      .subscribe((data) => {
        this.empresas = data.datos;
        console.log(`listado de empresas`, data);
      });
    await this.listadosService.getLastValue('empresas', query);
  }
  private async listarPrincipioActivos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.principioActivos$?.unsubscribe();
    this.principioActivos$ = this.listadosService
      .subscribe<IListado<IPrincipioActivo>>('principioActivos', query)
      .subscribe((data) => {
        this.principioActivos = data.datos;
        console.log(`listado de principioActivos`, data);
      });
    await this.listadosService.getLastValue('principioActivos', query);
  }
  private async listarGrupoComparativos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.grupoComparativos$?.unsubscribe();
    this.grupoComparativos$ = this.listadosService
      .subscribe<IListado<IGrupoComparativo>>('grupoComparativos', query)
      .subscribe((data) => {
        this.grupoComparativos = data.datos;
        console.log(`listado de grupoComparativos`, data);
      });
    await this.listadosService.getLastValue('grupoComparativos', query);
  }
  private async listarSubsegmentoPropios(): Promise<void> {
    const populate = {
      path: 'segmento',
      select: 'nombre',
    };
    const query: IQueryMongo = {
      select: 'nombre idSegmento idsProductos segmento',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.subsegmentoPropios$?.unsubscribe();
    this.subsegmentoPropios$ = this.listadosService
      .subscribe<IListado<ISubsegmentoPropio>>('subsegmentoPropios', query)
      .subscribe((data) => {
        this.subsegmentoPropios = data.datos;
        console.log(`listado de subsegmentoPropios`, data);
      });
    await this.listadosService.getLastValue('subsegmentoPropios', query);
  }

  public async listarTodo() {
    await Promise.all([
      this.listarProductos(),
      this.listarSegmentos(),
      this.listarSubsegmentos(),
      this.listarRegions(),
      this.listarZonas(),
      this.listarProvincias(),
      this.listarDepartamentos(),
      this.listarLocalidads(),
      this.listarEmpresas(),
      this.listarPrincipioActivos(),
      this.listarGrupoComparativos(),
      this.listarSubsegmentoPropios(),
    ]);
    console.log('Listado offline finalizado');
  }

  public borrarCache() {
    this.productos = [];
    this.segmentos = [];
    this.subsegmentos = [];
    this.regions = [];
    this.zonas = [];
    this.provincias = [];
    this.departamentos = [];
    this.localidads = [];
    this.empresas = [];
    this.principioActivos = [];
    this.grupoComparativos = [];
    this.subsegmentoPropios = [];
    console.log('Cache listados offline borrado');
  }
}
