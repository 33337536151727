import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  IListado,
  IUsuario,
  IQueryMongo,
  ISocketMessage,
  IEmpresa,
  IFamiliaQuimica,
  IProvincia,
  IDepartamento,
  ILocalidad,
  IPrincipioActivo,
  IProducto,
  ISegmento,
  ISubsegmento,
  ISubsegmentoPropio,
  IRelevamiento,
  IZona,
  IRegion,
  IResumenRelevamiento,
  IGrupoComparativo,
} from 'modelos/src';
import { UsuariosService } from '../modulos/usuarios/usuarios.service';
import { WebSocketService } from './websocket';
import { EmpresasService } from '../modulos/empresas/empresas.service';
import { FamiliaQuimicasService } from '../modulos/familiaQuimicas/familiaQuimicas.service';
import { ProvinciasService } from '../modulos/provincias/provincias.service';
import { DepartamentosService } from '../modulos/departamentos/departamentos.service';
import { LocalidadsService } from '../modulos/localidads/localidads.service';
import { PrincipioActivosService } from '../modulos/principioActivos/principioActivos.service';
import { ProductosService } from '../modulos/productos/productos.service';
import { SegmentosService } from '../modulos/segmentos/segmentos.service';
import { SubsegmentosService } from '../modulos/subsegmentos/subsegmentos.service';
import { SubsegmentoPropiosService } from '../modulos/subsegmentoPropios/subsegmentoPropios.service';
import { RelevamientosService } from '../modulos/relevamientos/relevamientos.service';
import { DexieService } from './dexie.service';
import { OfflineService } from './offline.service';
import { ZonasService } from '../modulos/zonas/zonas.service';
import { RegionsService } from '../modulos/regions/regions.service';
import { GrupoComparativosService } from '../modulos/grupoComparativos/grupoComparativos.service';

type Entidad =
  | 'usuario'
  | 'usuarios'
  | 'empresa'
  | 'empresas'
  | 'familiaQuimica'
  | 'familiaQuimicas'
  | 'producto'
  | 'productos'
  | 'provincia'
  | 'provincias'
  | 'departamento'
  | 'departamentos'
  | 'localidad'
  | 'localidads'
  | 'zona'
  | 'zonas'
  | 'region'
  | 'regions'
  | 'principioActivo'
  | 'principioActivos'
  | 'segmento'
  | 'segmentos'
  | 'subsegmento'
  | 'subsegmentos'
  | 'subsegmentoPropio'
  | 'subsegmentoPropios'
  | 'relevamiento'
  | 'relevamientos'
  | 'resumenRelevamientos'
  | 'grupoComparativo'
  | 'grupoComparativos';

type Tipo =
  | IUsuario
  | IListado<IUsuario>
  | IEmpresa
  | IListado<IEmpresa>
  | IFamiliaQuimica
  | IListado<IFamiliaQuimica>
  | IProducto
  | IListado<IProducto>
  | IProvincia
  | IListado<IProvincia>
  | IDepartamento
  | IListado<IDepartamento>
  | ILocalidad
  | IListado<ILocalidad>
  | IZona
  | IListado<IZona>
  | IRegion
  | IListado<IRegion>
  | IPrincipioActivo
  | IListado<IPrincipioActivo>
  | ISegmento
  | IListado<ISegmento>
  | ISubsegmento
  | IListado<ISubsegmento>
  | ISubsegmentoPropio
  | IListado<ISubsegmentoPropio>
  | IRelevamiento
  | IListado<IRelevamiento>
  | IResumenRelevamiento
  | IGrupoComparativo
  | IListado<IGrupoComparativo>;

class RequestQueue {
  subscribe: Subject<Tipo>;
  requests: number;
  cache?: Tipo;

  constructor() {
    this.requests = 0;
    this.subscribe = new Subject<Tipo>();
    this.cache = undefined;
  }
}

interface IRequestId {
  fn: (id: string) => Promise<any>;
  fnDexie?: (id: number | string) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQuery {
  fn: (query: IQueryMongo) => Promise<any>;
  fnDexie?: (query: IQueryMongo) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IEntidades {
  usuario: IRequestId;
  usuarios: IRequestQuery;
  empresa: IRequestId;
  empresas: IRequestQuery;
  familiaQuimica: IRequestId;
  familiaQuimicas: IRequestQuery;
  producto: IRequestId;
  productos: IRequestQuery;
  provincia: IRequestId;
  provincias: IRequestQuery;
  departamento: IRequestId;
  departamentos: IRequestQuery;
  localidad: IRequestId;
  localidads: IRequestQuery;
  zona: IRequestId;
  zonas: IRequestQuery;
  region: IRequestId;
  regions: IRequestQuery;
  principioActivo: IRequestId;
  principioActivos: IRequestQuery;
  segmento: IRequestId;
  segmentos: IRequestQuery;
  subsegmento: IRequestId;
  subsegmentos: IRequestQuery;
  subsegmentoPropio: IRequestId;
  subsegmentoPropios: IRequestQuery;
  relevamiento: IRequestId;
  relevamientos: IRequestQuery;
  resumenRelevamientos: IRequestQuery;
  grupoComparativo: IRequestId;
  grupoComparativos: IRequestQuery;
}

@Injectable({
  providedIn: 'root',
})
export class ListadosService {
  private entidades: IEntidades = this.getInitCache();

  constructor(
    private dexieService: DexieService,
    private offlineService: OfflineService,
    private webSocketService: WebSocketService,
    private usuariosService: UsuariosService,
    private empresasService: EmpresasService,
    private familiaQuimicaService: FamiliaQuimicasService,
    private productoService: ProductosService,
    private provinciasService: ProvinciasService,
    private departamentosService: DepartamentosService,
    private localidadsService: LocalidadsService,
    private principioActivoService: PrincipioActivosService,
    private segmentosService: SegmentosService,
    private subsegmentosService: SubsegmentosService,
    private subsegmentoPropiosService: SubsegmentoPropiosService,
    private relevamientosService: RelevamientosService,
    private zonasService: ZonasService,
    private regionsService: RegionsService,
    private grupoComparativosService: GrupoComparativosService
  ) {
    this.subscribeWsUpdates();
  }

  //

  private log() {
    setInterval(() => {
      for (const ent in this.entidades) {
        const entidad = this.entidades[ent as Entidad];
        for (const key in entidad.keys) {
          if (entidad.keys[key as string].subscribe.observers.length) {
            console.log(
              `Entidad ${ent} key ${key} requests ${
                entidad.keys[key as string].requests
              }. Subscribers ${
                entidad.keys[key as string].subscribe.observers.length
              }`
            );
          }
        }
      }
    }, 5000);
  }

  // Subscribe

  public subscribe<Tipo>(
    entidad: Entidad,
    query: IQueryMongo | string
  ): Observable<Tipo> {
    const key = typeof query === 'string' ? query : JSON.stringify(query);
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (!ent.keys[key]) {
        ent.keys[key] = new RequestQueue();
      }
    }
    return ent.keys[key].subscribe.asObservable() as any;
  }

  public async getLastValue(
    entidad: Entidad,
    query: IQueryMongo | string
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (typeof query === 'string') {
        await this.listarId(
          entidad,
          query,
          (ent as IRequestId).fn,
          (ent as IRequestId).fnDexie
        );
      } else {
        await this.listarQuery(
          entidad,
          query,
          (ent as IRequestQuery).fn,
          (ent as IRequestQuery).fnDexie
        );
      }
    }
  }

  // Listados Entidades
  private async listarUsuario(id: string): Promise<IUsuario> {
    const response = await this.usuariosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarUsuarios(
    query: IQueryMongo
  ): Promise<IListado<IUsuario>> {
    const response = await this.usuariosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEmpresa(id: string): Promise<IEmpresa> {
    const response = await this.empresasService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarEmpresas(
    query: IQueryMongo
  ): Promise<IListado<IEmpresa>> {
    const response = await this.empresasService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearEmpresas();
    this.dexieService.createEmpresas(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarEmpresasDexie(
    query: IQueryMongo
  ): Promise<IListado<IEmpresa>> {
    const response = await this.dexieService.getEmpresas(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarFamiliaQuimica(id: string): Promise<IFamiliaQuimica> {
    const response = await this.familiaQuimicaService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarFamiliaQuimicas(
    query: IQueryMongo
  ): Promise<IListado<IFamiliaQuimica>> {
    const response = await this.familiaQuimicaService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearFamiliaQuimicas();
    this.dexieService.createFamiliaQuimicas(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarFamiliaQuimicasDexie(
    query: IQueryMongo
  ): Promise<IListado<IFamiliaQuimica>> {
    const response = await this.dexieService.getFamiliaQuimicas(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarProducto(id: string): Promise<IProducto> {
    const response = await this.productoService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarProductos(
    query: IQueryMongo
  ): Promise<IListado<IProducto>> {
    const response = await this.productoService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearProductos();
    this.dexieService.createProductos(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarProductosDexie(
    query: IQueryMongo
  ): Promise<IListado<IProducto>> {
    const response = await this.dexieService.getProductos(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarProvincia(id: string): Promise<IProvincia> {
    const response = await this.provinciasService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarProvincias(
    query: IQueryMongo
  ): Promise<IListado<IProvincia>> {
    const response = await this.provinciasService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearProvincias();
    this.dexieService.createProvincias(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarProvinciasDexie(
    query: IQueryMongo
  ): Promise<IListado<IProvincia>> {
    const response = await this.dexieService.getProvincias(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDepartamento(id: string): Promise<IDepartamento> {
    const response = await this.departamentosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarDepartamentos(
    query: IQueryMongo
  ): Promise<IListado<IDepartamento>> {
    const response = await this.departamentosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearDepartamentos();
    this.dexieService.createDepartamentos(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarDepartamentosDexie(
    query: IQueryMongo
  ): Promise<IListado<IDepartamento>> {
    const response = await this.dexieService.getDepartamentos(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLocalidad(id: string): Promise<ILocalidad> {
    const response = await this.localidadsService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarLocalidads(
    query: IQueryMongo
  ): Promise<IListado<ILocalidad>> {
    const response = await this.localidadsService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearLocalidads();
    this.dexieService.createLocalidads(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarLocalidadsDexie(
    query: IQueryMongo
  ): Promise<IListado<ILocalidad>> {
    const response = await this.dexieService.getLocalidads(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarZona(id: string): Promise<IZona> {
    const response = await this.zonasService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarZonas(query: IQueryMongo): Promise<IListado<IZona>> {
    const response = await this.zonasService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearZonas();
    this.dexieService.createZonas(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarZonasDexie(query: IQueryMongo): Promise<IListado<IZona>> {
    const response = await this.dexieService.getZonas(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarRegion(id: string): Promise<IRegion> {
    const response = await this.regionsService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarRegions(query: IQueryMongo): Promise<IListado<IRegion>> {
    const response = await this.regionsService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearRegions();
    this.dexieService.createRegions(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarRegionsDexie(
    query: IQueryMongo
  ): Promise<IListado<IRegion>> {
    const response = await this.dexieService.getRegions(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarPrincipioActivo(id: string): Promise<IPrincipioActivo> {
    const response = await this.principioActivoService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarPrincipioActivos(
    query: IQueryMongo
  ): Promise<IListado<IPrincipioActivo>> {
    const response = await this.principioActivoService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearPrincipioActivos();
    this.dexieService.createPrincipioActivos(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarPrincipioActivosDexie(
    query: IQueryMongo
  ): Promise<IListado<IPrincipioActivo>> {
    const response = await this.dexieService.getPrincipioActivos(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarSegmento(id: string): Promise<ISegmento> {
    const response = await this.segmentosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarSegmentos(
    query: IQueryMongo
  ): Promise<IListado<ISegmento>> {
    const response = await this.segmentosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearSegmentos();
    this.dexieService.createSegmentos(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarSegmentosDexie(
    query: IQueryMongo
  ): Promise<IListado<ISegmento>> {
    const response = await this.dexieService.getSegmentos(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarSubsegmento(id: string): Promise<ISubsegmento> {
    const response = await this.subsegmentosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarSubsegmentos(
    query: IQueryMongo
  ): Promise<IListado<ISubsegmento>> {
    const response = await this.subsegmentosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearSubsegmentos();
    this.dexieService.createSubsegmentos(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarSubsegmentosDexie(
    query: IQueryMongo
  ): Promise<IListado<ISubsegmento>> {
    const response = await this.dexieService.getSubsegmentos(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarSubsegmentoPropio(
    id: string
  ): Promise<ISubsegmentoPropio> {
    const response = await this.subsegmentoPropiosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarSubsegmentoPropios(
    query: IQueryMongo
  ): Promise<IListado<ISubsegmentoPropio>> {
    const response = await this.subsegmentoPropiosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    this.dexieService.clearSubsegmentoPropios();
    this.dexieService.createSubsegmentoPropios(response.datos);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarSubsegmentoPropiosDexie(
    query: IQueryMongo
  ): Promise<IListado<ISubsegmentoPropio>> {
    const response = await this.dexieService.getSubsegmentoPropios(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarRelevamiento(id: string): Promise<IRelevamiento> {
    const response = await this.relevamientosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarRelevamientos(
    query: IQueryMongo
  ): Promise<IListado<IRelevamiento>> {
    const response = await this.relevamientosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarResumenRelevamientos(
    query: IQueryMongo
  ): Promise<IResumenRelevamiento> {
    const response = await this.relevamientosService
      .resumen(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarGrupoComparativo(id: string): Promise<IGrupoComparativo> {
    const response = await this.grupoComparativosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarGrupoComparativos(
    query: IQueryMongo
  ): Promise<IListado<IGrupoComparativo>> {
    const response = await this.grupoComparativosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  // Borrar cache
  private getInitCache(): IEntidades {
    return {
      usuario: { fn: this.listarUsuario.bind(this), keys: {} },
      usuarios: { fn: this.listarUsuarios.bind(this), keys: {} },
      empresa: { fn: this.listarEmpresa.bind(this), keys: {} },
      empresas: {
        fn: this.listarEmpresas.bind(this),
        fnDexie: this.listarEmpresasDexie.bind(this),
        keys: {},
      },
      familiaQuimica: {
        fn: this.listarFamiliaQuimica.bind(this),
        keys: {},
      },
      familiaQuimicas: {
        fn: this.listarFamiliaQuimicas.bind(this),
        fnDexie: this.listarFamiliaQuimicasDexie.bind(this),
        keys: {},
      },
      producto: { fn: this.listarProducto.bind(this), keys: {} },
      productos: {
        fn: this.listarProductos.bind(this),
        fnDexie: this.listarProductosDexie.bind(this),
        keys: {},
      },
      provincia: { fn: this.listarProvincia.bind(this), keys: {} },
      provincias: {
        fn: this.listarProvincias.bind(this),
        fnDexie: this.listarProvinciasDexie.bind(this),
        keys: {},
      },
      departamento: { fn: this.listarDepartamento.bind(this), keys: {} },
      departamentos: {
        fn: this.listarDepartamentos.bind(this),
        fnDexie: this.listarDepartamentosDexie.bind(this),
        keys: {},
      },
      localidad: { fn: this.listarLocalidad.bind(this), keys: {} },
      localidads: {
        fn: this.listarLocalidads.bind(this),
        fnDexie: this.listarLocalidadsDexie.bind(this),
        keys: {},
      },
      zona: { fn: this.listarZona.bind(this), keys: {} },
      zonas: {
        fn: this.listarZonas.bind(this),
        fnDexie: this.listarZonasDexie.bind(this),
        keys: {},
      },
      region: { fn: this.listarRegion.bind(this), keys: {} },
      regions: {
        fn: this.listarRegions.bind(this),
        fnDexie: this.listarRegionsDexie.bind(this),
        keys: {},
      },
      principioActivo: {
        fn: this.listarPrincipioActivo.bind(this),
        keys: {},
      },
      principioActivos: {
        fn: this.listarPrincipioActivos.bind(this),
        fnDexie: this.listarPrincipioActivosDexie.bind(this),
        keys: {},
      },
      segmento: { fn: this.listarSegmento.bind(this), keys: {} },
      segmentos: {
        fn: this.listarSegmentos.bind(this),
        fnDexie: this.listarSegmentosDexie.bind(this),
        keys: {},
      },
      subsegmento: { fn: this.listarSubsegmento.bind(this), keys: {} },
      subsegmentos: {
        fn: this.listarSubsegmentos.bind(this),
        fnDexie: this.listarSubsegmentosDexie.bind(this),
        keys: {},
      },
      subsegmentoPropio: {
        fn: this.listarSubsegmentoPropio.bind(this),
        keys: {},
      },
      subsegmentoPropios: {
        fn: this.listarSubsegmentoPropios.bind(this),
        fnDexie: this.listarSubsegmentoPropiosDexie.bind(this),
        keys: {},
      },
      relevamiento: { fn: this.listarRelevamiento.bind(this), keys: {} },
      relevamientos: {
        fn: this.listarRelevamientos.bind(this),
        keys: {},
      },
      resumenRelevamientos: {
        fn: this.listarResumenRelevamientos.bind(this),
        keys: {},
      },
      grupoComparativo: {
        fn: this.listarGrupoComparativo.bind(this),
        keys: {},
      },
      grupoComparativos: {
        fn: this.listarGrupoComparativos.bind(this),
        keys: {},
      },
    };
  }

  public borrarCache() {
    this.entidades = this.getInitCache();
    console.log('Cache listado borrado');
  }

  public async actualizarCache() {
    const entidades: Entidad[] = [
      'empresas',
      'familiaQuimicas',
      'productos',
      'provincias',
      'departamentos',
      'localidads',
      'zonas',
      'regions',
      'principioActivos',
      'segmentos',
      'subsegmentos',
      'subsegmentoPropios',
      'grupoComparativos',
    ];
    await Promise.all(
      entidades.map(async (entidad) => {
        this.actualizarQuery(entidad);
      })
    );
  }

  // Actualizar Entidades
  private async actualizarQuery(entidad: Entidad): Promise<void> {
    const ent = this.entidades[entidad] as IRequestQuery;
    for (const key in ent.keys) {
      if (ent.keys[key].requests === 0) {
        if (ent.keys.hasOwnProperty(key)) {
          ent.keys[key].cache = undefined;
          const query = JSON.parse(key);
          if (ent.keys[key].subscribe.observers.length) {
            ent.keys[key].requests++;
            while (ent.keys[key].requests) {
              ent.keys[key].cache = undefined;
              await this.listarQuery(
                entidad,
                query,
                ent.fn,
                ent.fnDexie,
                false
              );
              ent.keys[key].requests--;
            }
          }
        }
      } else if (ent.keys[key].requests < 2) {
        ent.keys[key].requests++;
      }
    }
  }

  private async actualizarId(entidad: Entidad, id?: string): Promise<void> {
    if (id) {
      const ent = this.entidades[entidad] as IRequestId;
      if (ent.keys[id]) {
        if (ent.keys[id].requests === 0) {
          ent.keys[id].cache = undefined;
          if (ent.keys[id].subscribe.observers.length) {
            ent.keys[id].requests++;
            while (ent.keys[id].requests) {
              ent.keys[id].cache = undefined;
              await this.listarId(entidad, id, ent.fn, ent.fnDexie, false);
              ent.keys[id].requests--;
            }
          }
        } else if (ent.keys[id].requests < 2) {
          ent.keys[id].requests++;
        }
      }
    }
  }

  // Listados Generales
  private getFechaCache(entidad: Entidad) {
    return localStorage.getItem(`fecha-${entidad}`);
  }
  private setFechaCache(entidad: Entidad) {
    localStorage.setItem(`fecha-${entidad}`, new Date().toISOString());
  }

  private cacheVencida(entidad: Entidad): boolean {
    const fechaUltimaQuery = this.getFechaCache(entidad);
    if (fechaUltimaQuery) {
      const fechaQuery = new Date(fechaUltimaQuery);
      const fechaLimite = new Date();
      fechaLimite.setHours(fechaLimite.getHours() - 24);
      return fechaQuery < fechaLimite;
    }
    return true;
  }

  private async listarQuery(
    entidad: Entidad,
    query: IQueryMongo,
    fn: (query: IQueryMongo) => Promise<any>,
    fnDexie?: (query: IQueryMongo) => Promise<any>,
    usarDexie = true
  ): Promise<void> {
    const ent = this.entidades[entidad];
    const key = JSON.stringify(query);
    if (
      !ent.keys[key].cache ||
      !(ent.keys[key].cache as IListado<any>).datos?.length
    ) {
      // console.log(`No hay datos en cache de ${entidad}`);
      let response: { totalCount: 0; datos: any[] } = {
        totalCount: 0,
        datos: [],
      };
      if (fnDexie && usarDexie && !this.cacheVencida(entidad)) {
        response = await fnDexie(query);
      }
      if (!response.datos?.length && this.offlineService.isOnline) {
        // console.log(`No hay datos en DB Local de ${entidad}. Buscando Online`);
        response = await fn(query);
        this.setFechaCache(entidad);
      }
      ent.keys[key].cache = JSON.parse(JSON.stringify(response));
      ent.keys[key].subscribe.next(response);
    } else {
      ent.keys[key].subscribe.next(ent.keys[key].cache);
    }
  }

  private async listarId(
    entidad: Entidad,
    id: string,
    fn: (id: string) => Promise<Tipo>,
    fnDexie?: (id: number | string) => Promise<any>,
    usarDexie = true
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!ent.keys[id].cache) {
      let response;
      if (fnDexie && usarDexie && !this.cacheVencida(entidad)) {
        response = await fnDexie(id);
      }
      if (!response && this.offlineService.isOnline) {
        response = await fn(id);
        this.setFechaCache(entidad);
      }
      ent.keys[id].cache = JSON.parse(JSON.stringify(response));
      ent.keys[id].subscribe.next(response);
    } else {
      ent.keys[id].subscribe.next(ent.keys[id].cache);
    }
  }

  // Suscripcion a WS Service para eliminar cache y actualizar entidades
  private subscribeWsUpdates() {
    this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }
  private handleUpdateResponse(message: ISocketMessage) {
    if (message.paths?.includes('usuarios')) {
      this.actualizarQuery('usuarios');
      this.actualizarId('usuario', message.body?._id);
    }
    if (message.paths?.includes('empresas')) {
      this.actualizarQuery('empresas');
      this.actualizarId('empresa', message.body?._id);
    }
    if (message.paths?.includes('familiaquimicas')) {
      this.actualizarQuery('familiaQuimicas');
      this.actualizarId('familiaQuimica', message.body?._id);
    }
    if (message.paths?.includes('productos')) {
      this.actualizarQuery('productos');
      this.actualizarId('producto', message.body?._id);
    }
    if (message.paths?.includes('provincias')) {
      this.actualizarQuery('provincias');
      this.actualizarId('provincia', message.body?._id);
    }
    if (message.paths?.includes('departamentos')) {
      this.actualizarQuery('departamentos');
      this.actualizarId('departamento', message.body?._id);
    }
    if (message.paths?.includes('localidads')) {
      this.actualizarQuery('localidads');
      this.actualizarId('localidad', message.body?._id);
    }
    if (message.paths?.includes('principioactivos')) {
      this.actualizarQuery('principioActivos');
      this.actualizarId('principioActivo', message.body?._id);
    }
    if (message.paths?.includes('segmentos')) {
      this.actualizarQuery('segmentos');
      this.actualizarId('segmento', message.body?._id);
    }
    if (message.paths?.includes('subsegmentos')) {
      this.actualizarQuery('subsegmentos');
      this.actualizarId('subsegmento', message.body?._id);
    }
    if (message.paths?.includes('subsegmentopropios')) {
      this.actualizarQuery('subsegmentoPropios');
      this.actualizarId('subsegmentoPropio', message.body?._id);
    }
    if (message.paths?.includes('relevamientos')) {
      this.actualizarQuery('relevamientos');
      this.actualizarId('relevamiento', message.body?._id);
      this.actualizarQuery('resumenRelevamientos');
    }
    if (message.paths?.includes('grupocomparativos')) {
      this.actualizarQuery('grupoComparativos');
      this.actualizarId('grupoComparativo', message.body?._id);
    }
    if (message.paths?.includes('zonas')) {
      this.actualizarQuery('zonas');
      this.actualizarId('zona', message.body?._id);
    }
    if (message.paths?.includes('regions')) {
      this.actualizarQuery('regions');
      this.actualizarId('region', message.body?._id);
    }
  }
}
