import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISegmento, IListado, IQueryMongo } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class SegmentosService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<ISegmento>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/segmentos`, { params });
  }

  public listarPorId(id: string): Observable<ISegmento> {
    return this.http.get(`/segmentos/${id}`);
  }
}
