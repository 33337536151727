import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ICliente, ICreateCliente, ILocalidad } from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { LoginService } from '../../login/login.service';
import { ClientesService } from '../clientes.service';

@Component({
  selector: 'app-crear-editar-clientes',
  templateUrl: './crear-editar-clientes.component.html',
  styleUrls: ['./crear-editar-clientes.component.scss'],
})
export class CrearEditarClientesComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public cliente?: ICliente;

  public condicionesComerciales: string[] = [
    'Distribuidor',
    'Megafarmers',
    'Productor',
  ];

  public localidades: ILocalidad[] = this.listados?.localidads;

  constructor(
    private fb: FormBuilder,
    public helper: HelperService,
    private service: ClientesService,
    public listados: ListadosOfflineService
  ) {}

  public cambioLocalidad() {
    const idLocalidad = this.form
      ?.get('config')
      ?.get('idLocalidadPorDefecto')?.value;
    this.helper.setLocalidadPorDefecto(idLocalidad);
  }

  public toggleForm(formName: string, value: MatCheckboxChange) {
    const form = this.form?.get('config')?.get('tasas')?.get(formName);
    if (form) {
      if (value.checked) {
        form.enable();
      } else {
        form.disable();
      }
    }
  }

  private createForm(): void {
    this.title = 'Editar Configuración';
    const idLocalidadPorDefecto = this.helper.getLocalidadPorDefecto() || null;

    this.form = this.fb.group({
      // nombre: [this.data?.nombre, Validators.required],
      // tipo: [this.data?.tipo, Validators.required],
      // idsEmpresa: [this.data?.idsEmpresa],
      config: this.fb.group({
        idLocalidadPorDefecto: [idLocalidadPorDefecto],
        condicionComercial: [this.cliente?.config?.condicionComercial],
        // comparteRelevamientos: [
        //   this.data?.config?.comparteRelevamientos || false,
        // ],
        tasas: this.fb.group({
          Contado: this.fb.group({
            peso: [this.cliente?.config?.tasas?.Contado?.peso || 0],
            dolar: [this.cliente?.config?.tasas?.Contado?.dolar || 0],
          }),
          Tarjeta: this.fb.group({
            peso: [this.cliente?.config?.tasas?.Tarjeta?.peso || 3.5],
            dolar: [this.cliente?.config?.tasas?.Tarjeta?.dolar || 3.5],
          }),
          Pesificado: this.fb.group({
            peso: [this.cliente?.config?.tasas?.Pesificado?.peso || 3.5],
            dolar: [this.cliente?.config?.tasas?.Pesificado?.dolar || 3.5],
          }),
          Canje: this.fb.group({
            peso: [this.cliente?.config?.tasas?.Canje?.peso || 0],
            dolar: [this.cliente?.config?.tasas?.Canje?.dolar || 0],
          }),
          Cheque: this.fb.group({
            peso: [this.cliente?.config?.tasas?.Cheque?.peso || 3.5],
            dolar: [this.cliente?.config?.tasas?.Cheque?.dolar || 3.5],
          }),
          'Dolar Abierto': this.fb.group({
            peso: [this.cliente?.config?.tasas?.['Dolar Abierto']?.peso || 0],
            dolar: [this.cliente?.config?.tasas?.['Dolar Abierto']?.dolar || 0],
          }),
          Otro: this.fb.group({
            peso: [this.cliente?.config?.tasas?.Otro?.peso || 3.5],
            dolar: [this.cliente?.config?.tasas?.Otro?.dolar || 3.5],
          }),
        }),
      }),
    });

    const tasas = this.form?.get('config')?.get('tasas');
    for (const key in tasas?.value) {
      const enabled = (this.cliente?.config?.tasas as any)?.[key];
      if (!enabled) {
        tasas?.get(key)?.disable();
      }
    }
  }

  public volver() {
    window.history.back();
  }

  //

  private getData() {
    const data: ICreateCliente = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.cliente?._id) {
        const cliente = await this.service
          .updatePropio(data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
        LoginService.setCliente(cliente);
      }
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // Listados

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.cliente = LoginService.getCliente();
    this.createForm();
    this.loading = false;
  }

  ngOnDestroy(): void {}
}
