import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPrincipioActivo, IListado, IQueryMongo } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class PrincipioActivosService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryMongo
  ): Observable<IListado<IPrincipioActivo>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/principioActivos`, { params });
  }

  public listarPorId(id: string): Observable<IPrincipioActivo> {
    return this.http.get(`/principioActivos/${id}`);
  }
}
