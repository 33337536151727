import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RolesGlobalesGuard } from '../../auxiliares/guards/rol.guard';
import { CrearRelevamientosComponent } from './crear-relevamientos/crear-relevamientos.component';
import { DetallesRelevamientoComponent } from './detalles-relevamiento/detalles-relevamiento.component';
import { EditarRelevamientoComponent } from './editar-relevamiento/editar-relevamiento.component';
import { ListadoRelevamientosOfflineComponent } from './listado-relevamientos-offline/listado-relevamientos-offline.component';
import { ListadoRelevamientosComponent } from './listado-relevamientos/listado-relevamientos.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'offline' },
  {
    path: 'crear',
    component: CrearRelevamientosComponent,
    canActivate: [RolesGlobalesGuard],
    data: { roles: ['Admin', 'Relevador'] },
  },
  {
    path: 'online',
    component: ListadoRelevamientosComponent,
  },
  {
    path: 'online/detalles/:id',
    component: DetallesRelevamientoComponent,
  },
  {
    path: 'offline',
    component: ListadoRelevamientosOfflineComponent,
  },
  {
    path: 'offline/editar/:id',
    component: EditarRelevamientoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RelevamientosRoutingModule {}
