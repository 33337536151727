<mat-card>
  <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    {{ title }}
  </mat-card-title>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="formUsuarios"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Username  -->
      <mat-form-field>
        <mat-label>Nombre de usuario</mat-label>
        <input
          matInput
          type="search"
          placeholder="Nombre de usuario"
          formControlName="username"
          required
        />
        <mat-error *ngIf="form?.get('username')?.invalid">{{
          getUsernameErrorMessage()
        }}</mat-error>
      </mat-form-field>

      <!-- Password -->
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'search'"
          [autocomplete]="!this.data ? 'new-password' : 'search'"
          placeholder="Password"
          formControlName="password"
          [required]="this.data ? false : true"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="$event.stopPropagation(); hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>

      <!-- Permisos -->
      <div formArrayName="permisos">
        <div
          *ngFor="
            let permisoUnidadNegocio of formArrayPermisos?.controls;
            let i = index;
            let even = even
          "
        >
          <div
            [formGroupName]="i"
            style="padding: 0.5em; margin-bottom: 1em; border: 1px solid #ccc"
            [style.background]="even ? 'rgba(0, 0, 0, 0.05)' : 'transparent'"
          >
            <mat-form-field>
              <mat-select placeholder="Rol" formControlName="tipo" required>
                <mat-option *ngFor="let dato of roles" [value]="dato">
                  {{ dato }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-checkbox
              style="display: block; margin-bottom: 1em"
              formControlName="global"
            >
              Global
            </mat-checkbox>
            <!--  -->
            <ng-container *ngIf="!permisoUnidadNegocio.get('global')?.value">
              <!-- Region -->
              <ng-select
                *ngIf="listadosOffline.regions.length"
                (change)="filtrarZonas(i)"
                placeholder="Region"
                formControlName="idsRegion"
                notFoundText="No se encontraron regiones"
                [items]="listadosOffline.regions"
                bindValue="_id"
                [searchFn]="helper.searchRegion"
                [virtualScroll]="true"
                [multiple]="true"
                [closeOnSelect]="false"
                [clearSearchOnAdd]="true"
                [clearable]="true"
                [appendTo]="'body'"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon right" (click)="clear(item)"
                    >×</span
                  >
                  <span>{{ item.nombre }} </span>
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div style="padding-bottom: 5px">
                    <span>{{ item.nombre }} </span>
                  </div>
                </ng-template>
              </ng-select>

              <!-- Zonas -->
              <ng-select
                *ngIf="zonas[i].length"
                placeholder="Zonas"
                formControlName="idsZona"
                notFoundText="No se encontraron zonas"
                [items]="zonas[i]"
                bindValue="_id"
                [searchFn]="helper.searchZona"
                [virtualScroll]="true"
                [multiple]="true"
                [closeOnSelect]="false"
                [clearSearchOnAdd]="true"
                [clearable]="true"
                [appendTo]="'body'"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon right" (click)="clear(item)"
                    >×</span
                  >
                  <span>{{ item.nombre }} </span>
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div style="padding-bottom: 5px">
                    <span>{{ item.nombre }} </span>
                  </div>
                </ng-template>
              </ng-select>

              <!-- Provincias -->
              <ng-select
                *ngIf="listadosOffline.provincias.length"
                placeholder="Provincias"
                formControlName="idsProvincia"
                (change)="filtrarDepartamentos(i); filtrarLocalidades(i)"
                notFoundText="No se encontraron provincias"
                [items]="listadosOffline.provincias"
                bindValue="_id"
                [searchFn]="helper.searchProvincia"
                [virtualScroll]="true"
                [multiple]="true"
                [closeOnSelect]="false"
                [clearSearchOnAdd]="true"
                [clearable]="true"
                [appendTo]="'body'"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon right" (click)="clear(item)"
                    >×</span
                  >
                  <span>{{ item.nombre }} </span>
                  <span style="font-size: 0.7em"></span>
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div style="padding-bottom: 5px">
                    <span>{{ item.nombre }} </span>
                    <span style="font-size: 0.7em"> </span>
                  </div>
                </ng-template>
              </ng-select>

              <!-- Departamentos -->
              <ng-select
                *ngIf="departamentos?.[i]?.length"
                placeholder="Departamentos"
                formControlName="idsDepartamento"
                notFoundText="No se encontraron departamentos"
                (change)="filtrarLocalidades(i)"
                [items]="departamentos[i]"
                bindValue="_id"
                [searchFn]="helper.searchDepartamento"
                [virtualScroll]="true"
                [multiple]="true"
                [closeOnSelect]="false"
                [clearSearchOnAdd]="true"
                [clearable]="true"
                [appendTo]="'body'"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon right" (click)="clear(item)"
                    >×</span
                  >
                  <span>{{ item.nombre }} </span>
                  <span style="font-size: 0.7em">
                    ({{ item.provincia?.nombre }})</span
                  >
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div style="padding-bottom: 5px">
                    <span>{{ item.nombre }} </span>
                    <span style="font-size: 0.7em">
                      ({{ item.provincia?.nombre }})
                    </span>
                  </div>
                </ng-template>
              </ng-select>

              <!-- Localidades -->
              <ng-select
                *ngIf="localidades?.[i]?.length"
                placeholder="Localidades"
                formControlName="idsLocalidad"
                notFoundText="No se encontraron localidades"
                [items]="localidades[i]"
                bindValue="_id"
                [searchFn]="helper.searchLocalidad"
                [virtualScroll]="true"
                [multiple]="true"
                [closeOnSelect]="false"
                [clearSearchOnAdd]="true"
                [clearable]="true"
                [appendTo]="'body'"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-icon right" (click)="clear(item)"
                    >×</span
                  >
                  <span>{{ item.nombre }} </span>
                  <span style="font-size: 0.7em">
                    ({{ item.provincia?.nombre }})</span
                  >
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div style="padding-bottom: 5px">
                    <span>{{ item.nombre }} </span>
                    <span style="font-size: 0.7em">
                      ({{ item.departamento?.nombre }} |
                      {{ item.provincia?.nombre }})</span
                    >
                  </div>
                </ng-template>
              </ng-select>
            </ng-container>

            <!-- Boton Eliminar Permiso -->
            <button
              *ngIf="formArrayPermisos.length > 1"
              mat-mini-fab
              color="warn"
              matTooltip="Eliminar"
              (click)="eliminarPermiso(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <!-- Boton Agregar Permiso -->
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="agregarPermiso()"
        >
          Agregar Permiso
        </button>
      </div>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="formUsuarios"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
