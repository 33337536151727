import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListadoSubsegmentoPropiosComponent } from './listado-subsegmentoPropios/listado-subsegmentoPropios.component';
import { CrearEditarSubsegmentoPropiosComponent } from './crear-editar-subsegmentoPropios/crear-editar-subsegmentoPropios.component';
import { SubsegmentoPropiosRoutingModule } from './subsegmentoPropios.routing.module';

@NgModule({
  declarations: [
    ListadoSubsegmentoPropiosComponent,
    CrearEditarSubsegmentoPropiosComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, SubsegmentoPropiosRoutingModule],
})
export class SubsegmentoPropiosModule {}
