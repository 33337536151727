export interface IProductoSeleccionado {
  idProducto?: string;
  volumen?: number;
  precioFinalU$S?: number;
  precioContadoU$S?: number;
  moneda?: string;
  precioFinal?: number;
}

export interface IPago {
  moneda?: string; // U$S / $
  metodo?: string; // Contado, Tarjeta, Canje, Etc
  plazoDias?: number;
  tasaMensual?: number; // En porcentaje
  tarjeta?: string; // Solo si metodo es tarjeta
  diasLibres?: number; // Solo si metodo es tarjeta
  plusCanje?: number; // Solo si el metodo es canje
  comisionCanje?: number; // Solo si el metodo es canje
  precioFinalU$S?: number; // Precio relevado
  precioContadoU$S?: number; // Precio calculado
  porcentajePago?: number; // Entre todos los metodos debe sumar 100
}

export const condicionesComerciales: string[] = [
  'Distribuidor',
  'Megafarmers',
  'Productor',
];
export const tiposRelevamiento: string[] = [
  'Condición de Mercado',
  'Precio de Lista',
  'Cotización',
];
export const metodosPago: string[] = [
  'Contado',
  'Tarjeta',
  'Pesificado',
  'Canje',
  'Cheque',
  'Dolar Abierto',
  'Otro',
];
export const plazosPago: number[] = [
  0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390,
];
export const tarjetas: string[] = [
  'BBVA Frances',
  'Buenos Aires - Procampo',
  'Cordoba - Bancor',
  'Entre Rios',
  'Galicia',
  'ICBC',
  'La Pampa',
  'Macro',
  'Nacion - AgroNacion',
  'Patagonia',
  'Santa Fe',
  'Santander',
  'Otra',
];

export const frecuencias = [
  {
    value: '%Y-%m-%d',
    label: 'Diario',
  },
  {
    value: '%V',
    label: 'Semanal',
  },
  {
    value: '%Y-%m',
    label: 'Mensual',
  },
  {
    value: '%Y',
    label: 'Anual',
  },
];

export const plazos: { [key: string]: number } = {
  Contado: 0,
  Tarjeta: 30,
  Pesificado: 0,
  Canje: 30,
  Cheque: 30,
  'Dolar Abierto': 0,
  Otro: 30,
};

export const tasasInteres: { [key: string]: number } = {
  Contado: 0,
  Tarjeta: 3.5,
  Pesificado: 3.5,
  Canje: 0,
  Cheque: 3.5,
  'Dolar Abierto': 0,
  Otro: 3.5,
};

export const moneda: { [key: string]: string } = {
  Contado: '$',
  Tarjeta: '$',
  Pesificado: '$',
  Canje: 'U$S',
  Cheque: '$',
  'Dolar Abierto': 'U$S',
  Otro: '$',
};
