import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { RelevamientosRoutingModule } from './relevamientos.routing.module';
import { ListadoRelevamientosComponent } from './listado-relevamientos/listado-relevamientos.component';
import { CrearRelevamientosComponent } from './crear-relevamientos/crear-relevamientos.component';
import { ListadoRelevamientosOfflineComponent } from './listado-relevamientos-offline/listado-relevamientos-offline.component';
import { EditarRelevamientoComponent } from './editar-relevamiento/editar-relevamiento.component';
import { DetallesRelevamientoComponent } from './detalles-relevamiento/detalles-relevamiento.component';

@NgModule({
  declarations: [ListadoRelevamientosComponent, CrearRelevamientosComponent, ListadoRelevamientosOfflineComponent, EditarRelevamientoComponent, DetallesRelevamientoComponent],
  imports: [CommonModule, AuxiliaresModule, RelevamientosRoutingModule],
})
export class RelevamientosModule {}
