import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import Boost from 'highcharts/modules/boost';
import SolidGauge from 'highcharts/modules/solid-gauge';
import more from 'highcharts/highcharts-more';
import HC_stock from 'highcharts/modules/stock';
import IndicatorsCore from 'highcharts/indicators/indicators';
import IndicatorRegressions from 'highcharts/indicators/regressions';
import { Options } from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

// Dark themes
import darkBlueTheme from 'highcharts/themes/dark-blue';
import darkGreenTheme from 'highcharts/themes/dark-green';
import darkUnicaTheme from 'highcharts/themes/dark-unica';

// Light themes
import avocadoTheme from 'highcharts/themes/avocado';
import grayTheme from 'highcharts/themes/gray';
import gridTheme from 'highcharts/themes/grid';
import gridLightTheme from 'highcharts/themes/grid-light';
import sandSignikaTheme from 'highcharts/themes/sand-signika';
import skiesTheme from 'highcharts/themes/skies';
import sunsetTheme from 'highcharts/themes/sunset';
import { HighchartsChartComponent } from 'highcharts-angular';

HC_stock(Highcharts);
noData(Highcharts);
more(Highcharts);
Boost(Highcharts);
SolidGauge(Highcharts);
IndicatorsCore(Highcharts);
IndicatorRegressions(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {
  public Highcharts: typeof Highcharts = Highcharts;
  @ViewChild('chart') private chartComponent?: HighchartsChartComponent;

  private ligthThemes = [
    gridTheme,
    gridLightTheme,
    avocadoTheme,
    sandSignikaTheme,
    skiesTheme,
    sunsetTheme,
  ];
  private darkThemes = [
    darkUnicaTheme,
    grayTheme,
    darkBlueTheme,
    darkGreenTheme,
  ];

  @Input() options?: Options;
  @Input() style: string | undefined;
  @Input() constructorType: string = 'chart';
  public update: boolean = false;
  chart?: Highcharts.Chart;

  @Output() optionsChange = new EventEmitter<Options>();
  @Output() chartPrint = new EventEmitter<void>();

  constructor() {
    this.chartCallback.bind(this);
  }

  public chartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }

  public chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    setTimeout(() => {
      this.chartPrint.emit();
    }, 100);
  };

  ngOnChanges() {
    this.update = true;
    if (this.chart) {
      if (this.options) {
        this.options.accessibility = {
          enabled: false,
        };
      }
      if (!this.options?.chart?.style) {
        this.options!.chart!.style = {
          fontFamily: 'Roboto, sans-serif',
        };
      }
      if (this.options) {
        this.chart?.update(this.options, true, true);
      }
    }
  }

  private wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async nextTheme() {
    const dark = localStorage.getItem('temaOscuro');
    const options = JSON.parse(JSON.stringify(this.options));
    this.options = undefined;
    await this.wait(100);
    if (dark) {
      this.darkThemes.push(this.darkThemes.shift()!);
      this.darkThemes[0](Highcharts);
    } else {
      this.ligthThemes.push(this.ligthThemes.shift()!);
      this.ligthThemes[0](Highcharts);
    }
    this.options = options;
    this.update = true;
  }

  ngOnInit(): void {
    const dark = localStorage.getItem('temaOscuro');
    if (dark) {
      this.darkThemes[0](Highcharts);
    } else {
      // this.ligthThemes[0](Highcharts);
    }

    if (this.options) {
      this.options.accessibility = {
        enabled: false,
      };
    }
    if (!this.options?.chart?.style) {
      this.options!.chart!.style = {
        fontFamily: 'Roboto, sans-serif',
      };
    }
  }

  ngOnDestroy() {}
}
