<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div
  *ngIf="this.pwa.promptEvent"
  fxLayoutAlign="center center"
  style="margin: 1.5em"
>
  <button
    mat-raised-button
    fxLayoutAlign="center center"
    type="BUTTON"
    color="accent"
    (click)="this.pwa.installPwa()"
  >
    <mat-icon> get_app </mat-icon>
    <span>Instalar APP</span>
  </button>
</div>

<h1 style="margin: 2em; text-align: center">Bienvenido!!!</h1>
<h1 style="margin: 2em; text-align: center"></h1>

<div
  class="p-3"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="24px"
>
  <!-- <h1>Registros</h1> -->
  <br />

  <button
    *ngIf="permisoCrear"
    type="button"
    class="btn-menu"
    mat-flat-button
    color="primary"
    [routerLink]="['../relevamientos/crear']"
  >
    Nuevo Registro
  </button>

  <button
    *ngIf="permisoCrear"
    type="button"
    class="btn-menu"
    mat-flat-button
    color="accent"
    [routerLink]="['../relevamientos/offline']"
  >
    Registros Locales
  </button>

  <button
    *ngIf="offlineService.isOnline && permisoVer"
    type="button"
    class="btn-menu"
    mat-flat-button
    color="accent"
    [routerLink]="['../relevamientos/online']"
  >
    Registros Sincronizados
  </button>

  <button
    *ngIf="offlineService.isOnline && permisoVer"
    type="button"
    class="btn-menu"
    mat-flat-button
    color="accent"
    [routerLink]="['../dashboard']"
  >
    Dashboard
  </button>

  <button
    type="button"
    class="btn-menu"
    mat-flat-button
    color="warn"
    (click)="logout()"
  >
    Salir
  </button>
</div>

<!-- <div
  class="center-dashboard-eze"
  *ngIf="offlineService.isOnline && this.rol !== 'externo'"
>
  <div
    class="p-3"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    fxFlex="100"
  >
    <button
      fxFlex="90"
      type="button"
      class="btn-menu"
      mat-flat-button
      color="accent"
      [routerLink]="['relevamientos']"
    >
      Mis Registros
    </button>
    <button
      fxFlex="10"
      type="button"
      class="btn-menu-side"
      mat-flat-button
      color="accent"
      [routerLink]="['relevamientos-full']"
    >
      <mat-icon>computer</mat-icon>
    </button>
  </div>
</div>

<div class="center-dashboard-eze">
  <div
    class="p-3"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    fxFlex="100"
  >
    <button
      fxFlex="90"
      *ngIf="offlineService.isOnline && this.rol !== 'externo'"
      type="button"
      class="btn-menu-side"
      mat-flat-button
      color="accent"
      [routerLink]="['dashboard']"
    >
      Dashboard
    </button>
    <button
      fxFlex="10"
      *ngIf="offlineService.isOnline && this.rol !== 'externo'"
      type="button"
      class="btn-menu-side"
      mat-flat-button
      color="accent"
      [routerLink]="['dashboard-full']"
    >
      <mat-icon>computer</mat-icon>
    </button>
  </div>
</div>

<div
  class="p-3"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="24px"
>
  <button
    type="button"
    class="btn-menu"
    mat-flat-button
    color="warn"
    (click)="salir()"
  >
    Salir
  </button>
</div> -->
