import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../modulos/login/login.service';
import { api } from '../../environments/environment';
import { saveAs } from 'file-saver';

export interface IHttpRequestOptions {
  headers?: HttpHeaders;
  context?: HttpContext;
  observe?: 'body' | 'events' | 'response' | any;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json' | any;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public openConnections = 0;

  constructor(private http: HttpClient, private loginService: LoginService) {}

  get sincronizando() {
    return this.openConnections ? true : false;
  }

  public getFile(
    url: string,
    filename: string,
    params?: HttpParams
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.get(url, { params, responseType: 'blob' }).subscribe(
        (response: any) => {
          try {
            const file = new File([response], filename);
            saveAs(file);
            resolve();
          } catch (error) {
            console.error(error);
            resolve();
          }
        },
        (error: any) => {
          console.error(error);
          resolve();
        }
      );
    });
  }

  public get(
    ruta: string,
    options?: IHttpRequestOptions,
    noCache = false
  ): Observable<any> {
    return new Observable((observer) => {
      this.openConnections++;
      this._get(ruta, options, noCache)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
          this.openConnections--;
        })
        .catch(async (err) => {
          if (err.error.message === 'Invalid token: access token has expired') {
            await this.loginService.refreshToken();
            this._get(ruta, options, noCache)
              .then((resp) => {
                observer.next(resp);
                observer.complete();
                this.openConnections--;
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
                observer.complete();
                this.openConnections--;
              });
          } else {
            observer.error(err);
            observer.complete();
            this.openConnections--;
          }
        });
    });
  }

  public post(ruta: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this.openConnections++;
      this._post(ruta, body)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
          this.openConnections--;
        })
        .catch(async (err) => {
          if (err.error.message === 'Invalid token: access token has expired') {
            await this.loginService.refreshToken();
            this._post(ruta, body)
              .then((resp) => {
                observer.next(resp);
                observer.complete();
                this.openConnections--;
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
                observer.complete();
                this.openConnections--;
              });
          } else {
            observer.error(err);
            observer.complete();
            this.openConnections--;
          }
        });
    });
  }

  public put(ruta: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this.openConnections++;
      this._put(ruta, body)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
          this.openConnections--;
        })
        .catch(async (err) => {
          if (err.error.message === 'Invalid token: access token has expired') {
            await this.loginService.refreshToken();
            this._put(ruta, body)
              .then((resp) => {
                observer.next(resp);
                observer.complete();
                this.openConnections--;
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
                observer.complete();
                this.openConnections--;
              });
          } else {
            observer.error(err);
            observer.complete();
            this.openConnections--;
          }
        });
    });
  }

  public delete(ruta: string): Observable<any> {
    return new Observable((observer) => {
      this.openConnections++;
      this._delete(ruta)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
          this.openConnections--;
        })
        .catch(async (err) => {
          if (err.error.message === 'Invalid token: access token has expired') {
            await this.loginService.refreshToken();
            this._delete(ruta)
              .then((resp) => {
                observer.next(resp);
                observer.complete();
                this.openConnections--;
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
                observer.complete();
                this.openConnections--;
              });
          } else {
            observer.error(err);
            observer.complete();
            this.openConnections--;
          }
        });
    });
  }

  private _get<T>(
    ruta: string,
    options?: IHttpRequestOptions,
    noCache = false
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      // Seteo el header de autorizacion
      const headers = new HttpHeaders({
        Authorization: `Bearer ${LoginService.getToken()}`,
      });
      if (!options) {
        options = {};
      }
      if (options.headers) {
        options.headers = options.headers.append(
          'Authorization',
          'Bearer ' + LoginService.getToken()
        );
      } else {
        options.headers = headers;
      }
      if (noCache) {
        options.headers = options.headers.append('no-cache', 'true');
      }

      // Request
      this.http.get<T>(`${api}${ruta}`, options).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _post(ruta: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${LoginService.getToken()}`,
      });
      const req = this.http.post<any>(`${api}${ruta}`, body, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _put(ruta: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${LoginService.getToken()}`,
      });
      const req = this.http.put<any>(`${api}${ruta}`, body, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _delete(ruta: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${LoginService.getToken()}`,
      });
      const req = this.http.delete<any>(`${api}${ruta}`, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
