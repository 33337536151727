import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IUsuario,
  ICreateUsuario,
  IUpdateUsuario,
  TipoUsuario,
  IDepartamento,
  ILocalidad,
  IPermiso,
  IZona,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { UsuariosService } from '../usuarios.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';

@Component({
  selector: 'app-crear-editar-usuarios',
  templateUrl: './crear-editar-usuarios.component.html',
  styleUrls: ['./crear-editar-usuarios.component.scss'],
})
export class CrearEditarUsuariosComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;
  public hide = true;

  public roles: TipoUsuario[] = ['Admin', 'Lector', 'Relevador'];

  public departamentos: IDepartamento[][] = [];
  public localidades: ILocalidad[][] = [];
  public zonas: IZona[][] = [];

  get formArrayPermisos() {
    return this.form?.get('permisos') as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUsuario,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarUsuariosComponent>,
    private service: UsuariosService,
    public helper: HelperService,
    public listadosOffline: ListadosOfflineService
  ) {}

  getUsernameErrorMessage() {
    if (this.form?.get('username')?.hasError('required')) {
      return 'Debe Ingresar un Email';
    }
    return this.form?.get('username')?.hasError('email')
      ? 'Email inválido'
      : '';
  }

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Usuario' : 'Crear Usuario';
    const formPermisos: FormGroup[] = [];

    if (this.data?.permisos?.length) {
      for (const permiso of this.data?.permisos) {
        formPermisos.push(
          this.fb.group({
            tipo: [permiso.tipo, Validators.required],
            global: [permiso.global],
            idsZona: [permiso.idsZona],
            idsRegion: [permiso.idsRegion],
            idsProvincia: [permiso.idsProvincia],
            idsDepartamento: [permiso.idsDepartamento],
            idsLocalidad: [permiso.idsLocalidad],
          })
        );
      }
    } else {
      formPermisos.push(
        this.fb.group({
          tipo: [null, Validators.required],
          global: [null],
          idsZona: [[]],
          idsRegion: [[]],
          idsProvincia: [[]],
          idsDepartamento: [[]],
          idsLocalidad: [[]],
        })
      );
    }

    this.form = this.fb.group({
      username: [this.data?.username, [Validators.required, Validators.email]],
      password: ['', !this.data ? Validators.required : null],
      permisos: this.fb.array(formPermisos),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    const data: ICreateUsuario = this.form?.value;
    return data;
  }

  private getUpdateData() {
    const data: IUpdateUsuario = this.form?.value;
    // Password
    delete data.password;
    const password = this.form?.get('password')?.value;
    if (password) {
      data.password = password;
    }
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public agregarPermiso() {
    this.formArrayPermisos.push(
      this.fb.group({
        tipo: [null, Validators.required],
        global: [null],
        idsZona: [[]],
        idsRegion: [[]],
        idsProvincia: [[]],
        idsDepartamento: [[]],
        idsLocalidad: [[]],
      })
    );
    this.filtrarDepartamentos(this.formArrayPermisos.length - 1);
    this.filtrarLocalidades(this.formArrayPermisos.length - 1);
    this.filtrarZonas(this.formArrayPermisos.length - 1);
  }

  public eliminarPermiso(i: number) {
    this.formArrayPermisos.removeAt(i);
  }

  //

  public filtrarLocalidades(i: number): void {
    const idsProvincia: string[] =
      this.formArrayPermisos?.value[i].idsProvincia || [];
    const idsDepartamento: string[] =
      this.formArrayPermisos?.value[i].idsDepartamento || [];

    this.localidades[i] = this.listadosOffline.localidads.filter((d) => {
      return (
        !idsProvincia.includes(d.idProvincia) &&
        !idsDepartamento.includes(d.idDepartamento)
      );
    });
  }

  public filtrarDepartamentos(i: number): void {
    const idsProvincia: string[] =
      this.formArrayPermisos?.value[i].idsProvincia || [];

    this.departamentos[i] = this.listadosOffline.departamentos.filter((d) => {
      return !idsProvincia.includes(d.idProvincia);
    });
  }

  public filtrarZonas(i: number): void {
    const idsRegion: string[] =
      this.formArrayPermisos?.value[i].idsRegion || [];

    const regionesSeleccionadas = this.listadosOffline.regions.filter((r) => {
      return idsRegion.includes(r._id);
    });

    this.zonas[i] = this.listadosOffline.zonas.filter((d) => {
      return !regionesSeleccionadas.some((r) => r.idsZonas?.includes(d._id));
    });
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    this.formArrayPermisos.value.forEach((element: IPermiso, index: number) => {
      this.filtrarDepartamentos(index);
      this.filtrarLocalidades(index);
      this.filtrarZonas(index);
    });
    this.loading = false;
  }
}
