<mat-card>
  <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    {{ title }}
  </mat-card-title>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form id="formSubsegmento" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <!-- Nombre  -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <!-- Zonas -->
      <ng-select
        *ngIf="listadosOffline.zonas.length"
        placeholder="Zonas"
        formControlName="idsZonas"
        notFoundText="No se encontraron zonas"
        [items]="listadosOffline.zonas"
        bindValue="_id"
        [virtualScroll]="true"
        [multiple]="true"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        [clearable]="true"
        [appendTo]="'body'"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-icon right" (click)="clear(item)">×</span>
          <span>{{ item.nombre }} </span>
          <span style="font-size: 0.7em"></span>
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div style="padding-bottom: 5px">
            <span>{{ item.nombre }} </span>
            <span style="font-size: 0.7em"> </span>
          </div>
        </ng-template>
      </ng-select>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="formSubsegmento"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
