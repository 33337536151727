import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IRegion,
  ICreateRegion,
  IListado,
  IQueryMongo,
  IUpdateRegion,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<IRegion>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/regions`, { params });
  }

  public crear(dato: ICreateRegion): Observable<IRegion> {
    return this.http.post(`/regions`, dato);
  }

  public listarPorId(id: string): Observable<IRegion> {
    return this.http.get(`/regions/${id}`);
  }

  public editar(id: string, dato: IUpdateRegion): Observable<IRegion> {
    return this.http.put(`/regions/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/regions/${id}`);
  }
}
