import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ICreateZona, IDepartamento, ILocalidad, IZona } from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { ZonasService } from '../zonas.service';

@Component({
  selector: 'app-crear-editar-zona',
  templateUrl: './crear-editar-zona.component.html',
  styleUrls: ['./crear-editar-zona.component.scss'],
})
export class CrearEditarZonaComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public departamentos: IDepartamento[] = [];
  public localidads: ILocalidad[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IZona,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarZonaComponent>,
    public helper: HelperService,
    private service: ZonasService,
    public listadosOffline: ListadosOfflineService,
    public matDialog: MatDialog
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Zona' : 'Crear Zona';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idsProvincias: [this.data?.idsProvincias || []],
      idsDepartamentos: [this.data?.idsDepartamentos || []],
      idsLocalidades: [this.data?.idsLocalidades || []],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateZona = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      let result: IZona;
      if (this.data?._id) {
        result = await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        result = await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(result);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  public filtrarDepartamentos(): void {
    const idsProvincias: string[] = this.form?.value.idsProvincias;
    if (idsProvincias.length) {
      this.departamentos = this.listadosOffline.departamentos.filter(
        (p) => !idsProvincias.includes(p.idProvincia)
      );
    } else {
      this.departamentos = this.listadosOffline.departamentos;
    }
  }

  public filtrarLocalidades(): void {
    const idsProvincias: string[] = this.form?.value.idsProvincias;
    const idsDepartamentos: string[] = this.form?.value.idsDepartamentos;
    if (idsProvincias.length || idsDepartamentos.length) {
      this.localidads = this.listadosOffline.localidads.filter((p) => {
        if (idsProvincias.length) {
          if (idsProvincias.includes(p.idProvincia)) {
            return false;
          }
        }
        if (idsDepartamentos.length) {
          if (idsDepartamentos.includes(p.idDepartamento)) {
            return false;
          }
        }
        return true;
      });
    } else {
      this.localidads = this.listadosOffline.localidads;
    }
  }

  // Listados

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    this.filtrarDepartamentos();
    this.filtrarLocalidades();
    this.loading = false;
  }
}
