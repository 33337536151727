import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  Options,
  PointOptionsObject,
  Series,
  SeriesBarOptions,
  SeriesLineOptions,
  SeriesOptionsType,
  SeriesScatterOptions,
} from 'highcharts';
import { HighchartsChartComponent } from 'highcharts-angular';
import {
  IFilter,
  IGrupoComparativo,
  IProducto,
  IQueryMongo,
  IResumenRelevamiento,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ChartComponent } from '../../../auxiliares/chart/chart/chart.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { condicionesComerciales, frecuencias } from '../../relevamientos/datos';

type PeriodosPredefinidos =
  | 'Última Semana'
  | 'Últimos 15 días'
  | 'Último mes'
  | 'Últimos 3 meses'
  | 'Ultimos 6 meses'
  | 'Último año';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  get darkTheme() {
    return localStorage.getItem('temaOscuro');
  }

  @ViewChild('chart01') private chart01?: ChartComponent;
  @ViewChild('chart02') private chart02?: ChartComponent;

  public loading = false;
  public chart?: Options;
  public chart2?: Options;

  public coloresPorEmpresa: boolean = true;
  public precioPorHect: boolean = false;
  public puntos: boolean = false;
  public lineas: boolean = true;

  public agrupaciones = [
    { nombre: 'Producto' },
    { nombre: 'Grupo' },
    { nombre: 'Subsegmento Propio' },
  ];
  public agrupacion: string = 'Grupo';

  public producto?: IProducto;

  public idGrupo?: string;
  public idSegmento?: string;
  public idSubsegmento?: string;
  public idSubsegmentoPropio?: string;

  public condicionesComerciales = condicionesComerciales;
  public condicionComercial = 'Distribuidor';

  public periodosPredefinidos: PeriodosPredefinidos[] = [
    'Última Semana',
    'Últimos 15 días',
    'Último mes',
    'Últimos 3 meses',
    'Ultimos 6 meses',
    'Último año',
  ];
  public periodoPredefinido: PeriodosPredefinidos =
    (this.helper.getPeriodoPredefinido() as PeriodosPredefinidos) ||
    'Últimos 3 meses';

  public periodo = this.helper.DesdeHastaISO(12 * 7);

  public frecuencias = frecuencias;
  public frecuencia: string = '%V';

  public cantidadMinimaRelevamientos: number =
    this.helper.getCantidadMinimaRelevamientos();

  public resumenRelevamientos: IResumenRelevamiento[] = [];

  // Listado continuo
  public resumenRelevamientos$?: Subscription;

  constructor(
    public helper: HelperService,
    private listadosService: ListadosService,
    public listadosOffline: ListadosOfflineService
  ) {}

  public nextTheme() {
    if (this.chart01) {
      this.chart01?.nextTheme();
      this.chart02?.nextTheme();
    } else {
      this.helper.notifWarn('No hay gráficos para cambiar de tema');
    }
  }

  public scrollToBottom(): void {
    // this.chartContainer?.nativeElement.scrollIntoView(true);
  }
  //
  public cambioFrecuencia() {
    switch (this.frecuencia) {
      case '%Y-%m-%d': {
        this.periodo = this.helper.DesdeHastaISO(12);
        break;
      }
      case '%V': {
        this.periodo = this.helper.DesdeHastaISO(12 * 7);
        break;
      }
      case '%Y-%m': {
        this.periodo = this.helper.DesdeHastaISO(365);
        break;
      }
      case '%Y': {
        this.periodo = this.helper.DesdeHastaISO(12 * 365);
        break;
      }
    }
  }

  public cambioPeriodoPredefinido() {
    this.helper.setPeriodoPredefinido(this.periodoPredefinido);
    switch (this.periodoPredefinido) {
      case 'Última Semana': {
        this.frecuencia = '%Y-%m-%d';
        this.periodo = this.helper.DesdeHastaISO(7);
        break;
      }
      case 'Últimos 15 días': {
        this.frecuencia = '%Y-%m-%d';
        this.periodo = this.helper.DesdeHastaISO(15);
        break;
      }
      case 'Último mes': {
        this.frecuencia = '%V';
        this.periodo = this.helper.DesdeHastaISO(30);
        break;
      }
      case 'Últimos 3 meses': {
        this.frecuencia = '%V';
        this.periodo = this.helper.DesdeHastaISO(90);
        break;
      }
      case 'Ultimos 6 meses': {
        this.frecuencia = '%Y-%m';
        this.periodo = this.helper.DesdeHastaISO(180);
        break;
      }
      case 'Último año': {
        this.frecuencia = '%Y-%m';
        this.periodo = this.helper.DesdeHastaISO(365);
        break;
      }
    }
  }

  public cambioCantidadMinimaRelevamientos() {
    this.helper.setCantidadMinimaRelevamientos(
      this.cantidadMinimaRelevamientos
    );
  }

  public volver() {
    window.history.back();
  }

  // Grafico

  private weekDateToDate(year: number, week: number, day: number) {
    const firstDayOfYear = new Date(year, 0, 1);
    const days = 1 + day + week * 7 - firstDayOfYear.getDay();
    return new Date(year, 0, days);
  }

  private getTimestamp(fecha: string, frecuencia: string) {
    let date = new Date(fecha);
    if (frecuencia === 'Semanal') {
      const year = new Date(this.periodo.desde).getFullYear();
      date = this.weekDateToDate(year, +fecha, 1);
      return date.getTime() - 1000 * 60 * 60 * 3;
    }
    return date.getTime();
  }

  private getTimeFormat(frecuencia: string) {
    if (frecuencia === 'Diario') {
      return '{value:%d/%m}';
    } else if (frecuencia === 'Semanal') {
      return '{value:%d/%m/%y}';
    } else if (frecuencia === 'Mensual') {
      return '{value:%m/%Y}';
    } else if (frecuencia === 'Anual') {
      return '{value:%Y}';
    }
    return;
  }

  private seriesName(frecuencia: string, timestamp: number) {
    const date = new Date(timestamp + 1000 * 60 * 60 * 3);
    if (frecuencia === 'Diario' || frecuencia === 'Semanal') {
      return date.toLocaleDateString();
    } else if (frecuencia === 'Mensual') {
      return `${date.getMonth() + 1}/${date.getFullYear()}`;
    } else if (frecuencia === 'Anual') {
      return `${date.getFullYear()}`;
    }
    return;
  }

  private crearGrafico(
    resumenesRelevamientos: IResumenRelevamiento[],
    frecuencia: string = ''
  ) {
    this.chart = undefined;
    const series: SeriesOptionsType[] = [];
    const tickPositions: number[] = [];

    let index = 0;
    let sumaComp: { [timestamp: number]: { suma: number; cant: number } } = {};

    const scatters: SeriesScatterOptions[] = [];
    let lines: SeriesLineOptions[] = [];

    for (const resumenProducto of resumenesRelevamientos) {
      if (resumenProducto.RelevamientosPorFecha.length) {
        let cantRelevamientos = 0;
        resumenProducto.RelevamientosPorFecha.forEach((fecha) => {
          cantRelevamientos += fecha.precios.length;
        });

        const serieScatter: SeriesScatterOptions & { nombre: string } = {
          nombre:
            resumenProducto.producto?.nombre + resumenProducto.empresa?.nombre,
          type: 'scatter',
          name: `<span  style="font-size: 0.75em">${resumenProducto.producto?.nombre} <small style="font-weight: 300;">(${resumenProducto.empresa?.nombre}) (${cantRelevamientos})</small></span>`,
          color: this.coloresPorEmpresa
            ? resumenProducto.empresa.color || '#8C92AC'
            : index === 0
            ? 'rgba(255, 0, 0, .5)'
            : 'rgba(0, 0, 255, .5)',
          visible:
            cantRelevamientos > this.cantidadMinimaRelevamientos ? true : false,
          data: [],
        };
        const serieLine: SeriesLineOptions & { nombre: string } = {
          nombre:
            resumenProducto.producto?.nombre + resumenProducto.empresa?.nombre,
          type: 'line',
          name: `<span  style="font-size: 0.75em">${resumenProducto.producto?.nombre} <small style="font-weight: 300;">(${resumenProducto.empresa?.nombre})</small> / U$S Medio</span> `,
          color: this.coloresPorEmpresa
            ? resumenProducto.empresa.color || '#8C92AC'
            : index === 0
            ? 'rgba(255, 0, 0, .5)'
            : 'rgba(0, 0, 255, .5)',
          visible: index === 0 ? true : false,
          data: [],
        };

        for (const dato of resumenProducto.RelevamientosPorFecha) {
          const timestamp = this.getTimestamp(dato.fecha, frecuencia);
          tickPositions.push(timestamp);

          let suma = 0;
          for (const precio of dato.precios) {
            // Agrega el punto
            serieScatter.data?.push({
              name: this.seriesName(frecuencia, timestamp),
              x: timestamp,
              y: this.precioPorHect
                ? precio * (resumenProducto.producto?.dosisMedia || 0)
                : precio,
              marker: {
                radius: 8,
              },
            });

            // Suma el producto actual para la fecha
            suma += precio;

            // Suma todos los competidores // index 0 es el producto elegido
            if (
              resumenesRelevamientos.length > 1 &&
              (this.agrupacion !== 'Producto' || index !== 0)
            ) {
              if (sumaComp[timestamp]) {
                sumaComp[timestamp].suma += this.precioPorHect
                  ? precio * (resumenProducto.producto?.dosisMedia || 0)
                  : precio;
                sumaComp[timestamp].cant++;
              } else {
                sumaComp[timestamp] = {
                  cant: 1,
                  suma: this.precioPorHect
                    ? precio * (resumenProducto.producto?.dosisMedia || 0)
                    : precio,
                };
              }
            }
          }

          // Serie de promedio del prodcuto
          serieLine.data?.push({
            name: this.seriesName(frecuencia, timestamp),
            x: timestamp,
            y: this.precioPorHect
              ? +(
                  (suma * (resumenProducto.producto.dosisMedia || 0)) /
                  dato.precios.length
                ).toFixed(2)
              : +(suma / dato.precios.length).toFixed(2),
            marker: {
              radius: 5,
            },
            color: this.coloresPorEmpresa
              ? this.helper.stringToColor(resumenProducto.empresa?.nombre)
              : undefined,
          });
        }

        if (!this.puntos) {
          scatters.push(serieScatter);
        }
        if (!this.lineas) {
          lines.push(serieLine);
        }
      }
      index++;
    }

    scatters.sort((a, b) => {
      if (a.data && b.data) {
        if (a.data?.length > b.data?.length) {
          return -1;
        } else if (a.data?.length < b.data?.length) {
          return 1;
        }
      }
      return 0;
    });
    lines = lines.filter((line) => line.data?.length! > 1);

    const sortLines: SeriesLineOptions[] = [];
    scatters.forEach((serie) => {
      const nombre = (serie as any).nombre;
      const line = lines.find((line) => (line as any).nombre === nombre);
      if (line) {
        sortLines.push(line);
      }
    });

    series.push(...scatters, ...sortLines);

    // Crea la serie de promedio de comp
    const serieComp: SeriesLineOptions = {
      type: 'line',
      name: `Media Competidores`,
      color: 'rgba(0, 255, 0, 1)',
      visible: true,
      data: [],
    };
    for (const timestamp in sumaComp) {
      serieComp.data?.push({
        name: new Date(+timestamp).toLocaleDateString(),
        x: timestamp,
        y: +(sumaComp[timestamp].suma / sumaComp[timestamp].cant).toFixed(2),
      } as any);
    }
    if (serieComp.data?.length && serieComp.data.length > 0) {
      serieComp.data = serieComp?.data?.sort((a, b) => {
        if ((a as any).x > (b as any).x) {
          return 1;
        }
        if ((a as any).x < (b as any).x) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      series.unshift(serieComp);
    }

    series.unshift({
      type: 'column',
      name: 'Mostrar/Ocultar todo',
      visible: false,
    });

    tickPositions.sort();

    const chartOptions: Options = {
      chart: {
        zoomType: 'x',
      },
      title: {
        text: undefined,
      },
      xAxis: {
        minPadding: 0.05,
        tickPositioner:
          frecuencia === 'Diario' || frecuencia === 'Semanal'
            ? function () {
                const ticks = tickPositions;
                (ticks as any).info = (this.tickPositions as any).info;
                return ticks;
              }
            : (null as any),
        tickAmount:
          frecuencia === 'Diario' || frecuencia === 'Semanal'
            ? tickPositions.length
            : (null as any),
        type: 'datetime',
        title: {
          style: {
            fontSize: '1.4em',
            color: this.darkTheme ? 'white' : 'black',
          },
          text: frecuencia,
        },
        labels: {
          style: {
            fontSize: '1.2em',
            color: this.darkTheme ? 'white' : 'black',
          },
          format: this.getTimeFormat(frecuencia),
          step: 1,
        },
      },
      yAxis: {
        title: {
          style: {
            fontSize: '1.4em',
            color: this.darkTheme ? 'white' : 'black',
          },
          text: 'U$S PCE',
        },
        labels: {
          style: {
            fontSize: '1.2em',
            color: this.darkTheme ? 'white' : 'black',
          },
        },
      },
      legend: {
        alignColumns: true,
        maxHeight: 200,

        width: '100%',
        margin: 15,
        verticalAlign: 'bottom',
        useHTML: true,
        align: 'left',
        layout: 'horizontal',
        itemStyle: {
          fontSize: '1.2em',
          color: this.darkTheme ? 'white' : 'black',
        },
      },
      plotOptions: {
        scatter: {
          tooltip: {
            xDateFormat: '%Y-%m',
            headerFormat:
              '<b style="font-size: 1.5em;  ">{series.name}</b><br>',
            pointFormat:
              '<span style="font-size: 1.5em;"> {point.name}, U$S <strong>{point.y}</strong></span>',
            valueDecimals: 2,
          },
        },
        // - parte nueva
        column: {
          events: {
            legendItemClick() {
              const chart: any = this.chart;
              const series: any = chart.series;
              if (this.index === 0) {
                if (chart.showHideFlag) {
                  series.forEach((series: Series) => {
                    series.hide();
                  });
                } else {
                  series.forEach((series: Series) => {
                    series.show();
                  });
                }
                chart.showHideFlag = !chart.showHideFlag;
              }
            },
          },
        },
        // - parte nueva
      },
      series,
    };

    this.chart = chartOptions;
  }

  private crearGraficoPromedioPorProducto() {
    this.chart2 = undefined;

    const serie: SeriesBarOptions = {
      type: 'bar',
      name: 'PCE Promedio',
      data: [],
      dataLabels: [
        {
          enabled: true,
          format: '{point.custom.cantidadMuestras} muestras',
          align: 'left',
          inside: true,
          style: {
            fontSize: '1em',
            color: this.darkTheme ? 'white' : 'black',
          },
        },
        {
          enabled: true,
          format: 'U$S {point.y:.2f}',
          inside: true,
          align: 'right',
          style: {
            fontSize: '1.2em',
            color: this.darkTheme ? 'white' : 'black',
          },
        },
      ],
    };

    for (const prod of this.resumenRelevamientos) {
      let count = 0;
      let sum = 0;

      for (const relevamiento of prod.RelevamientosPorFecha) {
        for (const precio of relevamiento.precios) {
          count++;
          sum += this.precioPorHect
            ? precio * (prod.producto?.dosisMedia || 0)
            : precio;
        }
      }
      const promedio = count ? +(sum / count).toFixed(2) : 0;

      const data: PointOptionsObject = {
        name: `${prod.producto?.nombre} (${prod.empresa?.nombre})`,
        y: promedio,
        custom: {
          cantidadMuestras: count,
        },
        color: this.coloresPorEmpresa
          ? prod.empresa?.color || '#8C92AC'
          : undefined,
      };

      if (count > this.cantidadMinimaRelevamientos) {
        serie.data?.push(data);
      }
    }

    const series: SeriesOptionsType[] = [serie];

    const chartOptions: Options = {
      chart: {
        // animation: {
        //   duration: 500,
        // },
        // marginRight: 50,
      },
      title: {
        text: 'PCE Promedio por Producto',
        align: 'left',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '1.2em',
            color: this.darkTheme ? 'white' : 'black',
          },
        },
      },
      yAxis: {
        opposite: true,
        tickPixelInterval: 100,
        title: {
          text: null,
        },
        labels: {
          style: {
            fontSize: '1.2em',
            color: this.darkTheme ? 'white' : 'black',
          },
        },
      },
      plotOptions: {
        series: {
          animation: false,
          borderWidth: 0,
          dataSorting: {
            enabled: true,
            matchByName: true,
          },
          dataLabels: {
            enabled: true,
          },
        },
      },
      series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 550,
            },
            chartOptions: {
              xAxis: {
                visible: false,
              },
              subtitle: {
                x: 0,
              },
              plotOptions: {
                series: {
                  dataLabels: [
                    {
                      enabled: true,
                      y: 8,
                    },
                    {
                      enabled: true,
                      format: '{point.name}',
                      y: -8,
                      style: {
                        fontWeight: 'normal',
                        opacity: 0.7,
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    };

    setTimeout(() => {
      this.chart2 = chartOptions;
    }, 100);
  }

  //

  public async enviar() {
    this.loading = true;
    try {
      const filter: IFilter[] = [];
      // Fecha
      const desde = new Date(this.periodo.desde);
      desde.setHours(0, 0, 0, 0);
      const hasta = new Date(this.periodo.hasta);
      hasta.setHours(23, 59, 59, 999);
      filter.push({
        field: 'fecha',
        type: 'object',
        value: { $gte: desde.toISOString(), $lte: hasta.toISOString() },
      });
      // Cond. Comercial
      filter.push({
        field: 'condicionComercial',
        type: 'string',
        value: this.condicionComercial,
      });
      // Producto
      if (this.agrupacion === 'Producto') {
        filter.push({
          field: 'producto.idProducto',
          type: 'string',
          value: this.producto?._id,
        });
      }
      // Query
      const query: IQueryMongo = {
        sort: 'fecha',
        filter: JSON.stringify(filter),
        agrupacion: this.agrupacion,
        dateFormat: this.frecuencia,
        idGrupo: this.idGrupo,
        idProducto: this.producto?._id,
        idSegmento: this.idSegmento,
        idSubsegmento: this.idSubsegmento,
        idSubsegmentoPropio: this.idSubsegmentoPropio,
      };
      // Consulta
      await this.listarResumenRelevamientos(query);
      const frecuencia = this.frecuencias.find(
        (f) => f.value === this.frecuencia
      )?.label;
      this.crearGrafico(this.resumenRelevamientos, frecuencia);
      this.crearGraficoPromedioPorProducto();
    } catch (err) {
      this.helper.notifError(err);
      console.error(err);
    }
    this.loading = false;
  }

  // Listados

  private async listarResumenRelevamientos(query: IQueryMongo): Promise<void> {
    this.resumenRelevamientos$?.unsubscribe();
    this.resumenRelevamientos$ = this.listadosService
      .subscribe<IResumenRelevamiento[]>('resumenRelevamientos', query)
      .subscribe((data) => {
        this.resumenRelevamientos = data;
        console.log(`listado de resumenRelevamientos`, data);
      });
    await this.listadosService.getLastValue('resumenRelevamientos', query);
  }

  public valid() {
    if (this.agrupacion === 'Producto' && !this.producto) return false;
    if (this.agrupacion === 'Grupo' && !this.idGrupo) return false;
    return true;
  }

  async ngOnInit(): Promise<void> {
    this.cambioPeriodoPredefinido();
  }
}
