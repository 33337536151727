import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  ICreateSubsegmentoPropio,
  IProducto,
  ISubsegmentoPropio,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { SubsegmentoPropiosService } from '../subsegmentoPropios.service';

@Component({
  selector: 'app-crear-editar-subsegmentoPropios',
  templateUrl: './crear-editar-subsegmentoPropios.component.html',
  styleUrls: ['./crear-editar-subsegmentoPropios.component.scss'],
})
export class CrearEditarSubsegmentoPropiosComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public productos: IProducto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISubsegmentoPropio,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarSubsegmentoPropiosComponent>,
    public helper: HelperService,
    private service: SubsegmentoPropiosService,
    public listados: ListadosOfflineService,
    public matDialog: MatDialog
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Subsegmento Propio'
      : 'Crear Subsegmento Propio';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idSegmento: [this.data?.idSegmento, Validators.required],
      idsProductos: [this.data?.idsProductos || []],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public filtrarProductos(): void {
    const idSegmento = this.form?.value.idSegmento;
    if (idSegmento) {
      this.productos = this.listados.productos.filter(
        (p) => p.idSegmento === idSegmento
      );
    } else {
      this.productos = this.listados.productos;
    }
  }

  //

  private getData() {
    const data: ICreateSubsegmentoPropio = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      let result: ISubsegmentoPropio;
      if (this.data?._id) {
        result = await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        result = await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(result);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // Listados

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    this.filtrarProductos();
    this.loading = false;
  }
}
