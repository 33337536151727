<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div style="padding: 1em">
  <form *ngIf="form" id="form" [formGroup]="form" autocomplete="off">
    <h2 class="title text-center">{{ "Editar registro" | uppercase }}</h2>

    <!-- Fecha y camp agricola -->
    <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">
      <mat-form-field fxFlex="48">
        <mat-label>Fecha</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="fecha"
          required
          (dateChange)="cambioFecha($event.value)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field fxFlex="48">
        <mat-label>Campaña Agrícola</mat-label>
        <mat-select formControlName="campAgricola" required>
          <mat-option *ngFor="let dato of campAgricolas" [value]="dato">{{
            dato
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Localidad -->
    <ng-select
      *ngIf="listados.localidads.length"
      placeholder="Localidad"
      formControlName="idLocalidad"
      notFoundText="No se encontraron localidades"
      [items]="listados.localidads"
      bindValue="_id"
      [searchFn]="helper.searchLocalidad"
      [virtualScroll]="true"
      required
    >
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.nombre }} </span>
        <span style="font-size: 0.7em"> ({{ item.provincia.nombre }})</span>
      </ng-template>

      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <div style="padding-bottom: 5px">
          <span>{{ item.nombre }} </span>
          <span style="font-size: 0.7em">
            ({{ item.departamento?.nombre }} |
            {{ item.provincia.nombre }})</span
          >
        </div>
      </ng-template>
    </ng-select>

    <div *ngIf="!listados.localidads.length" class="error-localidades">
      No se puede crear un relevamiento ya que no hay localidades
    </div>

    <!-- Condicion comercial y tipo de relevamiento -->
    <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">
      <mat-form-field fxFlex="48">
        <mat-label
          >Condición Comercial <small>(nivel de precio)</small></mat-label
        >
        <mat-select formControlName="condicionComercial" required>
          <ng-container *ngFor="let dato of condicionesComerciales">
            <mat-option [value]="dato">{{ dato }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="48">
        <mat-label>Tipo de Relevamiento</mat-label>
        <mat-select formControlName="tipoRelevamiento" required>
          <ng-container *ngFor="let dato of tiposRelevamiento">
            <mat-option [value]="dato">{{ dato }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <!--------------->
    <!-- Productos -->
    <!--------------->
    <h3 class="title text-center">
      {{ "Precio relevado del producto" | uppercase }}
    </h3>

    <div
      style="margin-bottom: 1em; padding: 0.5em"
      [ngClass]="productoValido(productoSeleccionado) ? 'valid' : 'not-valid'"
      fxLayout="column"
      fxLayoutAlign="space-around center"
    >
      <!-- Producto - Unidad - Volumen -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between end"
        fxLayoutGap="10px"
        fxFill
      >
        <div fxFlex="61">
          <ng-select
            style="width: 100%"
            placeholder="Producto"
            [items]="listados.productos"
            bindValue="_id"
            [(ngModel)]="productoSeleccionado.idProducto"
            [ngModelOptions]="{ standalone: true }"
            notFoundText="No se encontraron productos"
            [searchFn]="helper.searchProducto"
            [virtualScroll]="true"
            required
          >
            <ng-template ng-label-tmp let-item="item">
              <strong>
                <span>{{ item.nombre }} </span>
                <span style="font-size: 0.7em">
                  ({{ item.empresa?.nombre }})</span
                >
              </strong>
            </ng-template>

            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <div
                fxLayout="column"
                style="
                  padding-top: 0.5em;
                  padding-bottom: 0.5em;
                  line-height: 1em !important;
                "
              >
                <div style="padding-bottom: 5px">
                  <span>{{ item.nombre }} </span>
                  <span style="font-size: 0.7em">
                    ({{ item.empresa?.nombre }})</span
                  >
                </div>
                <div
                  fxLayout="row"
                  style="font-size: 0.6em !important; padding-left: 16px"
                >
                  <span
                    *ngFor="let composicion of item.composicion; let i = index"
                  >
                    <span *ngIf="i !== 0">&nbsp; | </span>
                    <span
                      >{{ composicion.principioActivo?.nombre }}
                      {{ composicion.concentracion }}%</span
                    >
                  </span>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>

        <!-- Unidad -->
        <mat-form-field fxFlex="15">
          <mat-label>Unidad</mat-label>
          <input
            matInput
            placeholder="Unidad"
            value="{{ unidadProducto(productoSeleccionado.idProducto) }}"
            readonly
            disabled
          />
        </mat-form-field>

        <!-- Volumen -->
        <mat-form-field fxFlex="20">
          <mat-label>Volumen</mat-label>
          <input
            matInput
            type="number"
            placeholder=""
            [(ngModel)]="productoSeleccionado.volumen"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>

      <!-- Moneda - Cotizacion - Precios Final -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between end"
        fxLayoutGap="10px"
        fxFill
      >
        <!-- Moneda -->
        <mat-form-field
          [fxFlex]="productoSeleccionado.moneda === '$' ? 14 : 14"
        >
          <mat-label>Moneda</mat-label>
          <mat-select
            required
            [(ngModel)]="productoSeleccionado.moneda"
            [ngModelOptions]="{ standalone: true }"
            (selectionChange)="cambioMonedaPago()"
          >
            <mat-option value="U$S">U$S</mat-option>
            <mat-option value="$">$</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Cotizacion -->
        <mat-form-field fxFlex="20" *ngIf="productoSeleccionado.moneda === '$'">
          <mat-label>Cotización</mat-label>
          <!-- <span matPrefix>$ &nbsp;</span> -->
          <input
            matInput
            type="number"
            placeholder=""
            [(ngModel)]="dolar"
            [ngModelOptions]="{ standalone: true }"
            disabled
          />
          <span matSuffix>
            <mat-icon
              color="accent"
              #tooltip2="matTooltip"
              matTooltip="Cotización del dólar BNA venta"
              (click)="$event.stopPropagation(); tooltip2.toggle()"
            >
              help
            </mat-icon>
          </span>
        </mat-form-field>

        <!-- Precio SIN IVA -->
        <mat-form-field
          [fxFlex]="productoSeleccionado.moneda === '$' ? 32 : 42"
        >
          <mat-label>Precio sin IVA</mat-label>
          <span matPrefix>{{ productoSeleccionado.moneda }} &nbsp;</span>
          <input
            matInput
            type="number"
            placeholder=""
            [(ngModel)]="productoSeleccionado.precioFinal"
            [ngModelOptions]="{ standalone: true }"
            (keyup)="calcularPCE()"
            required
          />
          <span matSuffix>
            <mat-icon
              color="accent"
              #tooltip1="matTooltip"
              matTooltip="Precio final sin impuestos"
              (click)="$event.stopPropagation(); tooltip1.toggle()"
            >
              help
            </mat-icon>
          </span>
        </mat-form-field>

        <!-- PCE -->
        <mat-form-field
          [fxFlex]="productoSeleccionado.moneda === '$' ? 26 : 38"
        >
          <mat-label><strong>PCE</strong></mat-label>
          <span matPrefix>U$S &nbsp;</span>
          <input
            matInput
            type="number"
            placeholder=""
            [(ngModel)]="productoSeleccionado.precioContadoU$S"
            [ngModelOptions]="{ standalone: true }"
            disabled
          />
          <span matSuffix>
            <mat-icon
              color="accent"
              #tooltip2="matTooltip"
              matTooltip="Precio contado equivalente en U$S sin impuestos"
              (click)="$event.stopPropagation(); tooltip2.toggle()"
            >
              help
            </mat-icon>
          </span>
        </mat-form-field>
      </div>
    </div>

    <!---------->
    <!-- Pago -->
    <!---------->
    <h3 class="title text-center">{{ "Pago" | uppercase }}</h3>

    <ng-container
      *ngFor="
        let metodoPagoSeleccionado of metodosPagoSeleccionados;
        let i = index
      "
    >
      <div
        style="margin-bottom: 1em; padding: 0.5em"
        [ngClass]="
          metodoPagoValido(metodoPagoSeleccionado) ? 'valid' : 'not-valid'
        "
        fxLayout="column"
        fxLayoutAlign="space-around center"
      >
        <!-- Metodo y porcentaje -->
        <div
          fxLayout="row"
          fxLayoutAlign="space-between end"
          fxLayoutGap="10px"
          fxFill
        >
          <!-- Metodo de Pago -->
          <mat-form-field fxFlex="38">
            <mat-label>Método de pago</mat-label>
            <mat-select
              required
              [(ngModel)]="metodoPagoSeleccionado.metodo"
              [ngModelOptions]="{ standalone: true }"
              (selectionChange)="cambioMetodoPago(i); calcularPCETodos()"
            >
              <ng-container *ngFor="let dato of metodosPago">
                <mat-option [value]="dato">{{ dato }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- Moneda -->
          <mat-form-field fxFlex="28">
            <mat-label>Moneda</mat-label>
            <mat-select
              required
              [(ngModel)]="metodoPagoSeleccionado.moneda"
              [ngModelOptions]="{ standalone: true }"
              (selectionChange)="cambioMonedaMetodoPago(i)"
            >
              <mat-option value="U$S">U$S</mat-option>
              <mat-option value="$">$</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Porcentaje de pago -->
          <mat-form-field fxFlex="28">
            <mat-label>% de Pago</mat-label>
            <input
              matInput
              type="number"
              required
              max="100"
              min="0"
              disabled="true"
              [(ngModel)]="metodoPagoSeleccionado.porcentajePago"
              [ngModelOptions]="{ standalone: true }"
              (change)="cambioProcentajePago(i); calcularPCETodos()"
            />
            <span matSuffix>%</span>
          </mat-form-field>
        </div>

        <!-- Plazo y Tasa -->
        <div
          fxLayout="row"
          fxLayoutAlign="space-between end"
          fxLayoutGap="10px"
          fxFill
        >
          <!-- Plazo -->
          <mat-form-field fxFlex="48">
            <mat-label>Plazo</mat-label>
            <mat-select
              required
              [(ngModel)]="metodoPagoSeleccionado.plazoDias"
              [ngModelOptions]="{ standalone: true }"
              (selectionChange)="calcularPCETodos()"
            >
              <ng-container *ngFor="let dato of plazosPago">
                <mat-option [value]="dato">{{ dato }} Días</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- Tasa mensual -->
          <mat-form-field fxFlex="48">
            <mat-label>Tasa Interes Mensual</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="metodoPagoSeleccionado.tasaMensual"
              [ngModelOptions]="{ standalone: true }"
              (change)="calcularPCETodos()"
            />
            <span matSuffix>%</span>
          </mat-form-field>
        </div>

        <!-- Tarjeta y dias libres -->
        <div
          *ngIf="metodoPagoSeleccionado?.metodo === 'Tarjeta'"
          fxLayout="row"
          fxLayoutAlign="space-between end"
          fxLayoutGap="10px"
          fxFill
        >
          <!-- Tarjeta -->
          <mat-form-field fxFlex="48">
            <mat-label>Tarjeta</mat-label>
            <mat-select
              required
              [(ngModel)]="metodoPagoSeleccionado.tarjeta"
              [ngModelOptions]="{ standalone: true }"
            >
              <ng-container *ngFor="let dato of tarjetas">
                <mat-option [value]="dato">{{ dato }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- Dias Libres -->
          <mat-form-field fxFlex="48">
            <mat-label>Dias Libres</mat-label>
            <input
              matInput
              required
              [(ngModel)]="metodoPagoSeleccionado.diasLibres"
              [ngModelOptions]="{ standalone: true }"
              (change)="calcularPCETodos()"
            />
          </mat-form-field>
        </div>

        <!-- plus y comision canje -->
        <div
          *ngIf="metodoPagoSeleccionado?.metodo === 'Canje'"
          fxLayout="row"
          fxLayoutAlign="space-between end"
          fxLayoutGap="10px"
          fxFill
        >
          <!-- Plus Canje -->
          <mat-form-field fxFlex="48">
            <mat-label>Plus de Canje</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="metodoPagoSeleccionado.plusCanje"
              [ngModelOptions]="{ standalone: true }"
              (change)="calcularPCETodos()"
            />
            <span matSuffix>%</span>
          </mat-form-field>

          <!-- Comision Canje -->
          <mat-form-field fxFlex="48">
            <mat-label>Comisión de Canje</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="metodoPagoSeleccionado.comisionCanje"
              [ngModelOptions]="{ standalone: true }"
              (change)="calcularPCETodos()"
            />
            <span matSuffix>%</span>
          </mat-form-field>
        </div>

        <!-- Eliminar Producto -->
        <button
          *ngIf="metodosPagoSeleccionados.length > 1"
          class="prefix-btn"
          type="button"
          color="warn"
          mat-mini-fab
          tabindex="-1"
          matTooltip="Eliminar"
          (click)="quitarMetodoPago(i)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-container>

    <!-- Agregar metodo de pago -->
    <button
      *ngIf="mostrarAgregarMetodoPago()"
      style="width: 100%; margin-bottom: 1em"
      mat-raised-button
      type="button"
      color="primary"
      (click)="agregarMetodoPago()"
    >
      <mat-icon style="margin-right: 0.5em">add</mat-icon>
      <span>Agregar método de pago</span>
    </button>

    <!--------------->
    <!----- MAS ----->
    <!--------------->
    <mat-expansion-panel
      style="background: rgba(255, 255, 255, 0.05); margin-bottom: 1em"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Mas </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- fuente de informacion -->
      <mat-form-field>
        <mat-label>Nombre o Razon Social</mat-label>
        <input matInput formControlName="fuente" />
      </mat-form-field>

      <!-- Observaciones -->
      <mat-form-field>
        <mat-label>Observaciones</mat-label>
        <textarea matInput rows="2" formControlName="observaciones"></textarea>
      </mat-form-field>
    </mat-expansion-panel>
  </form>

  <mat-dialog-actions
    style="margin: 1em 0"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
  >
    <!-- botón offline -->

    <button
      mat-raised-button
      type="button"
      color="accent"
      fxFlex
      [disabled]="botonEnviarDisabled()"
      (click)="update()"
    >
      GUARDAR
    </button>

    <button mat-fab type="button" color="primary" (click)="volver()">
      <mat-icon aria-label="Volver">keyboard_return</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
