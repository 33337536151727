import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IDepartamento,
  ILocalidad,
  IProducto,
  IProvincia,
  IQueryMongo,
  ISegmento,
  TipoUsuario,
  IRegion,
  IZona,
} from 'modelos/src';
import { LoginService } from '../modulos/login/login.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public pageSize = parseInt(localStorage.getItem('pageSize') || '15');
  public pageSizeOptions = [5, 10, 15, 25, 50, 100];

  constructor(private snackBar: MatSnackBar) {}

  public pageEvent($event: any): void {
    localStorage.setItem('pageSize', $event.pageSize);
    this.pageSize = $event.pageSize;
  }

  public static getQueryParams(queryParams?: IQueryMongo) {
    let params = new HttpParams();
    if (queryParams) {
      const keysIgnorar = [
        'page',
        'limit',
        'sort',
        'populate',
        'select',
        'filter',
      ];
      if (queryParams?.page) {
        params = params.set('page', queryParams.page.toString());
      }
      if (queryParams?.limit) {
        params = params.set('limit', queryParams.limit.toString());
      }
      if (queryParams?.sort) {
        params = params.set('sort', queryParams.sort);
      }
      if (queryParams?.populate) {
        params = params.set('populate', queryParams.populate);
      }
      if (queryParams?.select) {
        params = params.set('select', queryParams.select);
      }
      if (queryParams?.filter) {
        params = params.set('filter', queryParams.filter);
      }

      for (const key in queryParams) {
        if (!keysIgnorar.includes(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return params;
  }

  public esAdminGlobal(): boolean {
    const usuario = LoginService.getUsuario();
    if (usuario?.permisos) {
      return usuario.permisos.some((p) => p.tipo === 'Admin');
    }
    return false;
  }

  public stringToColor(str: string) {
    let hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (var i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  public static permisoCrearRelevamientos() {
    const user = LoginService.getUsuario();
    const tiposValidos: TipoUsuario[] = ['Admin', 'Relevador'];
    const tiposUser = user?.permisos.map((p) => p.tipo) || [];
    if (tiposValidos.some((t) => tiposUser.includes(t))) {
      return true;
    }
    return false;
  }

  public static permisoVerRelevamientos() {
    const user = LoginService.getUsuario();
    const tiposValidos: TipoUsuario[] = ['Admin', 'Lector'];
    const tiposUser = user?.permisos.map((p) => p.tipo) || [];
    if (tiposValidos.some((t) => tiposUser.includes(t))) {
      return true;
    }
    return false;
  }

  // Notificaciones
  public notifError(error: any): void {
    let errorMessage = error.error?.message;
    if (!errorMessage) {
      const typeofError = typeof error.error;
      if (typeofError === 'string') {
        errorMessage = error.error;
      } else if (typeofError === 'object') {
        errorMessage = JSON.stringify(error.error);
      }
    }
    console.error(errorMessage);
    this.snackBar.open(errorMessage, 'Cerrar', {
      panelClass: ['red-snackbar'],
    });
  }
  public notifSuccess(mensaje: string): void {
    console.log(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: 3000,
      panelClass: ['green-snackbar'],
    });
  }
  public notifWarn(mensaje: string): void {
    console.log(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', { panelClass: ['yellow-snackbar'] });
  }

  // ng-select
  public searchProducto = (term: string, item: IProducto) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.empresa?.nombre.toLowerCase().includes(term.toLowerCase()))
      return true;
    if (
      item.composicion
        ?.map((c) => c.familiaQuimica?.nombre.toLowerCase())
        .join(' ')
        .includes(term.toLowerCase())
    )
      return true;
    if (
      item.composicion
        ?.map((c) => c.principioActivo?.nombre.toLowerCase())
        .join(' ')
        .includes(term.toLowerCase())
    )
      return true;
    return false;
  };
  public searchLocalidad = (term: string, item: ILocalidad) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    // if (item.departamento?.nombre.toLowerCase().includes(term.toLowerCase()))
    //   return true;
    // if (item.provincia?.nombre.toLowerCase().includes(term.toLowerCase()))
    //   return true;
    return false;
  };
  //region
  public searchRegions = (term: string, item: IRegion) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    return false;
  };

  //zonas
  public searchZonas = (term: string, item: IZona) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    return false;
  };

  public searchDepartamento = (term: string, item: IDepartamento) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.provincia?.nombre.toLowerCase().includes(term.toLowerCase()))
      return true;
    return false;
  };
  public searchProvincia = (term: string, item: IProvincia) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    return false;
  };
  public searchSegmento = (term: string, item: ISegmento) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    return false;
  };

  public searchRegion = (term: string, item: IRegion) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    return false;
  };

  public searchZona = (term: string, item: IZona) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    return false;
  };

  // Fechas
  public DesdeHastaISO(diasAtrasDesde: number, diasAtrasHasta: number = 0) {
    const date = new Date();
    date.setDate(date.getDate() - diasAtrasHasta);
    date.setHours(0, 0, 0, 0);
    const hasta = date.toISOString();
    date.setDate(date.getDate() - diasAtrasDesde);
    date.setHours(23, 59, 59, 999);
    const desde = date.toISOString();
    return { desde, hasta };
  }

  // Configs
  public setLocalidadPorDefecto(idLocalidad: string) {
    if (idLocalidad) {
      localStorage.setItem('localidadPorDefecto', idLocalidad);
    } else {
      localStorage.removeItem('localidadPorDefecto');
    }
  }
  public getLocalidadPorDefecto(): string {
    return localStorage.getItem('localidadPorDefecto') || '';
  }

  public setUltimaLocalidadUtilizada(idLocalidad: string) {
    localStorage.setItem('ultimaLocalidadUtilizada', idLocalidad);
  }
  public getUltimaLocalidadUtilizada(): string {
    return localStorage.getItem('ultimaLocalidadUtilizada') || '';
  }

  public setPeriodoPredefinido(periodo: string) {
    localStorage.setItem('periodoPredefinido', periodo);
  }
  public getPeriodoPredefinido(): string {
    return localStorage.getItem('periodoPredefinido') || '';
  }

  public setCantidadMinimaRelevamientos(cantidad: number) {
    localStorage.setItem('cantidadMinimaRelevamientos', cantidad.toString());
  }
  public getCantidadMinimaRelevamientos(): number {
    return parseInt(localStorage.getItem('cantidadMinimaRelevamientos') || '0');
  }
}
