import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEmpresa, IListado, IQueryMongo } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class EmpresasService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<IEmpresa>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/empresas`, { params });
  }

  public listarPorId(id: string): Observable<IEmpresa> {
    return this.http.get(`/empresas/${id}`);
  }
}
