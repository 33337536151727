import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISubsegmento, IListado, IQueryMongo } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class SubsegmentosService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<ISubsegmento>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/subsegmentos`, { params });
  }

  public listarPorId(id: string): Observable<ISubsegmento> {
    return this.http.get(`/subsegmentos/${id}`);
  }
}
