import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ISubsegmentoPropio,
  ICreateSubsegmentoPropio,
  IListado,
  IQueryMongo,
  IUpdateSubsegmentoPropio,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class SubsegmentoPropiosService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryMongo
  ): Observable<IListado<ISubsegmentoPropio>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/subsegmentoPropios`, { params });
  }

  public crear(dato: ICreateSubsegmentoPropio): Observable<ISubsegmentoPropio> {
    return this.http.post(`/subsegmentoPropios`, dato);
  }

  public listarPorId(id: string): Observable<ISubsegmentoPropio> {
    return this.http.get(`/subsegmentoPropios/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateSubsegmentoPropio
  ): Observable<ISubsegmentoPropio> {
    return this.http.put(`/subsegmentoPropios/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/subsegmentoPropios/${id}`);
  }
}
