import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ICreateRegion, IRegion } from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { RegionsService } from '../regions.service';

@Component({
  selector: 'app-crear-editar-region',
  templateUrl: './crear-editar-region.component.html',
  styleUrls: ['./crear-editar-region.component.scss'],
})
export class CrearEditarRegionComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IRegion,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarRegionComponent>,
    public helper: HelperService,
    private service: RegionsService,
    public listadosOffline: ListadosOfflineService,
    public matDialog: MatDialog
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Región' : 'Crear Región';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idsZonas: [this.data?.idsZonas || []],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateRegion = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      let result: IRegion;
      if (this.data?._id) {
        result = await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        result = await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(result);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    this.loading = false;
  }
}
