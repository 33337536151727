import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DexieService } from '../../auxiliares/dexie.service';
import { ListadosOfflineService } from '../../auxiliares/listados-offline.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { WebSocketService } from '../../auxiliares/websocket';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private router: Router,
    private webSocketService: WebSocketService,
    private listadosService: ListadosService,
    private listadosOfflineService: ListadosOfflineService,
    private dexieService: DexieService
  ) {}

  public logout() {
    LoginService.removeLoginInfo();
    this.listadosService.borrarCache();
    this.listadosOfflineService.borrarCache();
    this.dexieService.closeDb();
    this.webSocketService.closeWS();
    this.router.navigate(['/login']);
  }
}
