import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IRelevamiento } from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../auxiliares/listados.service';

@Component({
  selector: 'app-detalles-relevamiento',
  templateUrl: './detalles-relevamiento.component.html',
  styleUrls: ['./detalles-relevamiento.component.scss'],
})
export class DetallesRelevamientoComponent implements OnInit, OnDestroy {
  public loading = true;
  public id?: string;
  public relevamiento?: IRelevamiento;

  private relevamiento$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listadosService: ListadosService
  ) {}

  public volver() {
    window.history.back();
  }

  private async listarRelevamiento(): Promise<void> {
    this.relevamiento$?.unsubscribe();
    this.relevamiento$ = this.listadosService
      .subscribe<IRelevamiento>('relevamiento', this.id!)
      .subscribe((data) => {
        this.relevamiento = data;
        console.log(`relevamiento`, data);
      });
    await this.listadosService.getLastValue('relevamiento', this.id!);
  }

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id')!;
    await this.listarRelevamiento();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.relevamiento$?.unsubscribe();
  }
}
