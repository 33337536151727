<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div style="margin: 1em">
  <div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between start">
      <!-- Captura -->
      <mat-card class="card" fxFlex.gt-md="49" fxFlex.lt-lg="100">
        <h2>Captura</h2>
        <table>
          <tr>
            <th>Fecha</th>
            <td>{{ relevamiento?.fecha | date: "shortDate" }}</td>
          </tr>
          <tr>
            <th>Campaña</th>
            <td>{{ relevamiento?.campAgricola }}</td>
          </tr>
          <tr>
            <th>Usuario</th>
            <td>
              {{ relevamiento?.usuario?.username }}
            </td>
          </tr>
          <tr>
            <th>Cond. Comercial</th>
            <td>{{ relevamiento?.condicionComercial }}</td>
          </tr>
          <tr>
            <th>Tipo Captura</th>
            <td>{{ relevamiento?.tipoRelevamiento }}</td>
          </tr>
        </table>
      </mat-card>

      <!-- Lugar -->
      <mat-card class="card" fxFlex.gt-md="49" fxFlex.lt-lg="100">
        <h2>Lugar</h2>
        <table>
          <tr>
            <th>Localidad</th>
            <td>{{ relevamiento?.ubicacion?.localidad?.nombre }}</td>
          </tr>
          <tr>
            <th>Departamento</th>
            <td>{{ relevamiento?.ubicacion?.departamento?.nombre }}</td>
          </tr>
          <tr>
            <th>Provincia</th>
            <td>{{ relevamiento?.ubicacion?.provincia?.nombre }}</td>
          </tr>
          <!-- <tr>
            <th>Zona</th>
            <td>{{ relevamiento?.ubicacion?.zona?.nombre }}</td>
          </tr>
          <tr>
            <th>Región</th>
            <td>{{ relevamiento?.ubicacion?.region?.nombre }}</td>
          </tr> -->
        </table>
      </mat-card>

      <!-- Producto -->
      <mat-card class="card" fxFlex.gt-md="49" fxFlex.lt-lg="100">
        <h2>Producto</h2>
        <table>
          <tr>
            <th>Marca Comercial</th>
            <td>{{ relevamiento?.producto?.producto?.nombre }}</td>
          </tr>
          <tr>
            <th>Empresa</th>
            <td>{{ relevamiento?.producto?.empresa?.nombre }}</td>
          </tr>
          <tr>
            <th>Segmento</th>
            <td>{{ relevamiento?.producto?.segmento?.nombre }}</td>
          </tr>
          <tr>
            <th>Subsegmentos</th>
            <td>
              <p
                style="margin: 0"
                *ngFor="let subsegmento of relevamiento?.producto?.subsegmentos"
              >
                {{ subsegmento.nombre }}
              </p>
            </td>
          </tr>
        </table>
        <h2 style="margin-top: 0.5em">Composición</h2>
        <ng-container *ngFor="let comp of relevamiento?.producto?.composicion">
          <div class="metodoPago">
            <table>
              <tr>
                <th>Familia Química</th>
                <td>{{ comp.familiaQuimica?.nombre }}</td>
              </tr>
              <tr>
                <th>Principio Activo</th>
                <td>{{ comp.principioActivo?.nombre }}</td>
              </tr>
              <tr>
                <th>Concentración</th>
                <td>{{ comp.concentracion }} %</td>
              </tr>
            </table>
          </div>
        </ng-container>
      </mat-card>

      <!-- Pago -->
      <mat-card class="card" fxFlex.gt-md="49" fxFlex.lt-lg="100">
        <h2>Pago</h2>
        <table>
          <tr>
            <th matTooltip="Precio Contado Equivalente">PCE</th>
            <td>
              U$S {{ relevamiento?.pago?.precioContadoU$S | number: "0.2-2" }}
            </td>
          </tr>
          <tr>
            <th matTooltip="Precio Final">Final</th>
            <td>
              U$S {{ relevamiento?.pago?.precioFinalU$S | number: "0.2-2" }}
            </td>
          </tr>
          <tr>
            <th>Volumen</th>
            <td>
              {{ relevamiento?.pago?.volumen }}
              {{ relevamiento?.producto?.producto?.unidad }}
            </td>
          </tr>
        </table>
        <h2 style="margin-top: 0.5em">Métodos</h2>
        <ng-container *ngFor="let metodo of relevamiento?.pago?.metodos">
          <div class="metodoPago">
            <table>
              <tr>
                <th>Método</th>
                <td>{{ metodo.metodo }}</td>
              </tr>
              <tr>
                <th>Porc. Pago</th>
                <td>{{ metodo.porcentajePago }} %</td>
              </tr>
              <tr>
                <th>Plazo</th>
                <td>{{ metodo.plazoDias }} días</td>
              </tr>
              <tr>
                <th>Tasa Mensual</th>
                <td>{{ metodo.tasaMensual }} %</td>
              </tr>

              <ng-container *ngIf="metodo.metodo === 'Tarjeta'">
                <tr>
                  <th>Tarjeta</th>
                  <td>{{ metodo.tarjeta }}</td>
                </tr>
                <tr>
                  <th>Días Libres</th>
                  <td>{{ metodo.diasLibres }}</td>
                </tr>
              </ng-container>

              <ng-container *ngIf="metodo.metodo === 'Canje'">
                <tr>
                  <th>Plus Canje</th>
                  <td>{{ metodo.plusCanje }} %</td>
                </tr>
                <tr>
                  <th>Comision Canje</th>
                  <td>{{ metodo.comisionCanje }} %</td>
                </tr>
              </ng-container>
            </table>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </div>

  <mat-dialog-actions
    style="margin: 1em 0"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
  >
    <button mat-fab type="button" color="primary" (click)="volver()">
      <mat-icon aria-label="Volver">keyboard_return</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
