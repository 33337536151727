import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../login/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ICliente,
  IComposicion,
  ICreateRelevamiento,
  IUsuario,
} from 'modelos/src';
import { DexieService } from '../../../auxiliares/dexie.service';
import { DolarService } from '../../../auxiliares/dolar.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { OfflineService } from '../../../auxiliares/offline.service';
import {
  condicionesComerciales,
  tasasInteres,
  IPago,
  IProductoSeleccionado,
  metodosPago,
  moneda,
  plazos,
  plazosPago,
  tarjetas,
  tiposRelevamiento,
} from '../datos';
import { RelevamientosService } from '../relevamientos.service';
@Component({
  selector: 'app-crear-relevamientos',
  templateUrl: './crear-relevamientos.component.html',
  styleUrls: ['./crear-relevamientos.component.scss'],
})
export class CrearRelevamientosComponent implements OnInit {
  public estaOnline = this.offlineService.isOnline;
  public loading = false;

  private cliente?: ICliente;

  public form?: FormGroup;

  public dolar: number = 1;

  public campAgricolas: string[] = [];
  public condicionesComerciales = condicionesComerciales;
  public tiposRelevamiento = tiposRelevamiento;
  public metodosPago = metodosPago;
  public plazosPago = plazosPago;
  public tarjetas = tarjetas;
  public tasasInteres = tasasInteres;

  public productosSeleccionados: IProductoSeleccionado[] = [
    {
      moneda: 'U$S',
    },
  ];
  public metodosPagoSeleccionados: IPago[] = [
    {
      plazoDias: 0,
      diasLibres: 0,
      tasaMensual: 0,
      porcentajePago: 100,
      comisionCanje: 0,
      plusCanje: 0,
      moneda: 'U$S',
    },
  ];

  constructor(
    public helper: HelperService,
    private fb: FormBuilder,
    public listados: ListadosOfflineService,
    private service: RelevamientosService,
    public matDialog: MatDialog,
    public dolarService: DolarService,
    public offlineService: OfflineService,
    public dexieService: DexieService
  ) {}

  get idRegionSeleccionada(): string | undefined {
    return this.form?.get('idRegion')?.value;
  }
  get idZonaSeleccionada(): string | undefined {
    return this.form?.get('idZona')?.value;
  }
  get idProvinciaSeleccionada(): string | undefined {
    return this.form?.get('idProvincia')?.value;
  }
  get idDepartamentoSeleccionada(): string | undefined {
    return this.form?.get('idDepartamento')?.value;
  }

  // Validaciones
  public async cambioMonedaPago(i: number) {
    const mondeda = this.productosSeleccionados[i].moneda;
    if (mondeda === '$' && this.dolar === 1) {
      this.loading = true;
      this.dolar = await this.dolarService.cotizacion();
      this.loading = false;
    }
    this.calcularPCE(i);
  }

  public cambioMetodoPago(i: number) {
    const metodo = this.metodosPagoSeleccionados[i].metodo || '';
    const monedaSeleccionadaS = this.metodosPagoSeleccionados[i].moneda;
    const monedaSeleccionada =
      monedaSeleccionadaS === '$'
        ? 'peso'
        : monedaSeleccionadaS === 'U$S'
        ? 'dolar'
        : '';

    const tasasCliente: any = this.cliente?.config?.tasas;

    this.metodosPagoSeleccionados[i].plazoDias = plazos[metodo];
    this.metodosPagoSeleccionados[i].tasaMensual =
      tasasCliente?.[metodo]?.[monedaSeleccionada] || tasasInteres[metodo];
    if (metodo === 'Canje') {
      this.metodosPagoSeleccionados[i].comisionCanje = 0.5;
    }

    this.productosSeleccionados.forEach((producto, i) => {
      producto.moneda = monedaSeleccionadaS;
      this.cambioMonedaPago(i);
    });
  }
  public cambioMonedaMetodoPago(i: number) {
    const metodo = this.metodosPagoSeleccionados[i].metodo || '';
    const monedaSeleccionadaS = this.metodosPagoSeleccionados[i].moneda;
    const monedaSeleccionada =
      monedaSeleccionadaS === '$'
        ? 'peso'
        : monedaSeleccionadaS === 'U$S'
        ? 'dolar'
        : '';

    const tasasCliente: any = this.cliente?.config?.tasas;

    this.metodosPagoSeleccionados[i].plazoDias = plazos[metodo];
    this.metodosPagoSeleccionados[i].tasaMensual =
      tasasCliente?.[metodo]?.[monedaSeleccionada] || tasasInteres[metodo];
    if (metodo === 'Canje') {
      this.metodosPagoSeleccionados[i].comisionCanje = 0.5;
    }

    this.productosSeleccionados.forEach((producto, i) => {
      producto.moneda = monedaSeleccionadaS;
      this.cambioMonedaPago(i);
    });
  }

  public cambioProcentajePago(i: number) {
    let suma = 0;
    this.metodosPagoSeleccionados.forEach((e, index) => {
      if (index !== i) {
        suma += e.porcentajePago || 0;
      }
    });
    const valorMax = 100 - suma;

    const valor = this.metodosPagoSeleccionados[i].porcentajePago || 0;

    if (valor < 0) this.metodosPagoSeleccionados[i].porcentajePago = 0;
    if (valor > valorMax)
      this.metodosPagoSeleccionados[i].porcentajePago = valorMax;
  }

  private porcentajeFaltante() {
    let suma = 0;
    this.metodosPagoSeleccionados.forEach((e, index) => {
      suma += e.porcentajePago || 0;
    });
    return 100 - suma;
  }

  public calcularPCETodos() {
    for (let i = 0; i < this.productosSeleccionados.length; i++) {
      this.calcularPCE(i);
    }
  }

  public calcularPCE(i: number) {
    const precioFinal: number = this.productosSeleccionados[i].precioFinal || 0;
    const moneda = this.productosSeleccionados[i].moneda;
    if (moneda === 'U$S') {
      this.productosSeleccionados[i].precioFinalU$S = precioFinal;
    } else {
      const valorDolar = this.dolar;
      this.productosSeleccionados[i].precioFinalU$S = +(
        precioFinal / valorDolar
      ).toFixed(2);
    }
    const precioFinalU$S: number =
      this.productosSeleccionados[i].precioFinalU$S || 0;

    let suma = 0;
    for (const pago of this.metodosPagoSeleccionados) {
      const importePorcentual =
        ((pago.porcentajePago || 0) * precioFinalU$S) / 100;
      let dias = pago.plazoDias || 0;

      if (pago.metodo === 'Tarjeta' && pago.diasLibres) {
        dias = dias - pago.diasLibres;
      }

      const meses = dias / 30;
      let interes = meses * (pago.tasaMensual || 0);

      if (pago.metodo === 'Canje') {
        if (pago.plusCanje) {
          interes = interes + pago.plusCanje;
        }
        if (pago.comisionCanje) {
          interes = interes - pago.comisionCanje;
        }
      }
      const importePorcentualContado =
        (importePorcentual / (100 + interes)) * 100;
      suma += importePorcentualContado;
    }
    this.productosSeleccionados[i].precioContadoU$S = +suma.toFixed(2);
  }

  public mostrarAgregarMetodoPago() {
    let suma = 0;
    this.metodosPagoSeleccionados.forEach(
      (e) => (suma += e.porcentajePago || 0)
    );
    return suma < 100;
  }

  public botonEnviarDisabled(): boolean {
    return (
      !this.form?.valid ||
      this.loading ||
      !this.productosValidos() ||
      !this.metodosPagoValidos()
    );
  }

  public productoValido(producto: IProductoSeleccionado): boolean {
    return !!(
      producto.idProducto &&
      producto.precioContadoU$S &&
      producto.precioFinalU$S
    );
  }
  public metodoPagoValido(pago: IPago): boolean {
    let valido = !!(
      pago.metodo &&
      pago.tasaMensual?.toString() &&
      pago.porcentajePago &&
      pago.plazoDias?.toString()
    );
    if (pago.metodo === 'Tarjeta') {
      valido = valido && !!(pago.tarjeta && pago.diasLibres?.toString());
    }
    if (pago.metodo === 'Canje') {
      valido =
        valido &&
        !!(pago.plusCanje?.toString() && pago.comisionCanje?.toString());
    }
    return valido;
  }
  public productosValidos(): boolean {
    for (const prod of this.productosSeleccionados) {
      if (!this.productoValido(prod)) {
        return false;
      }
    }
    return true;
  }
  public metodosPagoValidos(): boolean {
    let suma = 0;
    for (const metod of this.metodosPagoSeleccionados) {
      suma += metod.porcentajePago || 0;
      if (!this.metodoPagoValido(metod)) {
        return false;
      }
    }
    if (suma !== 100) {
      return false;
    }
    return true;
  }

  // Acciones

  public volver() {
    window.history.back();
  }

  private getData() {
    const idLocalidad = this.form?.get('idLocalidad')?.value;
    const localidad = this.listados.localidads.find(
      (l) => l._id === idLocalidad
    );
    const datos: ICreateRelevamiento[] = [];
    if (localidad) {
      for (const productoSeleccionado of this.productosSeleccionados) {
        const producto = this.listados.productos.find(
          (p) => p._id === productoSeleccionado.idProducto
        );
        if (producto) {
          const composicion: IComposicion[] = [];
          for (const comp of producto.composicion!) {
            composicion.push({
              idFamiliaQuimica: comp.idFamiliaQuimica,
              idPrincipioActivo: comp.idPrincipioActivo,
              concentracion: comp.concentracion,
            });
          }

          const dato: ICreateRelevamiento = {
            fecha: (this.form?.get('fecha')?.value as Date).toISOString(),
            campAgricola: this.form?.get('campAgricola')?.value,
            observaciones: this.form?.get('observaciones')?.value,
            ubicacion: {
              idLocalidad,
              idDepartamento: localidad.idDepartamento,
              idProvincia: localidad.idProvincia,
              // idZona: localidad.idZona,
              // idRegion: localidad.idRegion,
            },
            condicionComercial: this.form?.get('condicionComercial')?.value,
            fuente: this.form?.get('fuente')?.value,
            tipoRelevamiento: this.form?.get('tipoRelevamiento')?.value,
            pago: {
              precioContadoU$S: productoSeleccionado.precioContadoU$S,
              precioFinalU$S: productoSeleccionado.precioFinalU$S,
              volumen: productoSeleccionado.volumen,
              metodos: this.metodosPagoSeleccionados,
            },
            producto: {
              idProducto: producto._id,
              idEmpresa: producto.idEmpresa,
              idSegmento: producto.idSegmento,
              idsSubsegmentos: producto.idsSubsegmentos,
              // idsSubsegmentosPropios: producto.idsSubsegmentosPropios,
              composicion,
              numeroRegistro: producto.numeroRegistro,
            },
          };
          datos.push(dato);
        }
      }
    }
    return datos;
  }

  public async enviarOffline(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      await this.dexieService.createRelevamientos(data);
      this.helper.setUltimaLocalidadUtilizada(data[0].ubicacion.idLocalidad!);
      this.helper.notifSuccess('Creado correctamente');
      this.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public async enviarOnline() {
    this.loading = true;
    try {
      const data = this.getData();
      await this.service.bulkAdd(data);
      this.helper.notifSuccess('Creado correctamente');
      this.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  // public async buscarProducto(index: number) {
  //   const modal = this.matDialog.open(SeleccionProductoComponent, {
  //     maxWidth: '100%',
  //     maxHeight: '100%',
  //     hasBackdrop: true,
  //     panelClass: 'o-visible',
  //     position: { top: '1em' },
  //   });
  //   const idProducto = await modal.afterClosed().pipe(take(1)).toPromise();
  //   if (idProducto) {
  //     this.productosSeleccionados[index].idProducto = idProducto;
  //   }
  // }

  public unidadProducto(idProducto: string | undefined) {
    return this.listados.productos.find((p) => p._id === idProducto)?.unidad;
  }

  public agregarProducto() {
    this.productosSeleccionados.push({ moneda: 'U$S' });
  }

  public quitarProducto(index: number) {
    this.productosSeleccionados.splice(index, 1);
  }

  public agregarMetodoPago() {
    this.metodosPagoSeleccionados.push({
      plazoDias: 0,
      diasLibres: 0,
      tasaMensual: 3.5,
      porcentajePago: this.porcentajeFaltante(),
      comisionCanje: 0,
      plusCanje: 0,
      moneda: '',
    });
  }

  public quitarMetodoPago(index: number) {
    this.metodosPagoSeleccionados.splice(index, 1);
  }

  //

  public cambioFecha(date: Date) {
    const year = +date.getFullYear().toString().substr(2, 2);
    let campAgricola;
    if (
      date.getMonth() > 4 ||
      (date.getMonth() === 4 && date.getDate() >= 15)
    ) {
      campAgricola = `${year} / ${year + 1}`;
    } else {
      campAgricola = `${year - 1} / ${year}`;
    }
    if (!this.campAgricolas.includes(campAgricola)) {
      this.campAgricolas.push(campAgricola);
    }
    this.form?.patchValue({ campAgricola });
  }

  private crearCampAgricola() {
    let currYear = +new Date().getFullYear().toString().substr(2, 2);
    for (let i = 0; i < 3; i++) {
      this.campAgricolas.push(`${currYear - 1} / ${currYear}`);
      currYear++;
    }
  }

  private defaultCampAgricola() {
    const date = new Date();
    if (
      date.getMonth() > 4 ||
      (date.getMonth() === 4 && date.getDate() >= 15)
    ) {
      return this.campAgricolas[1];
    } else {
      return this.campAgricolas[0];
    }
  }

  private createForm() {
    const idLocalidadPorDefecto =
      this.helper.getLocalidadPorDefecto() ||
      this.helper.getUltimaLocalidadUtilizada() ||
      null;

    const user = LoginService.getUsuario();
    this.crearCampAgricola();
    this.form = this.fb.group({
      fecha: [new Date(), Validators.required],
      campAgricola: [
        { value: this.defaultCampAgricola(), disabled: false },
        Validators.required,
      ],
      idRegion: [],
      idZona: [],
      idProvincia: [],
      idDepartamento: [],
      idLocalidad: [idLocalidadPorDefecto, Validators.required],
      condicionComercial: [
        user.cliente?.config?.condicionComercial ||
          this.condicionesComerciales[0],
      ],
      fuente: [''],
      tipoRelevamiento: [this.tiposRelevamiento[0]],
      metodosPago: [],
      plazoPago: [],
      comisionCanje: [],
      comisionTarjeta: [],
      plusCanje: [],
      tarjeta: [],
      tasaMensual: [],
      observaciones: [],
    });
  }

  private defaultForm() {
    if (this.listados.regions.length === 1)
      this.form?.patchValue({ idRegion: this.listados.regions?.[0]?._id });
    if (this.listados.zonas.length === 1)
      this.form?.patchValue({ idZona: this.listados.zonas?.[0]?._id });
    if (this.listados.provincias.length === 1)
      this.form?.patchValue({
        idProvincia: this.listados.provincias?.[0]?._id,
      });
    if (this.listados.departamentos.length === 1)
      this.form?.patchValue({
        idDepartamento: this.listados.departamentos?.[0]?._id,
      });
    if (this.listados.localidads.length === 1)
      this.form?.patchValue({
        idLocalidad: this.listados.localidads?.[0]?._id,
      });
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.cliente = LoginService.getCliente();

    const tasasCliente = this.cliente?.config?.tasas;
    const metodosPagoCliente = [];
    for (const key in tasasCliente) {
      metodosPagoCliente.push(key);
    }
    this.metodosPago = metodosPagoCliente.length
      ? metodosPagoCliente
      : metodosPago;

    this.createForm();
    this.defaultForm();
    this.loading = false;
  }
}
