import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { GrupoComparativosRoutingModule } from './grupoComparativos.routing.module';
import { CrearEditarGrupoComparativoComponent } from './crear-editar-grupo-comparativo/crear-editar-grupo-comparativo.component';
import { ListadoGrupoComparativosComponent } from './listado-grupo-comparativos/listado-grupo-comparativos.component';

@NgModule({
  declarations: [
    // ListadoGrupoComparativosComponent,
    // CrearEditarGrupoComparativosComponent,
  
    CrearEditarGrupoComparativoComponent,
    ListadoGrupoComparativosComponent
  ],
  imports: [CommonModule, AuxiliaresModule, GrupoComparativosRoutingModule],
})
export class GrupoComparativosModule {}
