import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICliente, IToken, IUsuario } from 'modelos/src';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { api } from '../../../environments/environment';
import { DexieService } from '../../auxiliares/dexie.service';
import { HelperService } from '../../auxiliares/helper.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient, private helper: HelperService) {}

  public static isLogged() {
    if (sessionStorage.getItem('token')) {
      return true;
    }
    return false;
  }

  public static getToken() {
    return sessionStorage.getItem('token') || '';
  }

  public static getRefreshToken(): string {
    return sessionStorage.getItem('refreshToken') || '';
  }

  public static getUsuario(): IUsuario {
    const user =
      sessionStorage.getItem('usuario') || localStorage.getItem('usuario');
    return user ? JSON.parse(user) : undefined;
  }

  public static setUsuario(usuario: IUsuario): void {
    sessionStorage.setItem('usuario', JSON.stringify(usuario));
    localStorage.setItem('usuario', JSON.stringify(usuario));
  }

  public static setCliente(cliente: ICliente): void {
    sessionStorage.setItem('cliente', JSON.stringify(cliente));
    localStorage.setItem('cliente', JSON.stringify(cliente));
  }

  public static getCliente(): ICliente {
    const cliente =
      sessionStorage.getItem('cliente') || localStorage.getItem('cliente');
    return cliente ? JSON.parse(cliente) : undefined;
  }

  public static removeLoginInfo() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('usuario');
    sessionStorage.removeItem('cliente');
  }

  public static setLoginInfo(token: IToken) {
    sessionStorage.setItem('token', token.accessToken);
    sessionStorage.setItem('refreshToken', token.refreshToken || '');
    LoginService.setUsuario(token.user);
    LoginService.setCliente(token.user?.cliente!);
  }

  // LOGIN

  public _login(username: string, password: string): Observable<IToken> {
    return this.http.post<IToken>(`${api}/auth/login`, {
      username,
      password,
    });
  }

  public async login(username: string, password: string): Promise<IToken> {
    try {
      const token = await this._login(username, password)
        .pipe(first())
        .toPromise();
      LoginService.setLoginInfo(token);
      return token;
    } catch (error) {
      this.helper.notifError(error);
      throw error;
    }
  }

  // REFRESH TOKEN

  private _refreshToken(refresh_token: string): Observable<IToken> {
    return this.http.post<IToken>(`${api}/auth/refresh_token`, {
      refresh_token,
    });
  }

  public async refreshToken(): Promise<IToken> {
    const refresh_token = LoginService.getRefreshToken();
    const token = await this._refreshToken(refresh_token)
      .pipe(first())
      .toPromise();
    LoginService.setLoginInfo(token);
    return token;
    // "Invalid grant: refresh token is invalid"
  }
}
