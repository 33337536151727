import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter, IQueryMongo, IUsuario, TipoUsuario } from 'modelos/src';
import { first } from 'rxjs/operators';
import {
  DexieService,
  IRelevamientoOffline,
} from '../../../auxiliares/dexie.service';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { OfflineService } from '../../../auxiliares/offline.service';
import { RelevamientosService } from '../relevamientos.service';

@Component({
  selector: 'app-listado-relevamientos-offline',
  templateUrl: './listado-relevamientos-offline.component.html',
  styleUrls: ['./listado-relevamientos-offline.component.scss'],
})
export class ListadoRelevamientosOfflineComponent implements OnInit {
  public loading = true;
  public permisoCrear = HelperService.permisoCrearRelevamientos();

  // TABLA
  public dataSource = new MatTableDataSource<IRelevamientoOffline>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'fecha',
    'producto.idProducto',
    'ubicacion.idProvincia ubicacion.idDepartamento ubicacion.idLocalidad',
    'pago.precioContadoU$S',
    'pago.metodos.metodo',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'Fecha',
    'Producto',
    'Región',
    'Pago',
    'Metodo de Pago',
    'Acciones',
  ];
  public saveColumnas = 'precios-admin-relevamientos-offline';

  // Query
  public queryParams: IQueryMongo = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
  };

  constructor(
    private dialogService: DialogService,
    private service: RelevamientosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private dexieService: DexieService,
    private listadosOfflineService: ListadosOfflineService,
    public offlineService: OfflineService
  ) {}

  public nombreProducto(dato: IRelevamientoOffline): string {
    return (
      this.listadosOfflineService.productos.find(
        (p) => p._id === dato.producto.idProducto
      )?.nombre || ''
    );
  }
  public nombreProvincia(dato: IRelevamientoOffline): string {
    return (
      this.listadosOfflineService.provincias.find(
        (p) => p._id === dato.ubicacion.idProvincia
      )?.nombre || ''
    );
  }
  public nombreDepartamento(dato: IRelevamientoOffline): string {
    return (
      this.listadosOfflineService.departamentos.find(
        (p) => p._id === dato.ubicacion.idDepartamento
      )?.nombre || ''
    );
  }
  public nombreLocalidad(dato: IRelevamientoOffline): string {
    return (
      this.listadosOfflineService.localidads.find(
        (p) => p._id === dato.ubicacion.idLocalidad
      )?.nombre || ''
    );
  }

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter[]) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async eliminar(dato: IRelevamientoOffline): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el relevamiento de ${this.nombreProducto(dato)}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.dexieService.deleteRelevamiento(dato.id!);
        this.helper.notifSuccess('Eliminación correcta');
        await this.listar();
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }
  public async sincronizar(dato: IRelevamientoOffline): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Sincronizar el relevamiento de ${this.nombreProducto(
        dato
      )}, el mismo ya no se podrá modificar ni eliminar?`
    );
    if (confirm) {
      this.loading = true;
      try {
        const relevamiento = JSON.parse(JSON.stringify(dato));
        delete relevamiento.id;
        await this.service.crear(relevamiento).pipe(first()).toPromise();
        await this.dexieService.deleteRelevamiento(dato.id!);
        this.helper.notifSuccess('Sincronización correcta');
        await this.listar();
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  public volver() {
    window.history.back();
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    const response = await this.dexieService.getRelevamientos(this.queryParams);
    this.totalCount = response.totalCount;
    this.dataSource.data = response.datos;
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar()]);
    this.loading = false;
  }
}
