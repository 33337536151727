import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISocketMessage } from 'modelos/src';
import { interval, Observable, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { websocketServer } from 'src/environments/environment';
import { LoginService } from '../modulos/login/login.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket$?: WebSocketSubject<any>;
  private socketMsg$ = new Subject<ISocketMessage>();

  constructor() {
    this.initWs();
  }

  public initWs() {
    const token = LoginService.getToken();
    if (token && websocketServer) {
      try {
        this.socket$ = webSocket(websocketServer);
        this.socket$.subscribe(
          (message: ISocketMessage) => this.handleMessage(message),
          (error: Error) => this.handleError(error),
          () => this.closed()
        );
        console.log(
          `Iniciado WebSocket en ${websocketServer}, enviando token: ${token}`
        );
        this.sendToken(token);
      } catch (error) {
        console.log(`Error al iniciar WebSocket ${error}`);
      }
    } else {
      console.log('No hay token o websocketServer');
    }
  }

  private sendToken(token: string) {
    // Envio de mensaje con el token
    const identity = {
      event: 'identity',
      data: `Bearer ${token}`,
    };
    this.socket$?.next(identity);
  }

  public closeWS() {
    this.socket$?.complete();
  }

  public getMessage(): Observable<ISocketMessage> {
    return this.socketMsg$.asObservable();
  }

  // WS Handlers

  private handleMessage(message: ISocketMessage) {
    console.log(message);
    this.socketMsg$.next(message);
  }

  private handleError(error: Error) {
    console.log('WebSocket error reconectando...');
    this.socket$ = undefined;
    const reconect = interval(1000).pipe(takeWhile(() => !this.socket$));
    reconect.subscribe(() => {
      this.initWs();
    });
  }

  private closed() {
    console.log('WebSocket closed');
  }
}
