import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ZonasRoutingModule } from './zonas.routing.module';
import { CrearEditarZonaComponent } from './crear-editar-zona/crear-editar-zona.component';
import { ListadoZonasComponent } from './listado-zonas/listado-zonas.component';

@NgModule({
  declarations: [
    // ListadoZonasComponent,
    // CrearEditarZonasComponent,
  
    CrearEditarZonaComponent,
    ListadoZonasComponent
  ],
  imports: [CommonModule, AuxiliaresModule, ZonasRoutingModule],
})
export class ZonasModule {}
