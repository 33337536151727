import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import {
  ICreateRelevamiento,
  IDepartamento,
  IEmpresa,
  IFamiliaQuimica,
  ILocalidad,
  IPrincipioActivo,
  IProducto,
  IProvincia,
  IQueryMongo,
  IRegion,
  ISegmento,
  ISubsegmento,
  ISubsegmentoPropio,
  IZona,
} from 'modelos/src';
import { LoginService } from '../modulos/login/login.service';

export type IRelevamientoOffline = ICreateRelevamiento & { id?: number };

@Injectable({
  providedIn: 'root',
})
export class DexieService {
  // Index db
  private db?: Dexie;
  //
  private colecciones: {
    segmentos?: Dexie.Table<ISegmento, string>;
    subsegmentos?: Dexie.Table<ISubsegmento, string>;
    subsegmentoPropios?: Dexie.Table<ISubsegmentoPropio, string>;
    empresas?: Dexie.Table<IEmpresa, string>;
    familiaQuimicas?: Dexie.Table<IFamiliaQuimica, string>;
    principioActivos?: Dexie.Table<IPrincipioActivo, string>;
    productos?: Dexie.Table<IProducto, string>;
    //
    regions?: Dexie.Table<IRegion, string>;
    zonas?: Dexie.Table<IZona, string>;
    provincias?: Dexie.Table<IProvincia, string>;
    departamentos?: Dexie.Table<IDepartamento, string>;
    localidads?: Dexie.Table<ILocalidad, string>;
    //
    relevamientos?: Dexie.Table<IRelevamientoOffline, number>;
  } = {};

  constructor() {
    this.initIndexedDB();
  }

  // Index DB
  public async initIndexedDB() {
    this.setDb();
    this.db?.version(1).stores({
      segmentos: '_id, nombre',
      subsegmentos: '_id, nombre, idSegmento',
      subsegmentoPropios: '_id, nombre, idSegmento',
      empresas: '_id, nombre, propia',
      familiaQuimicas: '_id, nombre',
      principioActivos: '_id, nombre, idFamiliaQuimica',
      productos: '_id, nombre',
      //
      regions: '_id, nombre',
      zonas: '_id, nombre, idRegion',
      provincias: '_id, nombre, idRegion',
      departamentos: '_id, nombre, idProvincia, idZona',
      localidads: '_id, nombre, idDepartamento',
      //
      relevamientos: '++id',
    });
    this.colecciones['segmentos'] = this.db?.table('segmentos');
    this.colecciones['subsegmentos'] = this.db?.table('subsegmentos');
    this.colecciones['subsegmentoPropios'] =
      this.db?.table('subsegmentoPropios');
    this.colecciones['empresas'] = this.db?.table('empresas');
    this.colecciones['familiaQuimicas'] = this.db?.table('familiaQuimicas');
    this.colecciones['principioActivos'] = this.db?.table('principioActivos');
    this.colecciones['productos'] = this.db?.table('productos');
    //
    this.colecciones['regions'] = this.db?.table('regions');
    this.colecciones['zonas'] = this.db?.table('zonas');
    this.colecciones['provincias'] = this.db?.table('provincias');
    this.colecciones['departamentos'] = this.db?.table('departamentos');
    this.colecciones['localidads'] = this.db?.table('localidads');
    //
    this.colecciones['relevamientos'] = this.db?.table('relevamientos');
    //
    this.db
      ?.open()
      .then(() => {
        // console.log('Db abierta');
      })
      .catch((err: any) => {
        console.error(err.stack || err);
      });
  }

  public closeDb() {
    this.db?.close();
    console.log(`Db ${this.db?.name} cerrada`);
    this.db = undefined;
    this.colecciones = {};
  }

  public setDb() {
    const usuario = LoginService.getUsuario();
    if (usuario) {
      const dbName = `db-precios-${usuario.username}`;
      this.db = new Dexie(dbName);
      console.log(`*** Db seteada: ${dbName} ***`);
    } else {
      this.db = new Dexie(`db-precios`);
      console.error('No hay usuario');
    }
  }

  // Relevamientos
  public async getRelevamientos(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || '-fecha';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['relevamientos']?.count(),
      this.colecciones['relevamientos']
        ?.offset(offSet)
        .limit(limit)
        .sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getRelevamiento(id: number) {
    return await this.colecciones['relevamientos']?.get(id);
  }
  public async createRelevamiento(datos: ICreateRelevamiento) {
    await this.colecciones['relevamientos']?.add(datos);
  }
  public async createRelevamientos(datos: ICreateRelevamiento[]) {
    await this.colecciones['relevamientos']?.bulkAdd(datos);
  }
  public async updateRelevamiento(id: number, datos: ICreateRelevamiento) {
    await this.colecciones['relevamientos']?.update(id, datos);
  }
  public async deleteRelevamiento(id: number) {
    await this.colecciones['relevamientos']?.delete(id);
  }
  public async clearRelevamientos() {
    await this.colecciones['relevamientos']?.clear();
  }
  // Segmentos
  public async getSegmentos(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['segmentos']?.count(),
      this.colecciones['segmentos']?.offset(offSet).limit(limit).sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getSegmento(id: string) {
    return await this.colecciones['segmentos']?.get(id);
  }
  public async createSegmento(datos: ISegmento) {
    await this.colecciones['segmentos']?.add(datos);
  }
  public async createSegmentos(datos: ISegmento[]) {
    await this.colecciones['segmentos']?.bulkAdd(datos);
  }
  public async deleteSegmento(id: string) {
    await this.colecciones['segmentos']?.delete(id);
  }
  public async clearSegmentos() {
    await this.colecciones['segmentos']?.clear();
  }
  // Subsegmentos
  public async getSubsegmentos(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['subsegmentos']?.count(),
      this.colecciones['subsegmentos']
        ?.offset(offSet)
        .limit(limit)
        .sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getSubsegmento(id: string) {
    return await this.colecciones['subsegmentos']?.get(id);
  }
  public async createSubsegmento(datos: ISubsegmento) {
    await this.colecciones['subsegmentos']?.add(datos);
  }
  public async createSubsegmentos(datos: ISubsegmento[]) {
    await this.colecciones['subsegmentos']?.bulkAdd(datos);
  }
  public async deleteSubsegmento(id: string) {
    await this.colecciones['subsegmentos']?.delete(id);
  }
  public async clearSubsegmentos() {
    await this.colecciones['subsegmentos']?.clear();
  }
  // SubsegmentoPropios
  public async getSubsegmentoPropios(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['subsegmentoPropios']?.count(),
      this.colecciones['subsegmentoPropios']
        ?.offset(offSet)
        .limit(limit)
        .sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getSubsegmentoPropio(id: string) {
    return await this.colecciones['subsegmentoPropios']?.get(id);
  }
  public async createSubsegmentoPropio(datos: ISubsegmentoPropio) {
    await this.colecciones['subsegmentoPropios']?.add(datos);
  }
  public async createSubsegmentoPropios(datos: ISubsegmentoPropio[]) {
    await this.colecciones['subsegmentoPropios']?.bulkAdd(datos);
  }
  public async deleteSubsegmentoPropio(id: string) {
    await this.colecciones['subsegmentoPropios']?.delete(id);
  }
  public async clearSubsegmentoPropios() {
    await this.colecciones['subsegmentoPropios']?.clear();
  }
  // Empresas
  public async getEmpresas(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['empresas']?.count(),
      this.colecciones['empresas']?.offset(offSet).limit(limit).sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getEmpresa(id: string) {
    return await this.colecciones['empresas']?.get(id);
  }
  public async createEmpresa(datos: IEmpresa) {
    await this.colecciones['empresas']?.add(datos);
  }
  public async createEmpresas(datos: IEmpresa[]) {
    await this.colecciones['empresas']?.bulkAdd(datos);
  }
  public async deleteEmpresa(id: string) {
    await this.colecciones['empresas']?.delete(id);
  }
  public async clearEmpresas() {
    await this.colecciones['empresas']?.clear();
  }
  // FamiliaQuimicas
  public async getFamiliaQuimicas(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['familiaQuimicas']?.count(),
      this.colecciones['familiaQuimicas']
        ?.offset(offSet)
        .limit(limit)
        .sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getFamiliaQuimica(id: string) {
    return await this.colecciones['familiaQuimicas']?.get(id);
  }
  public async createFamiliaQuimica(datos: IFamiliaQuimica) {
    await this.colecciones['familiaQuimicas']?.add(datos);
  }
  public async createFamiliaQuimicas(datos: IFamiliaQuimica[]) {
    await this.colecciones['familiaQuimicas']?.bulkAdd(datos);
  }
  public async deleteFamiliaQuimica(id: string) {
    await this.colecciones['familiaQuimicas']?.delete(id);
  }
  public async clearFamiliaQuimicas() {
    await this.colecciones['familiaQuimicas']?.clear();
  }
  // PrincipioActivos
  public async getPrincipioActivos(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['principioActivos']?.count(),
      this.colecciones['principioActivos']
        ?.offset(offSet)
        .limit(limit)
        .sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getPrincipioActivo(id: string) {
    return await this.colecciones['principioActivos']?.get(id);
  }
  public async createPrincipioActivo(datos: IPrincipioActivo) {
    await this.colecciones['principioActivos']?.add(datos);
  }
  public async createPrincipioActivos(datos: IPrincipioActivo[]) {
    await this.colecciones['principioActivos']?.bulkAdd(datos);
  }
  public async deletePrincipioActivo(id: string) {
    await this.colecciones['principioActivos']?.delete(id);
  }
  public async clearPrincipioActivos() {
    await this.colecciones['principioActivos']?.clear();
  }
  // Productos
  public async getProductos(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['productos']?.count(),
      this.colecciones['productos']?.offset(offSet).limit(limit).sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getProducto(id: string) {
    return await this.colecciones['productos']?.get(id);
  }
  public async createProducto(datos: IProducto) {
    await this.colecciones['productos']?.add(datos);
  }
  public async createProductos(datos: IProducto[]) {
    await this.colecciones['productos']?.bulkAdd(datos);
  }
  public async deleteProducto(id: string) {
    await this.colecciones['productos']?.delete(id);
  }
  public async clearProductos() {
    await this.colecciones['productos']?.clear();
  }
  // Regions
  public async getRegions(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['regions']?.count(),
      this.colecciones['regions']?.offset(offSet).limit(limit).sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getRegion(id: string) {
    return await this.colecciones['regions']?.get(id);
  }
  public async createRegion(datos: IRegion) {
    await this.colecciones['regions']?.add(datos);
  }
  public async createRegions(datos: IRegion[]) {
    await this.colecciones['regions']?.bulkAdd(datos);
  }
  public async deleteRegion(id: string) {
    await this.colecciones['regions']?.delete(id);
  }
  public async clearRegions() {
    await this.colecciones['regions']?.clear();
  }
  // Zonas
  public async getZonas(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['zonas']?.count(),
      this.colecciones['zonas']?.offset(offSet).limit(limit).sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getZona(id: string) {
    return await this.colecciones['zonas']?.get(id);
  }
  public async createZona(datos: IZona) {
    await this.colecciones['zonas']?.add(datos);
  }
  public async createZonas(datos: IZona[]) {
    await this.colecciones['zonas']?.bulkAdd(datos);
  }
  public async deleteZona(id: string) {
    await this.colecciones['zonas']?.delete(id);
  }
  public async clearZonas() {
    await this.colecciones['zonas']?.clear();
  }
  // Provincias
  public async getProvincias(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['provincias']?.count(),
      this.colecciones['provincias']?.offset(offSet).limit(limit).sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getProvincia(id: string) {
    return await this.colecciones['provincias']?.get(id);
  }
  public async createProvincia(datos: IProvincia) {
    await this.colecciones['provincias']?.add(datos);
  }
  public async createProvincias(datos: IProvincia[]) {
    await this.colecciones['provincias']?.bulkAdd(datos);
  }
  public async deleteProvincia(id: string) {
    await this.colecciones['provincias']?.delete(id);
  }
  public async clearProvincias() {
    await this.colecciones['provincias']?.clear();
  }
  // Departamentos
  public async getDepartamentos(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['departamentos']?.count(),
      this.colecciones['departamentos']
        ?.offset(offSet)
        .limit(limit)
        .sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getDepartamento(id: string) {
    return await this.colecciones['departamentos']?.get(id);
  }
  public async createDepartamento(datos: IDepartamento) {
    await this.colecciones['departamentos']?.add(datos);
  }
  public async createDepartamentos(datos: IDepartamento[]) {
    await this.colecciones['departamentos']?.bulkAdd(datos);
  }
  public async deleteDepartamento(id: string) {
    await this.colecciones['departamentos']?.delete(id);
  }
  public async clearDepartamentos() {
    await this.colecciones['departamentos']?.clear();
  }
  // Localidads
  public async getLocalidads(query?: IQueryMongo) {
    const limit = +(query?.limit || 999999);
    const offSet = +(query?.page || 0) * +(query?.limit || 0);
    const sort = query?.sort || 'nombre';
    const [totalCount, datos] = await Promise.all([
      this.colecciones['localidads']?.count(),
      this.colecciones['localidads']?.offset(offSet).limit(limit).sortBy(sort),
    ]);
    return { totalCount: totalCount || 0, datos: datos || [] };
  }
  public async getLocalidad(id: string) {
    return await this.colecciones['localidads']?.get(id);
  }
  public async createLocalidad(datos: ILocalidad) {
    await this.colecciones['localidads']?.add(datos);
  }
  public async createLocalidads(datos: ILocalidad[]) {
    await this.colecciones['localidads']?.bulkAdd(datos);
  }
  public async deleteLocalidad(id: string) {
    await this.colecciones['localidads']?.delete(id);
  }
  public async clearLocalidads() {
    await this.colecciones['localidads']?.clear();
  }
  //
}
