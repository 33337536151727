import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IFilter,
  IListado,
  IQueryMongo,
  IRelevamiento,
  IUsuario,
  TipoUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { IFiltroTabla } from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosOfflineService } from '../../../auxiliares/listados-offline.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { LoginService } from '../../login/login.service';
import { RelevamientosService } from '../relevamientos.service';

@Component({
  selector: 'app-listado-relevamientos',
  templateUrl: './listado-relevamientos.component.html',
  styleUrls: ['./listado-relevamientos.component.scss'],
})
export class ListadoRelevamientosComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public loading = true;
  public user?: IUsuario;
  public permisoCrear = HelperService.permisoCrearRelevamientos();

  // TABLA
  public dataSource = new MatTableDataSource<IRelevamiento>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'fecha',
    'producto.idProducto',
    'producto.composicion.idPrincipioActivo',
    'ubicacion.idProvincia ubicacion.idDepartamento ubicacion.idLocalidad',
    'pago.precioContadoU$S',
    'pago.metodos.metodo',
    'idUsuario',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'Fecha',
    'Producto',
    'Composición',
    'Región',
    'Pago',
    'Metodo de Pago',
    'Usuario',
    'Acciones',
  ];
  public saveColumnas = 'precios-admin-relevamientos';
  // Filtros Tabla
  public search: IFilter = {
    field: [
      'condicionComercial',
      'fuente',
      'observaciones',
      'tipoRelevamiento',
    ],
    type: 'regex',
    value: '',
  };
  public fecha: IFiltroTabla = {
    label: 'Rango de Fechas',
    filter: {
      field: 'fecha',
      type: 'object',
      value: null,
    },
    tipo: 'dateRange',
  };
  public propio: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idUsuario',
      type: 'objectid',
      value: '',
    },
    label: 'Propios',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  public producto: IFiltroTabla = {
    elementos: this.listadosOfflineService.productos,
    filter: {
      field: 'producto.idProducto',
      type: 'string',
      value: null,
    },
    label: 'Producto',
    selectLabel: 'nombre',
    selectValue: '_id',
    multiple: true,
    tipo: 'ngselect',
  };
  public empresa: IFiltroTabla = {
    elementos: this.listadosOfflineService.empresas,
    filter: {
      field: 'producto.idEmpresa',
      type: 'string',
      value: null,
    },
    label: 'Empresa',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public segmento: IFiltroTabla = {
    elementos: this.listadosOfflineService.segmentos,
    filter: {
      field: 'producto.idSegmento',
      type: 'string',
      value: null,
    },
    label: 'Segmento',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public subsegmento: IFiltroTabla = {
    elementos: this.listadosOfflineService.subsegmentos,
    filter: {
      field: 'producto.idsSubsegmentos',
      type: 'string',
      value: null,
    },
    label: 'Subsegmento',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public principioActivo: IFiltroTabla = {
    elementos: this.listadosOfflineService.principioActivos,
    filter: {
      field: 'producto.composicion.idPrincipioActivo',
      type: 'string',
      value: null,
    },
    label: 'Principio Activo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public provincia: IFiltroTabla = {
    elementos: this.listadosOfflineService.provincias,
    filter: {
      field: 'ubicacion.idProvincia',
      type: 'string',
      value: null,
    },
    label: 'Provincia',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public departamento: IFiltroTabla = {
    elementos: this.listadosOfflineService.departamentos,
    filter: {
      field: 'ubicacion.idDepartamento',
      type: 'string',
      value: null,
    },
    label: 'Departamento',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public localidad: IFiltroTabla = {
    elementos: this.listadosOfflineService.localidads,
    filter: {
      field: 'ubicacion.idLocalidad',
      type: 'string',
      value: null,
    },
    label: 'Localidad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public metodoPago: IFiltroTabla = {
    elementos: [
      { label: 'Contado' },
      { label: 'Tarjeta' },
      { label: 'Pesificado' },
      { label: 'Canje' },
      { label: 'Cheque' },
      { label: 'Dolar Abierto' },
      { label: 'Otro' },
    ],
    filter: {
      field: 'pago.metodos.metodo',
      type: 'string',
      value: '',
    },
    label: 'Método de Pago',
    selectLabel: 'label',
    selectValue: 'label',
    tipo: 'select',
  };

  // Query
  private populate = [
    {
      path: 'producto.producto',
      select: 'nombre unidad',
    },
    {
      path: 'producto.composicion.principioActivo',
      select: 'nombre',
    },
    {
      path: 'producto.empresa',
      select: 'nombre',
    },
    {
      path: 'ubicacion.provincia',
      select: 'nombre',
    },
    {
      path: 'ubicacion.departamento',
      select: 'nombre',
    },
    {
      path: 'ubicacion.localidad',
      select: 'nombre',
    },
    {
      path: 'usuario',
      select: 'username idCliente',
    },
  ];
  public queryParams: IQueryMongo = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
    populate: JSON.stringify(this.populate),
    filter: JSON.stringify([]),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: RelevamientosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private listadosOfflineService: ListadosOfflineService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter[]) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async eliminar(dato: IRelevamiento): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el relevamiento de ${dato.producto.producto?.nombre}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  public async exportar() {
    const mensaje = `¿Desea exportar el listado de relevamientos?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.exportar(this.queryParams);
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  public volver() {
    window.history.back();
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    try {
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IRelevamiento>>('relevamientos', this.queryParams)
        .subscribe((data) => {
          this.totalCount = data.totalCount;
          this.dataSource.data = data.datos;
          console.log(`listado de relevamientos`, data);
        });
      await this.listadosService.getLastValue(
        'relevamientos',
        this.queryParams
      );
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.user = LoginService.getUsuario();
    this.propio.elementos?.push({
      label: 'Propios',
      value: this.user?._id,
    });
    await Promise.all([this.listar()]);
    this.loading = false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.producto.elementos = this.listadosOfflineService.productos;
      this.empresa.elementos = this.listadosOfflineService.empresas;
      this.segmento.elementos = this.listadosOfflineService.segmentos;
      this.subsegmento.elementos = this.listadosOfflineService.subsegmentos;
      this.principioActivo.elementos =
        this.listadosOfflineService.principioActivos;
      this.provincia.elementos = this.listadosOfflineService.provincias;
      this.departamento.elementos = this.listadosOfflineService.departamentos;
      this.localidad.elementos = this.listadosOfflineService.localidads;
    }, 1000);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
