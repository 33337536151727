import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { OfflineService } from '../../../auxiliares/offline.service';
import { PwaService } from '../../../auxiliares/pwa.service';
import { LogoutService } from '../../login/logout.service';
import { ResumenService } from '../resumen.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public loading = false;

  public permisoCrear = HelperService.permisoCrearRelevamientos();
  public permisoVer = HelperService.permisoVerRelevamientos();

  constructor(
    private resumenService: ResumenService,
    private dialogService: DialogService,
    private router: Router,
    private logoutService: LogoutService,
    public offlineService: OfflineService,
    public pwa: PwaService
  ) {}

  public logout() {
    this.dialogService
      .confirm('Confirme la acción', '¿Desea cerrar la sesión?')
      .then((confirm) => {
        if (confirm) {
          this.router.navigateByUrl('login');
          this.logoutService.logout();
        }
      });
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.loading = false;
  }
}
