import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RolesGlobalesGuard } from '../../auxiliares/guards/rol.guard';
import { CrearEditarClientesComponent } from './crear-editar-clientes/crear-editar-clientes.component';

const routes: Routes = [
  {
    path: 'crear',
    component: CrearEditarClientesComponent,
    canActivate: [RolesGlobalesGuard],
    data: { roles: ['Admin'] },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientesRoutingModule {}
