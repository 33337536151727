import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGlobalesGuard } from '../../auxiliares/guards/rol.guard';
import { ClientesModule } from '../clientes/clientes.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { GrupoComparativosModule } from '../grupoComparativos/grupoComparativos.module';
import { LandingModule } from '../landing/landing.module';
import { RegionsModule } from '../regions/regions.module';
import { RelevamientosModule } from '../relevamientos/relevamientos.module';
import { SubsegmentoPropiosModule } from '../subsegmentoPropios/subsegmentoPropios.module';
import { UsuariosModule } from '../usuarios/usuarios.module';
import { ZonasModule } from '../zonas/zonas.module';
import { NavigationComponent } from './navigation/navigation.component';

const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: 'landing',
        loadChildren: () => LandingModule,
      },
      {
        path: 'usuarios',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => UsuariosModule,
      },
      {
        path: 'grupoComparativos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => GrupoComparativosModule,
      },
      {
        path: 'subsegmentoPropios',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => SubsegmentoPropiosModule,
      },
      {
        path: 'regions',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => RegionsModule,
      },
      {
        path: 'zonas',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => ZonasModule,
      },
      {
        path: 'relevamientos',
        loadChildren: () => RelevamientosModule,
      },
      {
        path: 'dashboard',
        loadChildren: () => DashboardModule,
      },
      {
        path: 'clientes',
        loadChildren: () => ClientesModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NavigationRoutingModule {}
