import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IRelevamiento,
  ICreateRelevamiento,
  IListado,
  IQueryMongo,
  IUpdateRelevamiento,
  IResumenRelevamiento,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class RelevamientosService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryMongo
  ): Observable<IListado<IRelevamiento>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/relevamientos`, { params });
  }

  public async exportar(
    queryParams?: IQueryMongo,
    filename = 'relevamientos.xlsx'
  ): Promise<void> {
    let params = HelperService.getQueryParams(queryParams);
    await this.http.getFile(`/relevamientos/exportar`, filename, params);
  }

  public resumen(queryParams?: IQueryMongo): Observable<IResumenRelevamiento> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/relevamientos/resumen`, { params });
  }

  public crear(dato: ICreateRelevamiento): Observable<IRelevamiento> {
    return this.http.post(`/relevamientos`, dato);
  }

  public bulkAdd(dato: ICreateRelevamiento[]): Observable<IRelevamiento> {
    return this.http.post(`/relevamientos/bulk`, dato);
  }

  public listarPorId(id: string): Observable<IRelevamiento> {
    return this.http.get(`/relevamientos/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateRelevamiento
  ): Observable<IRelevamiento> {
    return this.http.put(`/relevamientos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/relevamientos/${id}`);
  }
}
