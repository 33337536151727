<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[
        propio,
        fecha,
        producto,
        empresa,
        segmento,
        subsegmento,
        principioActivo,
        provincia,
        departamento,
        localidad,
        metodoPago
      ]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Exportar -->
    <button
      style="margin-right: 0.5em"
      mat-fab
      matTooltip="Expotar"
      color="accent"
      (click)="exportar()"
    >
      XLS
    </button>
    <!-- Boton Crear -->
    <button
      *ngIf="permisoCrear"
      style="margin-right: 0.5em"
      mat-fab
      matTooltip="Crear"
      color="primary"
      [routerLink]="['../crear']"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna Fecha -->
    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.fecha | date: "shortDate" }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Producto -->
    <ng-container matColumnDef="producto.idProducto">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Producto
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <p style="margin: 0">
            {{ row.producto?.producto?.nombre || row.producto?.idProducto }}
          </p>
          <p style="margin: 0">
            <small style="color: #c9c9c9">
              {{ row.producto?.empresa?.nombre || row.producto?.idEmpresa }}
            </small>
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Composicion -->
    <ng-container matColumnDef="producto.composicion.idPrincipioActivo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Composición
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <ng-container *ngFor="let composicion of row.producto.composicion">
            <p style="margin: 0">
              {{
                composicion.principioActivo?.nombre ||
                  composicion.idPrincipioActivo
              }}
              - {{ composicion.concentracion }}%
            </p>
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Region -->
    <ng-container
      matColumnDef="ubicacion.idProvincia ubicacion.idDepartamento ubicacion.idLocalidad"
    >
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Región
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <p style="margin: 0">
            {{ row.ubicacion?.localidad?.nombre || row.ubicacion?.idLocalidad }}
          </p>
          <p style="margin: 0">
            <small style="color: #c9c9c9">
              {{
                row.ubicacion?.departamento?.nombre ||
                  row.ubicacion?.idDepartamento
              }}
            </small>
          </p>
          <p style="margin: 0">
            <small style="color: #c9c9c9">
              {{
                row.ubicacion?.provincia?.nombre || row.ubicacion?.idProvincia
              }}
            </small>
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Pago -->
    <ng-container matColumnDef="pago.precioContadoU$S">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Pago
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <table>
            <tr>
              <td>PCE:</td>
              <td>U$S {{ row.pago?.precioContadoU$S | number: "0.2-2" }}</td>
            </tr>
            <tr>
              <td>PF:</td>
              <td>U$S {{ row.pago?.precioFinalU$S | number: "0.2-2" }}</td>
            </tr>
            <tr>
              <td>VOL:</td>
              <td>
                {{ row.pago?.volumen }} {{ row.producto.producto?.unidad }}
              </td>
            </tr>
          </table>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Método de Pago -->
    <ng-container matColumnDef="pago.metodos.metodo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Método de Pago
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <ng-container *ngFor="let metodo of row.pago.metodos">
            <p style="margin: 0">
              {{ metodo.metodo }} {{ metodo.porcentajePago }}%
            </p>
            <!-- Tarjeta -->
            <ng-container *ngIf="metodo.metodo === 'Tarjeta'">
              <!-- <p style="margin: 0">{{ metodo.tarjeta }}</p> -->
              <p style="margin: 0">{{ metodo.plazoDias }} Días</p>
              <p style="margin: 0">Tasa {{ metodo.tasaMensual }}%</p>
            </ng-container>
            <ng-container *ngIf="metodo.metodo === 'Canje'"> </ng-container>
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Usuario -->
    <ng-container matColumnDef="idUsuario">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Usuario
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngIf="row.usuario?.idCliente === user?.idCliente">
          <p style="margin: 0">{{ row.usuario?.username || row.idUsuario }}</p>
        </div>
        <div *ngIf="row.usuario?.idCliente !== user?.idCliente">
          <p style="margin: 0">Usuario Externo</p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        style="overflow: visible"
        fxLayoutGap="5px"
      >
        <!-- <span class="mobile-label">Acciones:</span> -->
        <button
          mat-mini-fab
          matTooltip="Detalles"
          color="primary"
          [routerLink]="['detalles', row._id]"
        >
          <mat-icon>search</mat-icon>
        </button>
        <!-- <button
          mat-mini-fab
          matTooltip="Editar"
          color="accent"
          [routerLink]="['editar', row._id]"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button> -->
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator-goto>
</div>

<mat-dialog-actions
  style="margin: 1em"
  fxLayoutAlign="end center"
  fxLayoutGap="10px"
>
  <button mat-fab type="button" color="primary" (click)="volver()">
    <mat-icon aria-label="Volver">keyboard_return</mat-icon>
  </button>
</mat-dialog-actions>
