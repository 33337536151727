<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div style="margin-top: 0.5em; padding: 0.5em" #scrollMe>
  <!-- Filtros -->
  <div
    style="margin-bottom: 1em"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap="15px"
  >
    <!-- Filtros -->
    <div
      class="filtros"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutGap="15px"
      fxFill
    >
      <!-- Select  -->
      <div
        fxLayout="column"
        fxLayoutAlign="space-between center"
        fxFlex="85"
        fxFlex.lt-md="100"
        fxFill
        fxLayoutGap.lt-md="15px"
      >
        <!-- Primera Fila -->
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between end"
          fxLayoutGap="15px"
          fxFlex
          fxFill
        >
          <!-- Agrupar Por -->
          <mat-form-field fxFlex="20">
            <mat-label>Agrupar por</mat-label>
            <mat-select [(ngModel)]="agrupacion">
              <mat-option
                *ngFor="let dato of agrupaciones"
                [value]="dato.nombre"
              >
                {{ dato.nombre }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- Producto/Grupo/Subsegmento -->
          <div fxFlex="40" style="width: 100%">
            <!-- Producto -->
            <div *ngIf="agrupacion === 'Producto'">
              <ng-select
                placeholder="Seleccione un producto..."
                [items]="listadosOffline.productos"
                [virtualScroll]="true"
                bindLabel="nombre"
                [(ngModel)]="producto"
                notFoundText="No se encontraron productos"
                [searchFn]="helper.searchProducto"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ item.nombre }} </span>
                  <span style="font-size: 0.7em">
                    ({{ item.empresa?.nombre }})</span
                  >
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div
                    fxLayout="column"
                    style="
                      padding-top: 0.5em;
                      padding-bottom: 0.5em;
                      line-height: 1em !important;
                    "
                  >
                    <div style="padding-bottom: 5px">
                      <span>{{ item.nombre }} </span>
                      <span style="font-size: 0.7em">
                        ({{ item.empresa?.nombre }})</span
                      >
                    </div>
                    <div
                      fxLayout="row"
                      style="font-size: 0.6em !important; padding-left: 16px"
                    >
                      <span
                        *ngFor="
                          let composicion of item.composicion;
                          let i = index
                        "
                      >
                        <span *ngIf="i !== 0">&nbsp; | </span>
                        <span
                          >{{ composicion.principioActivo?.nombre }}
                          {{ composicion.concentracion }}%</span
                        >
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <!-- Grupos -->
            <div *ngIf="agrupacion === 'Grupo'">
              <ng-select
                placeholder="Seleccione un grupo..."
                [items]="listadosOffline.grupoComparativos"
                [virtualScroll]="true"
                bindLabel="nombre"
                [(ngModel)]="idGrupo"
                bindValue="_id"
                notFoundText="No se encontraron grupos"
              >
              </ng-select>
            </div>
            <!-- Segmentos -->
            <div *ngIf="agrupacion === 'Segmento'">
              <ng-select
                placeholder="Seleccione un segmento..."
                [items]="listadosOffline.segmentos"
                [virtualScroll]="true"
                bindLabel="nombre"
                [(ngModel)]="idSegmento"
                bindValue="_id"
                notFoundText="No se encontraron segmentos"
              >
              </ng-select>
            </div>
            <!-- Subsegmentos -->
            <div *ngIf="agrupacion === 'Subsegmento'">
              <ng-select
                placeholder="Seleccione un subsegmento..."
                [items]="listadosOffline.subsegmentos"
                [virtualScroll]="true"
                bindLabel="nombre"
                [(ngModel)]="idSubsegmento"
                bindValue="_id"
                notFoundText="No se encontraron subsegmentos"
              >
              </ng-select>
            </div>
            <!-- Subsegmentos Propios -->
            <div *ngIf="agrupacion === 'Subsegmento Propio'">
              <ng-select
                placeholder="Seleccione un subsegmento..."
                [items]="listadosOffline.subsegmentoPropios"
                [virtualScroll]="true"
                bindLabel="nombre"
                [(ngModel)]="idSubsegmentoPropio"
                bindValue="_id"
                notFoundText="No se encontraron subsegmentos"
              >
              </ng-select>
            </div>
          </div>

          <!-- Cond. Comercial -->
          <mat-form-field fxFlex="25">
            <mat-label>Condición comercial</mat-label>
            <mat-select [(ngModel)]="condicionComercial">
              <ng-container *ngFor="let dato of condicionesComerciales">
                <mat-option [value]="dato">{{ dato }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- Cantidad minima de relevamientos -->
          <mat-form-field fxFlex="15">
            <mat-label># Mínimo de muestras</mat-label>
            <input
              matInput
              type="num"
              (change)="cambioCantidadMinimaRelevamientos()"
              [(ngModel)]="cantidadMinimaRelevamientos"
            />
          </mat-form-field>
        </div>
        <!-- Segunda Fila -->
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="start end"
          fxLayoutGap="15px"
          fxFlex
          fxFill
        >
          <!-- Frecuencia predefinida -->
          <mat-form-field fxFlex="35">
            <mat-label>Periodo predefinido</mat-label>
            <mat-select
              (selectionChange)="cambioPeriodoPredefinido()"
              [(ngModel)]="periodoPredefinido"
            >
              <mat-option
                *ngFor="let dato of periodosPredefinidos"
                [value]="dato"
                >{{ dato }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- Frecuencia de agrupacion -->
          <mat-form-field fxFlex="35">
            <mat-label>Frecuencia de agrupación</mat-label>
            <mat-select
              (selectionChange)="cambioFrecuencia()"
              [(ngModel)]="frecuencia"
            >
              <mat-option
                *ngFor="let dato of frecuencias"
                [value]="dato.value"
                >{{ dato.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- Periodo comprendido -->
          <mat-form-field fxFlex="35">
            <mat-label>Periodo comprendido</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="Desde"
                [(ngModel)]="periodo.desde"
              />
              <input
                matEndDate
                placeholder="Hasta"
                [(ngModel)]="periodo.hasta"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
      <!-- Tildes  -->
      <div fxLayout="column" fxLayoutAlign="space-evenly start" fxFlex>
        <mat-checkbox [(ngModel)]="coloresPorEmpresa"
          >Colores por Empresa</mat-checkbox
        >
        <mat-checkbox [(ngModel)]="precioPorHect"
          >Precio por Hectárea</mat-checkbox
        >
        <mat-checkbox [(ngModel)]="puntos">Ocultar Puntos</mat-checkbox>
        <mat-checkbox [(ngModel)]="lineas">Ocultar Líneas</mat-checkbox>
      </div>
    </div>

    <!-- Botón  -->
    <button
      style="width: 100%"
      mat-raised-button
      color="primary"
      (click)="enviar()"
      [disabled]="!valid()"
    >
      CONSULTAR
    </button>
  </div>

  <!-- Grafico de barras -->
  <div *ngIf="chart2" class="chart-container" #chartContainer>
    <app-chart
      #chart02
      [(options)]="chart2"
      style="
        width: calc(100% - 5px);
        height: 700px;
        max-height: calc(100vh - 100px);
        display: block;
      "
      (chartPrint)="scrollToBottom()"
    ></app-chart>
  </div>

  <br />

  <!-- Grafico de lineas -->
  <div *ngIf="chart" class="chart-container" #chartContainer>
    <app-chart
      #chart01
      [(options)]="chart"
      style="
        width: calc(100% - 5px);
        height: 700px;
        max-height: calc(100vh - 100px);
        display: block;
      "
      (chartPrint)="scrollToBottom()"
    ></app-chart>
  </div>

  <br />
</div>
<mat-dialog-actions
  fxLayoutAlign="end center"
  fxLayoutGap="15px"
  style="margin: 1em 0; position: fixed; bottom: 1em; right: 1em; z-index: 10"
>
  <button mat-fab type="button" color="primary" (click)="volver()">
    <mat-icon aria-label="Volver">keyboard_return</mat-icon>
  </button>
</mat-dialog-actions>
